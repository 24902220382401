import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { setLang } from "../functions/dashboardFunctions";
import { AssessmentContext } from "../context/AssessmentProvider";
import { odaDecrypt } from "../functions/genericFunctions";
import JWT from "../contents/JWT";
import axios from "axios";
import axiosRetry from "axios-retry";

export function useGetFirstTestlet(skill, lang, level, runHook = false) {
  // console.log(lang);
  //** Global State Context Calls, links to context/AssessmentProvider.js */
  const [assessmentState, dispatch] = useContext(AssessmentContext);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [useAES] = useState(process.env.REACT_APP_USE_AES.toLowerCase() === "true" ? true : false);

  const navigate = useNavigate();

  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
    retryCondition: (err) => {
      console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  useEffect(() => {
    setIsLoading(true);
    if (runHook && !isLoading) {
      dispatch({
        type: "NEW_ASSESSMENT_RESET",
        payload: null
      });
      if (skill === "grammar") {
        JWT.fetchEmail()
          .then((result) => {
            const data = {
              language: setLang(lang),
              grammarLevel: level
              //email: result
            };
            JWT.fetchData()
              .then((result) => {
                if (result) {
                  data["userId"] = result;
                  dispatch({
                    type: "UPDATE_USER_ID",
                    payload: result
                  });
                  dispatch({
                    type: "UPDATE_MODALITY",
                    payload: "GR"
                  });
                  dispatch({
                    type: "UPDATE_MODULE_REF",
                    payload: "GR-" + level
                  });
                  axios
                    .post("/api/sessionGR", data)
                    .then((response) => {
                      // console.log(response);
                      let decrypted;
                      if (useAES === "true" || useAES === true) {
                        decrypted = JSON.parse(odaDecrypt(response.data));
                      } else {
                        decrypted = response.data;
                      }
                      //setData(decrypted);
                      sessionStorage.setItem("sessionID", decrypted._id);
                      dispatch({
                        type: "UPDATE_SESSION_ID",
                        payload: decrypted._id
                      });

                      const trackingObject = {
                        appName: "CMS-ODA",
                        eventType: "UserNewAssessment",
                        eventDetails: {
                          skill: skill,
                          lang: lang,
                          level: level,
                          sessionID: decrypted._id
                        },
                        dateTime: new Date().toISOString(),
                        location: window.location
                      };
                      axios.post("/api/tag", trackingObject).catch((error) => {
                        console.log(error);
                      });
                      // const devURL = "";
                      // const stagURL = "https://oda2stag.dliflc.edu";
                      // const prodURL = "https://oda.dliflc.edu";
                      // const port = ":5005";
                      // const api = "/api/tag";
                      // if (window.location.hostname === "localhost") {
                      //   axios.post(devURL + api, trackingObject).catch((error) => {
                      //     console.log(error);
                      //   });
                      // } else if (window.location.hostname === "oda2stag.dliflc.edu") {
                      //   axios.post(stagURL + api, trackingObject).catch((error) => {
                      //     console.log(error);
                      //   });
                      // } else {
                      //   axios.post(prodURL + api, trackingObject).catch((error) => {
                      //     console.log(error);
                      //   });
                      // }
                      // console.log(decrypted);
                      //** begin final axios call to query specific testlet data for given level
                      let testlets = { _id: [decrypted.modules[0].generatedTestlets] };
                      let testletPre = [decrypted.modules[0].generatedTestlets].toString().split(",");
                      let testletsString = "/api/testletGR?_id[]=[";
                      testletPre.forEach((item, index) => {
                        testletsString = testletsString + '"' + item + '"';
                        if (index < testletPre.length - 1) {
                          testletsString = testletsString + ",";
                        }
                      });
                      testletsString = testletsString + "]";
                      // console.log(testlets);
                      axios
                        .get(testletsString)
                        .then((finalData) => {
                          // console.log(finalData);
                          let decryptedFinal;
                          if (useAES === "true" || useAES === true) {
                            decryptedFinal = JSON.parse(odaDecrypt(finalData.data));
                          } else {
                            decryptedFinal = finalData.data;
                          }
                          // console.log(decryptedFinal[0].language);
                          if (lang === "North_Korean") {
                            dispatch({
                              type: "UPDATE_LANGUAGE",
                              payload: { description: "North Korean", displayName: "Korean/North Korean", _id: "nkr" }
                            });
                          } else {
                            dispatch({
                              type: "UPDATE_LANGUAGE",
                              payload: decryptedFinal[0].language
                            });
                          }
                          decrypted.testlets = decryptedFinal;
                          // console.log(decrypted);
                          setData(decrypted);
                        })
                        .catch((error2) => {
                          setError(error2);
                        });
                    })
                    .catch((error) => {
                      setError(error);
                    });
                } else {
                  //react-router-dom v6.8 version of props.history.push to pass state
                  //console.log("line 86 in useGetFirstTestlet");
                  navigate("/");
                }
              })
              .catch((error) => {
                setError(error);
              });
          })
          .catch((error) => {
            setError(error);
          });
      } else if (skill === "reading") {
        JWT.fetchEmail()
          .then((result) => {
            const data = {
              language: setLang(lang),
              proficiencyLevel: level
            };
            dispatch({
              type: "UPDATE_USER_EMAIL",
              payload: result
            });
            dispatch({
              type: "UPDATE_MODALITY",
              payload: "RC"
            });
            JWT.fetchData()
              .then((result) => {
                if (result) {
                  data["userId"] = result;
                  dispatch({
                    type: "UPDATE_USER_ID",
                    payload: result
                  });
                  axios
                    .post("/api/sessionRC", data)
                    .then((response) => {
                      let decrypted;
                      if (useAES === "true" || useAES === true) {
                        decrypted = JSON.parse(odaDecrypt(response.data));
                      } else {
                        decrypted = response.data;
                      }
                      //console.log(decrypted);
                      sessionStorage.setItem("sessionID", decrypted._id);
                      dispatch({
                        type: "UPDATE_SESSION_ID",
                        payload: decrypted._id
                      });
                      dispatch({
                        type: "UPDATE_TESTLET_MATRIX",
                        payload: decrypted.testletMatrix
                      });

                      const trackingObject = {
                        appName: "CMS-ODA",
                        eventType: "UserNewAssessment",
                        eventDetails: {
                          skill: skill,
                          lang: lang,
                          level: level,
                          sessionID: decrypted._id
                        },
                        dateTime: new Date().toISOString(),
                        location: window.location
                      };
                      axios.post("/api/tag", trackingObject).catch((error) => {
                        console.log(error);
                      });
                      // const devURL = "";
                      // const stagURL = "https://oda2stag.dliflc.edu";
                      // const prodURL = "https://oda.dliflc.edu";
                      // const port = ":5005";
                      // const api = "/api/tag";
                      // if (window.location.hostname === "localhost") {
                      //   axios.post(devURL + api, trackingObject).catch((error) => {
                      //     console.log(error);
                      //   });
                      // } else if (window.location.hostname === "oda2stag.dliflc.edu") {
                      //   axios.post(stagURL + api, trackingObject).catch((error) => {
                      //     console.log(error);
                      //   });
                      // } else {
                      //   axios.post(prodURL + api, trackingObject).catch((error) => {
                      //     console.log(error);
                      //   });
                      // }
                      //setData(decrypted);
                      //** begin final axios call to query specific testlet data for given level
                      let convertedLevel = "L" + level;
                      if (level === "1+") {
                        convertedLevel = "L1P";
                      } else if (level === "2+") {
                        convertedLevel = "L2P";
                      }

                      let testletPre = decrypted.testletMatrix[convertedLevel][0].toString().split(",");
                      let testletsString = "/api/testletRC?_id[]=[";
                      testletPre.forEach((item, index) => {
                        testletsString = testletsString + '"' + item + '"';
                        if (index < testletPre.length - 1) {
                          testletsString = testletsString + ",";
                        }
                      });
                      testletsString = testletsString + "]";

                      axios
                        .get(testletsString)
                        .then((finalData) => {
                          let decryptedFinal;
                          if (useAES === "true" || useAES === true) {
                            decryptedFinal = JSON.parse(odaDecrypt(finalData.data));
                          } else {
                            decryptedFinal = finalData.data;
                          }
                          //due to Studio3T generated out of order testitems, lets re-sort all items here.
                          for (let testItemCount = 0; testItemCount < 3; testItemCount++) {
                            let thisTestItemUnsorted = decryptedFinal[testItemCount].testitems;
                            let thisTestItemSorted = thisTestItemUnsorted;
                            thisTestItemSorted.sort((a, b) => (a._id > b._id ? 1 : b._id > a._id ? -1 : 0));
                            decryptedFinal[testItemCount].testitems = thisTestItemSorted;
                          }
                          //console.log(decryptedFinal);
                          if (lang === "North_Korean") {
                            dispatch({
                              type: "UPDATE_LANGUAGE",
                              payload: {
                                description: "North Korean",
                                displayName: "Korean/North Korean",
                                _id: "nkr"
                              }
                            });
                          } else {
                            dispatch({
                              type: "UPDATE_LANGUAGE",
                              payload: decryptedFinal[0].language
                            });
                          }
                          //console.log(decryptedFinal);
                          setData(decryptedFinal);
                        })
                        .catch((error2) => {
                          setError(error2);
                        });
                    })
                    .catch((error) => {
                      setError(error);
                    });
                } else {
                  //react-router-dom v6.8 version of props.history.push to pass state
                  //console.log("line 169 in useGetFirstTestlet");
                  navigate("/");
                }
              })
              .catch((error) => {
                setError(error);
              });
          })
          .catch((error) => {
            setError(error);
          });
      } else if (skill === "listening") {
        JWT.fetchEmail()
          .then((result) => {
            const data = {
              language: setLang(lang),
              proficiencyLevel: level
            };
            dispatch({
              type: "UPDATE_USER_EMAIL",
              payload: result
            });
            dispatch({
              type: "UPDATE_MODALITY",
              payload: "LC"
            });
            JWT.fetchData()
              .then((result) => {
                if (result) {
                  data["userId"] = result;
                  dispatch({
                    type: "UPDATE_USER_ID",
                    payload: result
                  });
                  axios
                    .post("/api/sessionLC", data)
                    .then((response) => {
                      let decrypted;
                      if (useAES === "true" || useAES === true) {
                        decrypted = JSON.parse(odaDecrypt(response.data));
                      } else {
                        decrypted = response.data;
                      }
                      sessionStorage.setItem("sessionID", decrypted._id);
                      dispatch({
                        type: "UPDATE_SESSION_ID",
                        payload: decrypted._id
                      });
                      dispatch({
                        type: "UPDATE_TESTLET_MATRIX",
                        payload: decrypted.testletMatrix
                      });

                      const trackingObject = {
                        appName: "CMS-ODA",
                        eventType: "UserNewAssessment",
                        eventDetails: {
                          skill: skill,
                          lang: lang,
                          level: level,
                          sessionID: decrypted._id
                        },
                        dateTime: new Date().toISOString(),
                        location: window.location
                      };
                      axios.post("/api/tag", trackingObject).catch((error) => {
                        console.log(error);
                      });
                      // const devURL = "";
                      // const stagURL = "https://oda2stag.dliflc.edu";
                      // const prodURL = "https://oda.dliflc.edu";
                      // const port = ":5005";
                      // const api = "/api/tag";
                      // if (window.location.hostname === "localhost") {
                      //   axios.post(devURL + api, trackingObject).catch((error) => {
                      //     console.log(error);
                      //   });
                      // } else if (window.location.hostname === "oda2stag.dliflc.edu") {
                      //   axios.post(stagURL + api, trackingObject).catch((error) => {
                      //     console.log(error);
                      //   });
                      // } else {
                      //   axios.post(prodURL + api, trackingObject).catch((error) => {
                      //     console.log(error);
                      //   });
                      // }
                      //setData(decrypted);
                      //** begin final axios call to query specific testlet data for given level
                      let convertedLevel = "L" + level;
                      if (level === "1+") {
                        convertedLevel = "L1P";
                      } else if (level === "2+") {
                        convertedLevel = "L2P";
                      }
                      // let testlets = { _id: [decrypted.testletMatrix[convertedLevel][0]] };
                      // //let testlets = new URLSearchParams({ _id: [decrypted.testletMatrix[convertedLevel][0]] });

                      // axios
                      //   .get("/api/testletLC", {
                      //     params: testlets,
                      //     paramsSerializer: {
                      //       serialize: (params) => {
                      //         return qs.stringify(params, {
                      //           encode: false,
                      //           encodeValuesOnly: true,
                      //           indices: false,
                      //           arrayFormat: "comma",
                      //           commaRoundTrip: true,
                      //           allowEmptyArrays: true
                      //         });
                      //       }
                      //     }
                      //   })

                      let testletPre = decrypted.testletMatrix[convertedLevel][0].toString().split(",");
                      let testletsString = "/api/testletLC?_id[]=[";
                      testletPre.forEach((item, index) => {
                        testletsString = testletsString + '"' + item + '"';
                        if (index < testletPre.length - 1) {
                          testletsString = testletsString + ",";
                        }
                      });
                      testletsString = testletsString + "]";

                      axios
                        .get(testletsString)
                        .then((finalData) => {
                          let decryptedFinal;
                          if (useAES === "true" || useAES === true) {
                            decryptedFinal = JSON.parse(odaDecrypt(finalData.data));
                          } else {
                            decryptedFinal = finalData.data;
                          }
                          //due to Studio3T generated out of order testitems, lets re-sort all items here.
                          for (let testItemCount = 0; testItemCount < 3; testItemCount++) {
                            let thisTestItemUnsorted = decryptedFinal[testItemCount].testitems;
                            let thisTestItemSorted = thisTestItemUnsorted;
                            thisTestItemSorted.sort((a, b) => (a._id > b._id ? 1 : b._id > a._id ? -1 : 0));
                            decryptedFinal[testItemCount].testitems = thisTestItemSorted;
                          }
                          //console.log(decryptedFinal);
                          if (lang === "North_Korean") {
                            dispatch({
                              type: "UPDATE_LANGUAGE",
                              payload: { description: "North Korean", displayName: "Korean/North Korean", _id: "nkr" }
                            });
                          } else {
                            dispatch({
                              type: "UPDATE_LANGUAGE",
                              payload: decryptedFinal[0].language
                            });
                          }
                          setData(decryptedFinal);
                        })
                        .catch((error2) => {
                          setError(error2);
                        });
                    })
                    .catch((error) => {
                      setError(error);
                    });
                } else {
                  //react-router-dom v6.8 version of props.history.push to pass state
                  //console.log("line 251 in useGetFirstTestlet");
                  navigate("/");
                }
              })
              .catch((error) => {
                setError(error);
              });
          })
          .catch((error) => {
            setError(error);
          });
      }
    }
    setIsLoading(false);
  }, [navigate, dispatch, lang, level, skill, runHook, isLoading, useAES]);

  return [data, isLoading, error];
}
