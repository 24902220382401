import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios"; //TODO: (Review-->this is never used, can we remove it?)
import JWT from "../../contents/JWT"; //TODO: (Review-->this is never used, can we remove it?)
import querySelectors from "../../functions/headerFunctions";
import { Helmet } from "react-helmet";
import { odaEncrypt } from "../../functions/genericFunctions";

const AdminLogin = (props) => {
  const [state, setState] = useState({
    input: {},
    errors: {},
    frontEndErrors: {}
  });

  useEffect(() => {
    querySelectors();
  }, []);

  const onChange = (event) => {
    let input = state.input;
    input[event.target.name] = event.target.value;
    setState({ ...state, input });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const checkValidation = validateUser();

    // const user = state.input;
    // const password = user.password;
    // const name = user.email;

    if (checkValidation) {
      // const user = state.input;
      const user = { email: odaEncrypt(state.input.email), password: odaEncrypt(state.input.password) };

      axios
        .post("/api/user/login", user)
        .then((res) => {
          console.log("ADMIN LOG IN SUCCESSFUL", res.data);
          localStorage.setItem("token", JSON.stringify(res.data));
          props.handleSuccessfulLogIn(res.data);
        })
        .catch((err) => {
          setState({ ...state, errors: err.response.data });
        });
      // props.handleSuccessfulLogIn(name, password);
    }

    const currentTime = new Date().getTime();
    const timer = setInterval(function () {
      const accessToken = localStorage.getItem("token");
      //const accessToken = assessmentState.assessmentUserToken;
      if (accessToken !== null) {
        const base64Url = accessToken.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const iatJWT = JSON.parse(window.atob(base64)).iat;
        const expJWT = JSON.parse(window.atob(base64)).exp;
        const expDuration = expJWT - iatJWT;
        if (new Date().getTime() >= currentTime + expDuration) {
          localStorage.removeItem("token");
          console.log(new Date().getTime());
          console.log(currentTime);
          console.log(expDuration);
          console.log("deleted token");
        }
      } else {
        clearInterval(timer);
      }
    }, 1000);
  };

  const validateUser = () => {
    let input = state.input;
    let frontEndErrors = {};
    let isValid = true;
    if ((input["email"] === undefined || input["email"] === "") && document.querySelector("#userEmail").value !== "") {
      input["email"] = document.querySelector("#userEmail").value;
    }
    if (
      (input["password"] === undefined || input["password"] === "") &&
      document.querySelector("#password").value !== ""
    ) {
      input["password"] = document.querySelector("#password").value;
    }

    if (input["email"] === "undefined") {
      isValid = false;
      frontEndErrors["email"] = "Please enter your email address.";
    }

    if (typeof input["email"] !== "undefined") {
      const emailRegEx = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!emailRegEx.test(input["email"])) {
        isValid = false;
        frontEndErrors["email"] = "Please enter valid email address.";
      }
    }

    if (!input["password"]) {
      isValid = false;
      frontEndErrors["password"] = "Please enter your password.";
    }

    setState({ ...state, frontEndErrors: frontEndErrors });

    return isValid;
  };

  const { errors } = state;
  return (
    <div className="loginWrap focused">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="login">
        <span className="cardTitle">Developers Sign In</span>
        <form onSubmit={onSubmit}>
          <label htmlFor="userEmail">
            Email
            <input
              type="text"
              name="email"
              id="userEmail"
              value={state.email}
              onChange={onChange}
              autoComplete="off"
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            <div className="text-danger">{state.frontEndErrors.email}</div>
          </label>

          <label htmlFor="password">
            Password
            <input
              id="password"
              type="password"
              className="password"
              name="password"
              value={state.password}
              onChange={onChange}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
            <div className="text-danger">{state.frontEndErrors.password}</div>
            <span className="eye password" title="Show/Hide password" />
          </label>
          <button className="loginBtn">Sign In</button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
