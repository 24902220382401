import { useEffect, useState, useCallback, useContext } from "react";
import { AssessmentContext } from "../../context/AssessmentProvider";
import { ErrorContext } from "../../context/ErrorProvider";
import axios from "axios";
import axiosRetry from "axios-retry";
import "../../css/index.css";
import { initialErrorState } from "../../context/ErrorStateReducer";

/**
 * @component ErrorModal
 * @description This function creates an Error Message Modal that pops up when called,
 * should be used anytime a potential for an error can occur, including API calls,
 * function calls, etc; by utilizing a "try catch block" when needed.
 * @param {object} props
 * @example
 * #in api call#
 * try {//do something with an API call}
 * catch (error) {
 *   if (error.response) {
 *    if (error.response.status >= 400 && error.response.status < 600) {
 *      errorDispatch({
 *        type: "UPDATE_ERROR_STATE",
 *        payload: {
 *          errorStatusCode: error.response.status,
 *          errorUserMessage: "New Error",
 *          errorDevData: error.response
 *        }
 *      });
 *    }
 *  }
 * }
 *
 * #in return segment where an error modal may be needed#
 * <ErrorModal errorStatusCode = {modalErrorState.errorStatusCode} errorUserMessage = {modalErrorState.errorUserMessage} errorDevData = {modalErrorState.errorDevData} /> *
 */

const ErrorModal = (props) => {
  //TRUE : disables the developer bug emails & error modal, but enables console.log output
  //FALSE: enables the developer bug emails & error modal, but disables console.log output
  const developerFlag = process.env.REACT_APP_DEVELOPERMODE;

  //set required states and variables
  const [mailState, setMailState] = useState(false);
  const [assessmentState] = useContext(AssessmentContext);
  const [modalErrorState, errorDispatch] = useContext(ErrorContext);
  const [errorState, setErrorState] = useState(false);
  const [initialErrorActive, setInitialErrorActive] = useState(modalErrorState.errorActiveThrown);
  let randomTicketNumber = Math.floor(Math.random() * 10000000);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      // console.log(err);
      // console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    },
  });

  /**
   * @callback onClick
   * @description Clears the error message when the user clicks out of the error modal
   * @param {object} e on click event from the error modal
   * @returns {callback} a cleared error prop state
   */

  const onClick = useCallback(
    (e) => {
      setErrorState(false);
      //console.log("resetting error state");
      errorDispatch({ type: "RESET_ERROR_STATE" });
    },
    [props]
  );

  /**
   * @description Watches for an updated error prop object and sets the error state
   * to true, which then shows the error modal to the user until it is cleared.
   * @param {object} props
   * @returns shows the error modal & sends a debug email if necessary
   */

  useEffect(() => {
    if (
      props.errorUserMessage !== "" &&
      props.errorUserMessage !== null &&
      props.errorUserMessage !== undefined &&
      errorState !== true
    ) {
      //size of this property is too large for long assessments >= 100kb, causes error 413 on requests
      let minimizedAssessmentState = JSON.parse(JSON.stringify(assessmentState));
      if (minimizedAssessmentState.hasOwnProperty("assessmentModulesRCLC")) {
        minimizedAssessmentState.assessmentModulesRCLC = null;
      }
      //create object for dev email
      if (props.errorDevData.config.hasOwnProperty("data")) {
        const devEmailDataObject = {
          ticketNum: randomTicketNumber,
          errorTime: new Date(),
          errMsg: props.errorDevData.data.message,
          errReqUrl: props.errorDevData.request.responseURL,
          errReqCred: props.errorDevData.request.withCredentials,
          errStatusCode: props.errorDevData.status,
          errStatusMsg: props.errorDevData.statusText,
          errConfigMethod: props.errorDevData.config.method,
          errConfigParams: props.errorDevData.config.params,
          errConfigURL: props.errorDevData.config.url,
          errConsoleLog: modalErrorState.errorConsoleLogErrors,
          assessmentState: minimizedAssessmentState,
        };
        devEmailDataObject.errData = props.errorDevData.config.hasOwnProperty("data")
          ? devEmailDataObject.errConfigURL === "/api/user/login" ||
            devEmailDataObject.errConfigURL === "/api/user/register" ||
            devEmailDataObject.errReqUrl === "/api/user/login" ||
            devEmailDataObject.errReqUrl === "/api/user/register"
            ? "Hidden due to sensitive data"
            : props.errorDevData.config.data
          : null;
        //only email developers if in production mode and no other mails have been sent
        if (mailState === false && developerFlag === "false") {
          axios
            .post("/api/emailDevelopersOnError", {
              devEmailDataObject,
            })
            .catch((error) => {
              console.log("Failed to notify developers of ticket " + randomTicketNumber + ".");
            });

          //do not send any additional emails
          setMailState(true);
        } else if (mailState === false && developerFlag == true) {
          console.log(
            "An email mode would have been dispatched had developerMode been disabled with the following information: "
          );
          console.log(devEmailDataObject);
          setMailState(true);
        }
      }
      //suppress the error modal if set to developer mode
      if (developerFlag.toString() === "false" && initialErrorActive.toString() === "false") {
        console.log(errorState);
        //showErrorModal
        setErrorState(true);
        errorDispatch({ type: "SHOWING_MODAL_TRUE" }); //only allow one at a time
      } else if (developerFlag.toString() === "false" && initialErrorActive.toString() === "true") {
        console.log(errorState);
        setErrorState(false);
        // do not show multiple error modals. only the first.
      } else {
        console.log(errorState);
        console.log("An error modal would have been displayed to the user if developerMode was disabled.");
        console.log("The reason for this is... " + props.errorDevData.status + " " + props.errorUserMessage);
        console.log(props.errorDevData);
        errorDispatch({ type: "RESET_ERROR_STATE" });
      }
    }
  }, [props, assessmentState, developerFlag, randomTicketNumber, mailState, errorState]);

  /**
   * @description Creates the mailto link for users to click in the error modal
   * @param {string} email
   * @param {string} subject
   * @param {string} body
   * @param {string} children
   * @returns {string} html string embedded in error modal
   */

  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  return (
    <div>
      {errorState && (
        <div className="modalDialog" id="modalDialog">
          <div>
            <a href="#close" title="Close" className="closeModal" onClick={onClick}>
              X
            </a>
            <div className="feedbackHeader" id="modalHeader">
              Error {props.errorStatusCode} - {props.errorUserMessage} (#{randomTicketNumber})
            </div>
            <h4>An error has occured...</h4>
            <h5>
              Error {props.errorStatusCode} - {props.errorUserMessage}
              {props.errorUserMessageDetails !== null && props.errorUserMessageDetails !== "" ? (
                <>
                  <br />
                  <i>{props.errorUserMessageDetails} </i>
                  <br />
                  <br />
                </>
              ) : (
                <>
                  <br />
                </>
              )}
              The developers have been automatically notified and will review the issue shortly. The developers are
              addressing any and all issues as they occur in the order of importance following the recent launch of the
              new ODA page.
              <br />
              <br />
            </h5>

            <h4>What to do next</h4>
            <h5>
              Many errors occur as one off issues during high server load. You should attempt to retry your action after
              closing this popup if you received one of these errors.
              <br /><br />
              You may also optionally{" "}
              <Mailto email="pres.ODA@dliflc.edu" subject={"Support Request for Ticket #" + randomTicketNumber}>
                email support
              </Mailto>{" "}
              if you would like to include additional information about this error.
            </h5>
            <h4>
              <a href="#close" title="Close" className="closeModalLink" onClick={onClick}>
                Return to ODA Home
              </a>
            </h4>
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorModal;
