import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import JWT from "./JWT";
import HeaderDefault from "./homes/Header";
import HeaderProfile from "./profiles/grammarProfiles/GrammarHeader";
import HeaderProfileRCLC from "./profiles/lcrcProfiles/Header";
import { AssessmentContext } from "../context/AssessmentProvider";
import { ErrorContext } from "../context/ErrorProvider";

/**
 * @component RootLayout
 * @description Creates the Root Layout for ODA; displays proper header/body/footer content
 **/

//** Root page layout for ODA; routes Outlet (body) and associated header to user's view **/
export default function RootLayout() {
  //collects the "location" data that used to be in props
  const location = useLocation();
  const navigate = useNavigate();

  //initiate state
  const [curPath, setCurPath] = useState(location.pathname);
  const [headerElement, setHeaderElement] = useState("<div></div>");
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
  const [assessmentState, dispatch] = useContext(AssessmentContext);
  const [errorState, errorDispatch] = useContext(ErrorContext);

  /**
   * @eventListener window error (console log error)
   * @description Tracks all of the errors sent to console log and stores them in errorState
   */
  window.addEventListener("error", e => {
    const { message, filename, lineno, colno, error } = e; 
  // window.onerror = (a, b, c, d, e) => {
    //console.log(`message: ${a} source: ${b} lineno: ${c} colno: ${d} error: ${e}`);
    //console.log({ message: a, source: b, lineno: c, colno: d, error: e.stack });
    console.log("New Error Caught (below)...");
    console.error(error);

    errorDispatch({
      type: "APPEND_CONSOLE_ERROR",
      payload: {
        errorConsoleLog: { message: message, source: filename, lineno: lineno, colno: colno, error: error }
      }
    });

    // return true;
  });

  //boots users back to dashboard if they hit F5 or the back button
  useEffect(() => {
    if (!assessmentState.assessmentSessionId && curPath === "/grammar") {
      navigate("/dashboard", { replace: true });
    }
  }, [assessmentState, curPath, navigate]);

  // regenerate missing global context if page back button was clicked
  useEffect(() => {
    setCurPath(location.pathname);
    if (!token) {
      setToken(JSON.parse(localStorage.getItem("token")));
    }
    if (!assessmentState.assessmentUserToken && token) {
      // console.log("Refreshing assessmentState from token.");
      dispatch({
        type: "UPDATE_TOKEN",
        payload: JSON.parse(localStorage.getItem("token"))
      });

      JWT.fetchEmail().then((result) => {
        if (result) {
          dispatch({
            type: "UPDATE_USER_EMAIL",
            payload: result
          });
        }
      });
      JWT.fetchData().then((result) => {
        if (result) {
          dispatch({
            type: "UPDATE_USER_ID",
            payload: result
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dispatch]);

  // determine which header to show
  useEffect(() => {
    //console.log("Token is: " + token);
    if (
      curPath === "/" ||
      curPath === "/home" ||
      curPath === "/about" ||
      curPath === "/testdescriptions" ||
      curPath === "/proficiency" ||
      curPath === "/faqs" ||
      curPath === "/policy" ||
      curPath === "/contact" ||
      curPath.includes("/reset") ||
      curPath === "/forgot" ||
      curPath.includes("verify") ||
      curPath === "/dashboard" ||
      curPath === "/editProfile"
    ) {
      setHeaderElement(<HeaderDefault />);
      //} else if (token && (curPath === "/profileGrammar" || curPath === "/profile")) {
    } else if (token && curPath === "/profileGrammar") {
      setHeaderElement(<HeaderProfile />);
    } else if (
      (token && curPath === "/profile") ||
      (token && curPath === "/surveyRCLC") ||
      (token && curPath === "/surveyGrammar")
    ) {
      setHeaderElement(<HeaderProfileRCLC />);
    } else {
      setHeaderElement(null);
    }
  }, [curPath, token]);

  //return the correct page elements (header, body, footer)
  return (
    <>
      {headerElement}
      <Outlet />
    </>
  );
}
