import React, { useState, useEffect } from "react";
import axios from "axios";
import dompurify from "dompurify";
import * as XLSX from "xlsx";

const ItemUserDisplayDetail = () => {
  const sanitizer = dompurify.sanitize;
  const [items, setItems] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const storedItems = localStorage.getItem("selectedItems");
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setItems(parsedItems);
      localStorage.removeItem("selectedItems");
    }
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      const [itemId, lang, mod, userLvl, isPassed, startDate, endDate, excludeAdmin] = items;

      axios
        .get(
          `/api/adminReport/itemUserCorrelationDetails?site=oda&lang=${lang}&itemId=${itemId}&mod=${mod}&userLvl=${userLvl}&isPassed=${isPassed}&startDate=${startDate}&endDate=${endDate}&excludeAdmin=${excludeAdmin}`
        )
        .then((res) => {
          setContent(res.data);
        })
        .catch((error) => {
          console.log("API request failed");
        });
    }
  }, [items]);

  const renderQuestionContent = (item, mod) => {
    if (Array.isArray(item._id.questions) && item._id.questions.length > 0) {
      return item._id.questions.join("<br/>");
    } else if (Array.isArray(item._id.questionText) && item._id.questionText.length > 0) {
      return item._id.questionText.join("<br/>");
    } else if (typeof item._id.questionText === 'string') {
      return item._id.questionText;
    } else {
      // If no conditions are met, return a default message or an empty string
      return "No question available";
    }
  };

  const cellStyle = {
    paddingLeft: "15px",
    textAlign: "left",
    color: "#4c7b80",
    fontWeight: "normal",
  };

  const cellStyle3 = {
    paddingLeft: "10px",
    textAlign: "left",
    color: "#4c7b80",
    fontWeight: "normal",
  };

  const exportToExcel = () => {
    const dataToExport = content.map((item) => ({
      Answer: item.userAnswer ? decodeURIComponent(item.userAnswer) : "N/A",
      Matches: item.matches ? item.matches.join(", ") : "N/A",
      ID: item.sessionId,
      "P/F": item.isPassed ? "Pass" : "Fail",
      Score: item.userScore,
      "Current Level": item.currentLevel || "0/0+",
      Date: item.date ? new Date(item.date).toLocaleDateString() : "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ItemUserDetails");
    XLSX.writeFile(workbook, "ItemUserDetails.xlsx");
  };

  return (
    <>
      <br /> <br />
      {content.length > 0 && content[0]._id && (
        <table>
          <tbody>
            <tr>
              <td style={cellStyle}>Test Item</td>
              <td style={cellStyle}>{content[0]._id.testletId}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Item Type</td>
              <td style={cellStyle}>{content[0]._id.template}</td>
            </tr>
            <tr>
              <td style={cellStyle}>Question</td>
              <td
                style={cellStyle}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    renderQuestionContent(content[0], items[2])
                  ),
                }}
              ></td>
            </tr>
            <tr>
              <td style={cellStyle}>Key(s)</td>
              <td style={cellStyle}>
                {content[0].keys && content[0].keys.length > 0 ? content[0].keys.join(", ") : 
                 content[0]._id.multipleChoiceAns !== undefined ? content[0]._id.multipleChoiceAns : "N/A"}
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>Passing Score</td>
              <td style={cellStyle}>{content[0]._id.passingScore}</td>
            </tr>
          </tbody>
        </table>
      )}
      <br /> <br /> <br />
      <table>
        <thead>
          <tr>
            <th
              style={{ width: "35%", paddingLeft: "10px", textAlign: "left" }}
            >
              Answer
            </th>
            <th
              style={{ width: "20%", paddingLeft: "10px", textAlign: "left" }}
            >
              Matches
            </th>
            <th style={{ width: "10%" }}>ID</th>
            <th style={{ width: "10%" }}>P/F</th>
            <th style={{ width: "5%" }}>Score</th>
            <th style={{ width: "5%" }}>Current Level</th>
            <th style={{ width: "15%" }}>Date</th>
          </tr>
        </thead>
        <tbody>
          {content
            .filter((item) => item.sessionId) // Filter out invalid items
            .map((item, index) => (
              <tr key={index}>
                <td style={cellStyle3}>
                  {item.userAnswer ? decodeURIComponent(item.userAnswer) : "N/A"}
                </td>
                <td style={cellStyle3}>
                  {item.matches ? item.matches.join(", ") : "N/A"}
                </td>
                <td style={cellStyle3}>{item.sessionId.slice(-4)}</td>
                <td style={cellStyle3}>{item.isPassed ? "Pass" : "Fail"}</td>
                <td style={cellStyle3}>{item.userScore}</td>
                <td style={cellStyle3}>{item.currentLevel || "0/0+"}</td>
                <td style={cellStyle3}>
                  {item.date ? new Date(item.date).toLocaleDateString() : "N/A"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <br />
      <button
        style={{
          fontSize: "13px",
          borderRadius: "10px",
          height: "30px",
          paddingTop: "7px",
          marginRight: "20px",
        }}
        onClick={exportToExcel}
      >
        Export to Excel
      </button>
    </>
  );
};

export default ItemUserDisplayDetail;
