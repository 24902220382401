import React, { useState, useEffect, useContext } from "react";
import { ErrorContext } from "../../../context/ErrorProvider";
import styled, { keyframes } from "styled-components";
import Speech from "./Speech";
import SpeechTarget from "./SpeechTarget";
import axios from "axios";
import axiosRetry from "axios-retry";
import dompurify from "dompurify";
import LoadingSpinner from "../../../components/spinner/LoadingSpinner";
import { openCloseAccordionSection } from "../../../functions/genericFunctions";
import sharedFunctions from "../../../functions/sharedFunctions";
import detailsFunctions from "../../../functions/detailsFunctions";
import EdgeCaseAbove from "./EdgeCaseAbove";
import EdgeCaseBelow from "./EdgeCaseBelow";

let RotateTarget = styled.div``;
let RotateTarget2 = styled.div``;

export default function Details(props) {
  const [, errorDispatch] = useContext(ErrorContext);
  const sanitizer = dompurify.sanitize;
  const userProfile = props.userProfile;

  //console.log("uerProfile=", JSON.stringify(userProfile));
  //console.log(props);
  const [detailData, setDetailData] = useState(userProfile);
  const [targetDetailData, setTargetDetailData] = useState(null);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  const [state, setState] = useState({
    id: props.id,
    language: props.language,
    modality: props.modality,
    // contentScoreTarget: props.contentScoreTarget + 1,
    // linguisticScoreTarget: props.linguisticScoreTarget + 1,
    // contentAngleTarget: 3.6 * (props.contentScoreTarget - 1),
    // contentAngleTarget: 3.6 * (props.linguisticScoreTarget - 1),
    estimatedLevel: props.estimatedLevel,
    targetLevel: props.targetLevel,
    angleTarget: 0,
    angleTarget2: 0,
    initialized: false,
    attempt: 0,
    edgeCase: null
  });

  // alert(state.id);
  // alert(state.language);
  // alert(state.modality);
  // alert(state.cotnetScoreTarget);
  // alert(state.linguisticScoreTarget);

  const getTargetDetailData = () => {
    if (state.modality === "reading") {
      if (props.targetLevel !== "+++" && props.targetLevel !== "***" && props.targetLevel !== "above") {
        axios
          .get("api/sessionRC/diagProfileByProfLevel", {
            params: {
              sessionId: props.id,
              // proficiencyLevel: props.targetLevel !== undefined ? props.targetLevel : "1",
              proficiencyLevel: props.targetLevel !== undefined ? props.targetLevel : "null",
              language: props.language
            }
          })
          .then((response) => {
            // console.log(
            //   "TARGET RC target detail=" + JSON.stringify(response.data)
            // );
            setTargetDetailData(response.data);
            setState({ ...state, edgeCase: false });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
                //global error dispatch call to show error modal if error received during api call
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: error.response.status,
                    errorUserMessage: "New Error",
                    errorDevData: error.response
                  }
                });
              }
            }
          });
      } else {
        setTargetDetailData([]);
        setState({ ...state, edgeCase: "above" });
      }
    } else if (state.modality === "listening") {
      if (props.targetLevel !== "+++" && props.targetLevel !== "***" && props.targetLevel !== "above") {
        axios
          .get("api/sessionLC/diagProfileByProfLevel", {
            params: {
              sessionId: props.id,
              proficiencyLevel: props.targetLevel !== undefined ? props.targetLevel : "1",
              language: props.language
            }
          })
          .then((response) => {
            //console.log("TARGET LC detail=" + JSON.stringify(response.data));
            setTargetDetailData(response.data);
            setState({ ...state, edgeCase: false });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
                //global error dispatch call to show error modal if error received during api call
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: error.response.status,
                    errorUserMessage: "New Error",
                    errorDevData: error.response
                  }
                });
              }
            }
          });
      } else {
        setTargetDetailData([]);
        setState({ ...state, edgeCase: "above" });
      }
    }
  };

  /*************************** */
  // const targetContScore = parseInt(
  //   targetDetailData && targetDetailData[0]?.contentPercentage + 1
  // );
  // const targetLingScore = parseInt(
  //   targetDetailData && targetDetailData[0]?.linguisticPercentage + 1
  // );
  // const targetContAngle = 3.6 * (targetContScore - 1);
  // const targetLingAngle = 3.6 * (targetLingScore - 1);

  // let targetContScore =
  //   targetDetailData && targetDetailData[0]?.contentPercentage + 1;
  // targetContScore = parseInt(targetContScore);
  // let targetLingScore =
  //   targetDetailData && targetDetailData[0]?.linguisticPercentage + 1;
  // targetLingScore = parseInt(targetLingScore);
  // const targetContAngle = 3.6 * (targetContScore - 1);
  // const targetLingAngle = 3.6 * (targetLingScore - 1);
  /******************************* */

  //
  // To ensure that targetContScore and targetLingScore have values when they are first accessed, you can guard against accessing properties of targetDetailData before it's initialized. You can use optional chaining (?.) to safely access properties and provide default values in case the data is not available yet.
  let targetContScore = targetDetailData?.[0]?.contentPercentage ?? 0;
  targetContScore = parseInt(targetContScore) + 1;

  let targetLingScore = targetDetailData?.[0]?.linguisticPercentage ?? 0;
  targetLingScore = parseInt(targetLingScore) + 1;

  const targetContAngle = 3.6 * targetContScore;
  const targetLingAngle = 3.6 * targetLingScore;

  // This modification uses optional chaining (?.) along with the nullish coalescing operator (??) to provide a default value of 0 if the required properties in targetDetailData are not available yet. This ensures that targetContScore and targetLingScore will have numeric values from the start, even before targetDetailData is fetched and set

  /*load the sharedFunctions from seperate js file*/
  useEffect(() => {
    //spin();
    sharedFunctions();
    detailsFunctions();
    getTargetDetailData();
  }, []);

  const spin = () => {
    if (state.attempt > 0) {
      setState({ ...state, initialized: true });
    }
    if (!state.initialized) {
      setState({
        ...state,
        angleTarget: targetContAngle,
        angleTarget2: targetLingAngle
      });

      const contentArrowTarget = keyframes`
      to {
        transform: rotate(${targetContAngle}deg);
        -webkit-transform: rotate(${targetContAngle}deg);
      }
    `;

      const lingusticArrowTarget = keyframes`
    to {
      transform: rotate(${targetLingAngle}deg);
      -webkit-transform: rotate(${targetLingAngle}deg);
    }
  `;

      // Here we create a component that will rotate the content questions
      RotateTarget = styled.div`
        width: 100px;
        height: 100px;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        border-radius: 50%;
        display: inline-block;
        z-index: 999;
        top: 11px;
        position: relative;
        -webkit-animation: ${contentArrowTarget}2s ease-out forwards;
        animation: ${contentArrowTarget} 2s ease-out forwards;
      `;

      RotateTarget2 = styled.div`
        width: 100px;
        height: 100px;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        border-radius: 50%;
        display: inline-block;
        z-index: 999;
        top: 11px;
        position: relative;
        -webkit-animation: ${lingusticArrowTarget}2s ease-out forwards;
        animation: ${lingusticArrowTarget} 2s ease-out forwards;
      `;
    }
    //setState({ initialized: true });
    setState({ ...state, attempt: state.attempt + 1 });
  };

  let speech = "";
  let speechTarget = "";

  //issue #192 fix, need to ensure userProfile isnt [] for LC, ie .length === 0
  if (state.modality === "listening" && props.userProfile.length > 0) {
    const oriAudTotalQues = detailData[0].originalAudioSummary.totalQuestion;

    const oriAudPassedQues = detailData[0].originalAudioSummary.questionPassed;

    const oriAudScore = oriAudPassedQues + "/" + oriAudTotalQues;

    const modAudTotalQues = detailData[0].modifiedAudioSummary.totalQuestion;
    const modAudPassedQues = detailData[0].modifiedAudioSummary.questionPassed;
    const modAudScore = modAudPassedQues + "/" + modAudTotalQues;

    const scriptTotalQues = detailData[0].transcribedSummary.totalQuestion;
    const scriptPassedQues = detailData[0].transcribedSummary.questionPassed;
    const scriptScore = scriptPassedQues + "/" + scriptTotalQues;

    const audVocabTotalQues = detailData[0].lexicon.totalQuestion;
    const audVocabPassedQues = detailData[0].lexicon.questionPassed;
    const audVocabScore = audVocabPassedQues + "/" + audVocabTotalQues;
    /******************************************************* */
    const targetOriAudTotalQues = targetDetailData && targetDetailData[0]?.originalAudioSummary.totalQuestion;

    const targetOriAudPassedQues = targetDetailData && targetDetailData[0]?.originalAudioSummary.questionPassed;
    const targetOriAudScore = targetOriAudPassedQues + "/" + targetOriAudTotalQues;

    const targetModAudTotalQues = targetDetailData && targetDetailData[0]?.modifiedAudioSummary.totalQuestion;
    const targetModAudPassedQues = targetDetailData && targetDetailData[0]?.modifiedAudioSummary.questionPassed;
    const targetModAudScore = targetModAudPassedQues + "/" + targetModAudTotalQues;

    const targetScriptTotalQues = targetDetailData && targetDetailData[0]?.transcribedSummary.totalQuestion;
    const targetScriptPassedQues = targetDetailData && targetDetailData[0]?.transcribedSummary.questionPassed;
    const targetScriptScore = targetScriptPassedQues + "/" + targetScriptTotalQues;

    const targetAudVocabTotalQues = targetDetailData && targetDetailData[0]?.lexicon.totalQuestion;
    const targetAudVocabPassedQues = targetDetailData && targetDetailData[0]?.lexicon.questionPassed;
    const targetAudVocabScore = targetAudVocabPassedQues + "/" + targetAudVocabTotalQues;

    speech = (
      <Speech
        oriAudScore={oriAudScore}
        modAudScore={modAudScore}
        audVocabScore={audVocabScore}
        scriptScore={scriptScore}
      />
    );
    speechTarget = (
      <SpeechTarget
        targetOriAudScore={targetOriAudScore}
        targetModAudScore={targetModAudScore}
        targetScriptScore={targetScriptScore}
        targetAudVocabScore={targetAudVocabScore}
      />
    );
  }

  // current level detailData

  const contentProposition = () => {
    return detailData.map((category, index) => {
      const mainScore = category.mainProposition.questionPassed + "/" + category.mainProposition.totalQuestion;
      const mainPercentage =
        ((category.mainProposition.questionPassed / category.mainProposition.totalQuestion) * 100).toFixed(0) + "%";

      const supportingScore =
        category.supportingProposition.questionPassed + "/" + category.supportingProposition.totalQuestion;
      const supportingPercentage =
        ((category.supportingProposition.questionPassed / category.supportingProposition.totalQuestion) * 100).toFixed(
          0
        ) + "%";

      return (
        <div key={index}>
          <div className="profileCategory">
            <span className="dataLeft" data="100">
              {mainScore}
              <br />
              {mainPercentage}
            </span>
            <h3>Main Ideas</h3>
            {category.mainProposition._id.primaryTagStatements.map((statement, statementIndex) => (
              <div key={statementIndex}>
                <p className="tag">{statement}</p>
              </div>
            ))}
          </div>
          <br />
          <div className="profileCategory">
            <span className="dataLeft" data="33">
              {supportingScore}
              <br />
              {supportingPercentage}
            </span>
            <h3>Supporting Ideas</h3>
            {category.supportingProposition._id.primaryTagStatements.map((statement, statementIndex) => (
              <div key={statementIndex}>
                <p className="tag">{statement}</p>
              </div>
            ))}
          </div>
        </div>
      );
    });
  };

  const lexicon = () => {
    return detailData.map((category, index) => {
      const score = category.lexicon.questionPassed + "/" + category.lexicon.totalQuestion;
      // setVocabScore(score);
      const percentage = ((category.lexicon.questionPassed / category.lexicon.totalQuestion) * 100).toFixed(0) + "%";

      if (detailData[0].hasOwnProperty("lexicon") && Object.keys(detailData[0].lexicon).length > 0) {
        return (
          <div key={index}>
            <div className="profileCategory">
              <span className="dataLeft" data="100">
                {score}
                <br />
                {percentage}
              </span>
              <h3>Vocabulary</h3>
              {category.lexicon._id.primaryTagStatements
                .filter((statement) => !statement.includes("_TOPIC_"))
                .map((statement, statementIndex) => (
                  <div key={statementIndex}>
                    <p className="tag">{statement}</p>
                  </div>
                ))}
              <div className="fillGreyArea">
                <h4 className="accordionTrigger closed" onClick={openCloseAccordionSection}>
                  Vocabulary Subject Area Breakdown
                </h4>
                <div className="accordion">
                  {category.lexicon.detail.map((detail, detailIndex) => {
                    const breakdownScore = detail.questionPassed + "/" + detail.totalQuestion;

                    return (
                      <div key={detailIndex}>
                        <p className="tag">
                          {detail._id.primaryTopic}:{detail.subtopic1}-{detail.subtopic2}
                          <span className="dataLeftSmall" data="100">
                            {breakdownScore}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  const structure = () => {
    return detailData.map((category, index) => {
      const score = category.structure.questionPassed + "/" + category.structure.totalQuestion;
      const percentage =
        ((category.structure.questionPassed / category.structure.totalQuestion) * 100).toFixed(0) + "%";

      if (detailData[0].hasOwnProperty("structure") && Object.keys(detailData[0].structure).length > 0) {
        return (
          <div key={index}>
            <div className="profileCategory">
              <span className="dataLeft" data="100">
                {score}
                <br />
                {percentage}
              </span>
              <h3>Structure</h3>
              {category.structure._id.primaryTagStatements.map((statement, statementIndex) => (
                <div key={statementIndex}>
                  <p className="tag">{statement}</p>
                </div>
              ))}
              <div className="fillGreyArea">
                <h4 className="accordionTrigger closed" onClick={openCloseAccordionSection}>
                  Structural Feature Breakdown
                </h4>
                <div className="accordion">
                  {category.structure.detail.map((detail, detailIndex) => {
                    const breakdownScore = detail.questionPassed + "/" + detail.totalQuestion;

                    return (
                      <div key={detailIndex}>
                        <div className="tag">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizer(detail._id.langSpecificTagStatements.join(""))
                            }}
                          />
                          <br />
                          <span className="dataLeftSmall" data="100">
                            {breakdownScore}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  const discourse = () => {
    return detailData.map((category, index) => {
      const score = category.discourse.questionPassed + "/" + category.discourse.totalQuestion;
      const percentage =
        ((category.discourse.questionPassed / category.discourse.totalQuestion) * 100).toFixed(0) + "%";

      if (detailData[0].hasOwnProperty("discourse") && Object.keys(detailData[0].discourse).length > 0) {
        return (
          <div key={index}>
            <div className="profileCategory">
              <span className="dataLeft" data="100">
                {score}
                <br />
                {percentage}
              </span>
              <h3>Discourse</h3>
              {category.discourse._id.primaryTagStatements.map((statement, statementIndex) => (
                <div key={statementIndex}>
                  <p className="tag">{statement}</p>
                </div>
              ))}
              <div className="fillGreyArea">
                <h4 className="accordionTrigger closed" onClick={openCloseAccordionSection}>
                  Discourse Feature Breakdown
                </h4>
                <div className="accordion">
                  {category.discourse.detail.map((detail, detailIndex) => {
                    const breakdownScore = detail.questionPassed + "/" + detail.totalQuestion;

                    return (
                      <div key={detailIndex}>
                        <div className="tag">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizer(detail._id.langSpecificTagStatements.join(""))
                            }}
                          />
                          <br />
                          <span className="dataLeftSmall" data="100">
                            {breakdownScore}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  // target level detailData

  const contentPropositionTarget = () => {
    return (
      targetDetailData &&
      Array.isArray(targetDetailData) &&
      targetDetailData.map((category, index) => {
        const mainScore = category.mainProposition.questionPassed + "/" + category.mainProposition.totalQuestion;
        const mainPercentage =
          ((category.mainProposition.questionPassed / category.mainProposition.totalQuestion) * 100).toFixed(0) + "%";

        const supportingScore =
          category.supportingProposition.questionPassed + "/" + category.supportingProposition.totalQuestion;
        const supportingPercentage =
          (
            (category.supportingProposition.questionPassed / category.supportingProposition.totalQuestion) *
            100
          ).toFixed(0) + "%";

        return (
          <div key={index}>
            <div className="profileCategory">
              <span className="dataLeft" data="100">
                {mainScore}
                <br />
                {mainPercentage}
              </span>
              <h3>Main Ideas</h3>
              {category.mainProposition._id.primaryTagStatements.map((statement, statementIndex) => (
                <div key={statementIndex}>
                  <p className="tag">{statement}</p>
                </div>
              ))}
            </div>
            <br />
            <div className="profileCategory">
              <span className="dataLeft" data="33">
                {supportingScore}
                <br />
                {supportingPercentage}
              </span>
              <h3>Supporting Ideas</h3>
              {category.supportingProposition._id.primaryTagStatements.map((statement, statementIndex) => (
                <div key={statementIndex}>
                  <p className="tag">{statement}</p>
                </div>
              ))}
            </div>
          </div>
        );
      })
    );
  };

  const lexiconTarget = () => {
    return (
      targetDetailData &&
      targetDetailData.map((category, index) => {
        const score = category.lexicon.questionPassed + "/" + category.lexicon.totalQuestion;
        const percentage = ((category.lexicon.questionPassed / category.lexicon.totalQuestion) * 100).toFixed(0) + "%";

        if (targetDetailData[0].hasOwnProperty("lexicon") && Object.keys(targetDetailData[0].lexicon).length > 0) {
          return (
            <div key={index}>
              <div className="profileCategory">
                <span className="dataLeft" data="100">
                  {score}
                  <br />
                  {percentage}
                </span>
                <h3>Vocabulary</h3>
                {category.lexicon._id.primaryTagStatements
                  .filter((statement) => !statement.includes("_TOPIC_"))
                  .map((statement, statementIndex) => (
                    <div key={statementIndex}>
                      <p className="tag">{statement}</p>
                    </div>
                  ))}
                <div className="fillGreyArea">
                  <h4 className="accordionTrigger closed" onClick={openCloseAccordionSection}>
                    Vocabulary Subject Area Breakdown
                  </h4>
                  <div className="accordion">
                    {category.lexicon.detail.map((detail, detailIndex) => {
                      const breakdownScore = detail.questionPassed + "/" + detail.totalQuestion;
                      return (
                        <div key={detailIndex}>
                          <p className="tag">
                            {detail._id.primaryTopic}:{detail.subtopic1}-{detail.subtopic2}
                            <span className="dataLeftSmall" data="100">
                              {breakdownScore}
                            </span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return null;
      })
    );
  };

  const structureTarget = () => {
    return (
      targetDetailData &&
      targetDetailData.map((category, index) => {
        const score = category.structure.questionPassed + "/" + category.structure.totalQuestion;
        const percentage =
          ((category.structure.questionPassed / category.structure.totalQuestion) * 100).toFixed(0) + "%";

        if (targetDetailData[0].hasOwnProperty("structure") && Object.keys(targetDetailData[0].structure).length > 0) {
          return (
            <div key={index}>
              <div className="profileCategory">
                <span className="dataLeft" data="100">
                  {score}
                  <br />
                  {percentage}
                </span>
                <h3>Structure</h3>
                {category.structure._id.primaryTagStatements.map((statement, statementIndex) => (
                  <div key={statementIndex}>
                    <p className="tag">{statement}</p>
                  </div>
                ))}
                <div className="fillGreyArea">
                  <h4 className="accordionTrigger closed" onClick={openCloseAccordionSection}>
                    Structural Feature Breakdown
                  </h4>
                  <div className="accordion">
                    {category.structure.detail.map((detail, detailIndex) => {
                      const breakdownScore = detail.questionPassed + "/" + detail.totalQuestion;
                      return (
                        <div key={detailIndex}>
                          <div className="tag">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizer(detail._id.langSpecificTagStatements.join(""))
                              }}
                            />
                            <br />
                            <span className="dataLeftSmall" data="100">
                              {breakdownScore}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return null;
      })
    );
  };

  const discourseTarget = () => {
    return (
      targetDetailData &&
      targetDetailData.map((category, index) => {
        const score = category.discourse.questionPassed + "/" + category.discourse.totalQuestion;
        const percentage =
          ((category.discourse.questionPassed / category.discourse.totalQuestion) * 100).toFixed(0) + "%";

        if (targetDetailData[0].hasOwnProperty("discourse") && Object.keys(targetDetailData[0].discourse).length > 0) {
          return (
            <div key={index}>
              <div className="profileCategory">
                <span className="dataLeft" data="100">
                  {score}
                  <br />
                  {percentage}
                </span>
                <h3>Discourse</h3>
                {category.discourse._id.primaryTagStatements.map((statement, statementIndex) => (
                  <div key={statementIndex}>
                    <p className="tag">{statement}</p>
                  </div>
                ))}
                <div className="fillGreyArea">
                  <h4 className="accordionTrigger closed" onClick={openCloseAccordionSection}>
                    Discourse Feature Breakdown
                  </h4>
                  <div className="accordion">
                    {category.discourse.detail.map((detail, detailIndex) => {
                      const breakdownScore = detail.questionPassed + "/" + detail.totalQuestion;
                      return (
                        <div key={detailIndex}>
                          <div className="tag">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizer(detail._id.langSpecificTagStatements.join(""))
                              }}
                            />
                            <br />
                            <span className="dataLeftSmall" data="100">
                              {breakdownScore}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return null;
      })
    );
  };

  if (document.querySelector(".container.template") !== null) {
    document.querySelector(".container.template").style.maxWidth = "1000px";
  }

  //console.log(props);
  //console.log(state);
  return (
    <>
      {state.edgeCase !== null && (
        <div className="pageBreak">
          <div className="tabs">
            <div className="tabLabel active" id="currentLevel">
              CURRENT LEVEL{" "}
              {props.estimatedLevel !== "below" && props.estimatedLevel !== undefined
                ? props.estimatedLevel
                : props.language === "eng"
                ? "BELOW 1+"
                : "BELOW 1"}
            </div>

            <div className="tabLabel" id="targetLevel" onClick={spin}>
              TARGET LEVEL{" "}
              {props.targetLevel !== "above" && props.targetLevel !== undefined ? props.targetLevel : "ABOVE 3"}
            </div>
          </div>

          <div className="tabContent currentLevel active">
            {props.estimatedLevel !== "below" && props.estimatedLevel !== null ? (
              <>
                <h4 className="center">
                  Based on your performance in this ODA session, your ILR proficiency level estimate is{" "}
                  {props.estimatedLevel} (Current Level).
                </h4>

                <div className="profileGridContainer">
                  <div className="profileContentQuestions">
                    <h2>Content Questions</h2>
                    <p className="descriptor">The meaning of a text, events, details, ideas, or arguments</p>
                    {contentProposition()}
                  </div>

                  <div className="profileLinguisticQuestions">
                    <h2>Linguistic Questions</h2>
                    <p className="descriptor">Key vocabulary, sentence structure and relations between ideas.</p>
                    {lexicon()}
                    {structure()}
                    {discourse()}
                  </div>
                </div>
              </>
            ) : (
              <EdgeCaseBelow
                id={props.userProfileID}
                userProfile={props.userProfile}
                language={props.language}
                modality={props.modality}
                estimatedLevel={props.estimatedLevel}
                targetLevel={props.targetLevel}
              />
            )}

            <div>{speech}</div>
          </div>

          <div className="tabContent targetLevel">
            {props.targetLevel !== "+++" && props.targetLevel !== "***" && props.targetLevel !== "above" ? (
              <>
                <h4 className="center">Your goal is to work toward proficiency {props.targetLevel} (Target Level).</h4>

                <div className="profileGridContainer">
                  <div className="profileContentQuestions">
                    <h2>Content Questions</h2>
                    <p className="descriptor">The meaning of a text, events, details, ideas, or arguments</p>

                    <div className="contentQuestionsTarget">
                      <h2 id="countContentTarget" score={targetContScore}>
                        {" "}
                        {targetContScore}%
                      </h2>
                      <span>Content Questions</span>
                      <div className="centerer">
                        <RotateTarget>
                          <div className="contentArrowTarget" />
                        </RotateTarget>
                      </div>
                      <svg id="contentChartTarget" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          {/* <circle
                    className="ExcellentTarget"
                    r="69.85699"
                    cy="81"
                    cx="81"
                    strokeWidth="18"
                    stroke="#91D59C"
                    fill="none"
                  />

                  <circle
                    className="PassTarget"
                    r="69.85699"
                    cy="81"
                    cx="81"
                    strokeWidth="18"
                    stroke="#73B1DA"
                    fill="none"
                  />

                  <circle
                    className="FailTarget"
                    r="69.85699"
                    cy="81"
                    cx="81"
                    strokeWidth="18"
                    stroke="#d57b7e"
                    fill="none"
                  /> */}
                          <path
                            className="Fail"
                            strokeWidth="18"
                            stroke="#d57b7e"
                            fill="none"
                            d=" M 82 11 A 70 70 0 1 1 20 115"
                          />
                          <path
                            className="Pass"
                            strokeWidth="18"
                            stroke="#73B1DA"
                            fill="none"
                            d=" M 20 115 A 70 70 0 0 1 14 59"
                          />
                          <path
                            className="Excellent"
                            strokeWidth="18"
                            stroke="#91D59C"
                            fill="none"
                            d=" M 14 59 A 70 70 0 0 1 81 11"
                          />
                        </g>
                      </svg>
                      <div className="flagsTarget">
                        <div className="failFlagTarget">Unsatisfactory</div>
                        <div className="passFlagTarget">Satisfactory</div>
                        <div className="excellentFlagTarget">Excellent</div>
                      </div>
                    </div>
                    {contentPropositionTarget()}
                  </div>

                  <div className="profileLinguisticQuestions">
                    <h2>Linguistic Questions</h2>
                    <p className="descriptor">Key vocabulary, sentence structure and relations between ideas.</p>

                    <div className="linguisticQuestionsTarget">
                      <h2 id="countLinguisticTarget" score={targetLingScore}>
                        {targetLingScore}%
                      </h2>
                      <span>Linguistic Questions</span>
                      <div className="centerer">
                        <RotateTarget2>
                          <div className="linguisticArrowTarget" />
                        </RotateTarget2>
                      </div>
                      <svg id="linguisticChartTarget" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <defs>
                            <linearGradient id="lgrad" x1="100%" y1="50%" x2="0%" y2="50%">
                              <stop
                                offset="0%"
                                style={{
                                  stopColor: "#BED8DB",
                                  stopOpacity: "1"
                                }}
                              />
                              <stop
                                offset="100%"
                                style={{
                                  stopColor: "#368B95",
                                  stopOpacity: "1"
                                }}
                              />
                            </linearGradient>
                            <linearGradient id="lgrad2" x1="0%" y1="50%" x2="100%" y2="50%">
                              <stop
                                offset="0%"
                                style={{
                                  stopColor: "#368B95",
                                  stopOpacity: "1"
                                }}
                              />
                              <stop
                                offset="100%"
                                style={{
                                  stopColor: "#0E4A51",
                                  stopOpacity: "1"
                                }}
                              />
                            </linearGradient>
                          </defs>

                          <circle
                            id="Linguistic1"
                            r="69.85699"
                            cy="81"
                            cx="81"
                            strokeWidth="18"
                            stroke="url(#lgrad2)"
                            fill="none"
                          />
                          <circle
                            id="Linguistic2"
                            r="69.85699"
                            cy="81"
                            cx="81"
                            strokeWidth="18"
                            stroke="url(#lgrad)"
                            fill="none"
                          />
                        </g>
                      </svg>
                    </div>
                    {lexiconTarget()}
                    {structureTarget()}
                    {discourseTarget()}
                  </div>
                </div>
                {speechTarget}
              </>
            ) : (
              <EdgeCaseAbove
                id={props.userProfileID}
                userProfile={props.userProfile}
                language={props.language}
                modality={props.modality}
                estimatedLevel={props.estimatedLevel}
                targetLevel={props.targetLevel}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
