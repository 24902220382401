import "../../css/index.css";
import { fadeIn, fadeOut } from "../../functions/genericFunctions";

/**
 * @component RegisterAccountModal
 * @description
 * @param {object} props
 **/

export default function RegisterAccountModal(props) {
  const afterCreation = (e) => {
    // ** .animated jQuery replacement function in vanilla for this purpose
    // ** used in the functions below
    function move(elem, distance, direction) {
      let left = 0;
      let updatedPosition = 0;
      let endingPosition = 0;
      endingPosition = updatedPosition + distance;

      function frame() {
        if (direction == "left") {
          left++;
        } else {
          left--;
        }
        updatedPosition = updatedPosition + left;
        if (direction == "left" && updatedPosition >= endingPosition) {
          updatedPosition = Math.floor(updatedPosition / 400) * 400;
        } else if (direction == "right" && updatedPosition <= endingPosition) {
          updatedPosition = Math.floor(updatedPosition / -400) * -400;
        }
        if (elem !== null) {
          elem.style.left = updatedPosition + "px"; // show frame
        }
        if (
          (updatedPosition >= endingPosition && direction === "left") ||
          (updatedPosition <= endingPosition && direction === "right")
        ) {
          // check finish condition
          clearInterval(id);
        }
      }
      var id = setInterval(frame, 500); // draw every 100ms
    }

    let winWidth = 0;
    if (document.querySelector(".centerWrap").clientWidth !== null) {
      winWidth = document.querySelector(".centerWrap").clientWidth / 2;
    } else {
      winWidth = 0;
    }

    if (document.querySelector(".loginWrap") !== null) {
      if (!document.querySelector(".loginWrap").classList.contains("focused")) {
        move(document.querySelector(".signUpWrap"), winWidth, "right");
        fadeOut(document.querySelector(".signUpWrap"));
        document.querySelector(".loginWrap").style.left = "30%";
        fadeIn(document.querySelector(".loginWrap"));
        document.querySelector("#userEmail").focus();
      }
      document.querySelector(".signUpWrap").classList.remove("focused");
      document.querySelector(".loginWrap").classList.add("focused");
      document.querySelector(".info").classList.remove("info-margin-p150");
      document.querySelector(".info").classList.add("info-margin-p50");
      // close modal thru parent
      props.handleRefModalState(e);
    }
  };

  return (
    <div className="modalDialog" id="RegisterAccountModal">
      <div className="RegisterAccountModal">
        <div className="feedbackHeader" id="modalHeader">
          ODA Account Creation
        </div>
        <h4>Your ODA account has been created!</h4>
        <h5>Please click the link in your email to verify your account.</h5>
        <button className="goLogin outlined" onClick={(e) => afterCreation(e)}>
          OK
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}
