import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import None from "./dashboards/None";
import Select from "./dashboards/Select";
import Resume from "./dashboards/Resume";
import ProfileAll from "./dashboards/ProfileAll";
import JWT from "./JWT";
import { AssessmentContext } from "../context/AssessmentProvider";
import { ErrorContext } from "../context/ErrorProvider";
import ErrorModal from "../components/error/ErrorModal";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import Footer from "../components/footer/Footer";
import dashboardMisc, { handleNewAssessment } from "../functions/dashboardFunctions";
import { useCollectCompleteTestFromDb } from "../hooks/useCollectCompleteTestFromDb";
import { useCollectIncompleteTestFromDb } from "../hooks/useCollectIncompleteTestFromDb";
import { useGetFirstTestlet } from "../hooks/useGetFirstTestlet";
import { useResumeIncomplete } from "../hooks/useResumeIncomplete";
import { useDisplayDiagnosticProfile } from "../hooks/useDisplayDiagnosticProfile";
import { odaDecrypt } from "../functions/genericFunctions";
import useErrorState from "../hooks/useErrorState";
import FAQLinks from "./FAQLinks";

/**
 * @component Dashboard
 * @description primary landing page for signed in users; displays profiles & assessment info
 * @todo clean up left over code, add comments, break up parts into smaller components
 **/

//** Main Dashboard for Signed in Users */
const Dashboard = (props) => {
  //** Initialize State */
  const [state, setState] = useState({
    name: "React",
    showHideNone: true,
    showHideMany: false,
    showHideResume: false,
    lang: "",
    skill: "",
    level: "1",
    url: "",
    checker: false,
    testlet: "",
    incompleteTest: [],
    restoreIncompleteTest: null,
    restoreAssessmentType: null,
    restoredTestlet: null,
    pageNum: null,
    getGrTotal: null,
    getRcTotal: null,
    getLcTotal: null,
    getAllTotal: null,
    grDPDashBoard: true,
    rcDPDashBoard: true,
    lcDPDashBoard: true
  });
  const [countCompletes, setCountCompletes] = useState(null);
  const [countIncomplete, setCountIncomplete] = useState(null);
  const [reloadIncompletes, setReloadIncompletes] = useState(true);
  const [runGetFirstTestlet, setRunGetFirstTestlet] = useState(false);
  const [resumeLoadingModal, setResumeLoadingModal] = useState(false);
  const [resumeEvent, setResumeEvent] = useState(null);
  const [runResumeIncomplete, setRunResumeIncomplete] = useState(false);
  const [runDisplayDiagnosticProfile, setRunDisplayDiagnosticProfile] = useState(false);
  const [profileType, setProfileType] = useState(null);
  const [profileEvent, setProfileEvent] = useState(null);
  const [latestError, setLatestError] = useState(null);
  const [newAssessmentLoading, setNewAssessmentLoading] = useState(false);
  const [useAES] = useState(process.env.REACT_APP_USE_AES.toLowerCase() === "true" ? true : false);
  const [isThursdayShowMessage, setIsThursdayShowMessage] = useState(false);

  // console.log(process.env.REACT_APP_USE_AES);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  //** Initialize useRef needed to tag DOM adjustments before DOM loads */
  const dpShowButton = useRef(null);
  const dpHideButton = useRef(null);
  const resShowButton = useRef(null);
  const resHideButton = useRef(null);

  //**react-router-dom v6.8 version of redirect, initiation */
  const navigate = useNavigate();

  //** Global State Context Calls, links to context/AssessmentProvider.js & context/ErrorProvider.js */
  const [assessmentState, dispatch] = useContext(AssessmentContext);
  const [modalErrorState, errorDispatch] = useContext(ErrorContext);

  //** assessmentState monitor **/
  // useEffect(() => {
  //   console.log("New global state (below).");
  //   console.log(assessmentState);
  // }, [assessmentState]);

  //** localState monitor */
  // useEffect(() => {
  //   console.log("New local state (below).");
  //   console.log(state);
  // }, [state]);

  //** componentDidMount */
  useEffect(() => {
    // check if its Thursday 12:01am to 8:59pm to display the message
    const todayDate = new Date();
    let todayDateDay = todayDate.getDay();
    let todayDateHour = todayDate.getHours();
    if (todayDateDay == 4 && todayDateHour <= 20) {
      setIsThursdayShowMessage(true);
    }

    const footerLogo = document.querySelector(".footerLogo");
    if (footerLogo !== null) {
      footerLogo.style.top = "10px";
      footerLogo.style.height = "67px";
    }
    dashboardMisc();

    window.addEventListener("popstate", (e) => {
      window.location.reload();
    });
  }, []);

  //** customHook to handle all logic for collecting data on a new assessment & loading first testlet on demand */
  const [firstTestletHookData, firstTestletHookRunning, firstTestletHookError] = useGetFirstTestlet(
    state.skill,
    state.lang,
    state.level,
    runGetFirstTestlet
  );
  useEffect(() => {
    if (firstTestletHookData !== null && runGetFirstTestlet === true) {
      setState({ ...state, testlet: firstTestletHookData });
      setRunGetFirstTestlet(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTestletHookData, firstTestletHookRunning, runGetFirstTestlet]);

  //** API to grab completed tests for user */
  const [completedTestData, completedTestLoading, completedTestError] = useCollectCompleteTestFromDb(countCompletes);
  useEffect(() => {
    if (completedTestData !== null && state.getAllTotal === null) {
      setState({
        ...state,
        getGrTotal: completedTestData.GR,
        getRcTotal: completedTestData.RC,
        getLcTotal: completedTestData.LC,
        getAllTotal: completedTestData.ALL,
        showHideNone: false
      });
    }
  }, [completedTestData]);

  //** API to grab incomplete assessments for user */
  const [incompletedTestData, incompletedTestLoading, incompletedTestError] =
    useCollectIncompleteTestFromDb(reloadIncompletes);
  //only run custom hook api call once when requested
  useEffect(() => {
    if (reloadIncompletes) {
      setReloadIncompletes(false);
    }
  }, [reloadIncompletes]);
  //update state from custom hook api after complete
  useEffect(() => {
    if (incompletedTestData !== null) {
      setState({
        ...state,
        incompleteTest: incompletedTestData,
        showHideNone: false
      });
      setCountIncomplete(incompletedTestData);
      //console.log(incompletedTestData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompletedTestData]);

  //** show+hide dashboard pieces section */
  //* for diagnostic profile portion */
  const hideDP = () => {
    setState({ ...state, showHideMany: false });
  };
  const displayDP = () => {
    setState({ ...state, showHideMany: true });
  };

  //* for resume section */
  //hides resume section and updates state
  const hideResume = () => {
    setState({ ...state, showHideResume: false, showHideMany: true });
  };
  //show resume section and updates state
  const displayResume = () => {
    JWT.fetchData()
      .then((result) => {
        const key = result;
        if (key) {
          setState({
            ...state,
            showHideResume: true,
            showHideMany: false
          });
        }
      })
      .catch((error) => {
        setLatestError(error);
      });
  };

  //* logic for DP or resume section display */
  //which buttons to show after all loaded
  useEffect(() => {
    if (state.showHideMany === true && document.querySelector(".DPDisplayIn")) {
      dpShowButton.current.style.display = "none";
      dpHideButton.current.style.display = "inline";
    }
    if (state.showHideMany === false && document.querySelector(".DPDisplayOut")) {
      dpShowButton.current.style.display = "inline";
      dpHideButton.current.style.display = "none";
    }
    if (state.showHideResume === true && document.querySelector(".resumeIn")) {
      resShowButton.current.style.display = "none";
      resHideButton.current.style.display = "inline";
    }
    if (state.showHideResume === false && document.querySelector(".resumeOut")) {
      resShowButton.current.style.display = "inline";
      resHideButton.current.style.display = "none";
    }
  }, [state]);
  //which profile elements to display
  useEffect(() => {
    if (countIncomplete !== null && state.hasOwnProperty("getAllTotal") && state.getAllTotal !== null) {
      if (state.getAllTotal.length > 0) {
        displayDP();
      } else if (countIncomplete["ALL"].length > 0) {
        displayResume();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countIncomplete, state.getAllTotal]);

  //* DP helpers */
  //custom hook to grab and display completed assessment DP
  const [displayDiagnosticProfileData, diagnosticProfileRunning, diagnosticProfileError] = useDisplayDiagnosticProfile(
    profileEvent,
    profileType,
    state.getGrTotal,
    state.getRcTotal,
    state.getLcTotal,
    state.getAllTotal,
    runDisplayDiagnosticProfile,
    state.grDPDashBoard,
    state.rcDPDashBoard,
    state.lcDPDashBoard
  );
  useEffect(() => {
    if (runDisplayDiagnosticProfile) {
      setRunDisplayDiagnosticProfile(false);
    }
  }, [runDisplayDiagnosticProfile]);
  useEffect(() => {
    if (displayDiagnosticProfileData !== null && runDisplayDiagnosticProfile === false && profileType === "grammar") {
      navigate(displayDiagnosticProfileData.navigateTo, {
        state: {
          grID: displayDiagnosticProfileData.grID,
          grTotal: displayDiagnosticProfileData.grTotal,
          grDashboardDP: state.grDPDashBoard
        }
      });
    } else if (
      displayDiagnosticProfileData !== null &&
      runDisplayDiagnosticProfile === false &&
      profileType === "reading"
    ) {
      navigate(displayDiagnosticProfileData.navigateTo, {
        state: {
          rcID: displayDiagnosticProfileData.rcID,
          rcTotal: displayDiagnosticProfileData.rcTotal,
          //rcDashboardDP: state.rcDPDashBord
          rcDashboardDP: state.rcDPDashBoard
        }
      });
    } else if (
      displayDiagnosticProfileData !== null &&
      runDisplayDiagnosticProfile === false &&
      profileType === "listening"
    ) {
      navigate(displayDiagnosticProfileData.navigateTo, {
        state: {
          lcID: displayDiagnosticProfileData.lcID,
          lcTotal: displayDiagnosticProfileData.lcTotal,
          //rcDashboardDP: state.rcDPDashBord
          lcDashboardDP: state.lcDPDashBoard
        }
      });
    }
  }, [displayDiagnosticProfileData, runDisplayDiagnosticProfile, navigate]);

  const grammarProfile = (e) => {
    if (e !== null && runDisplayDiagnosticProfile === false) {
      setProfileEvent(e);
      setProfileType("grammar");
      setRunDisplayDiagnosticProfile(true);
    }
  };
  const readingProfile = (e) => {
    if (e !== null && runDisplayDiagnosticProfile === false) {
      setProfileEvent(e);
      setProfileType("reading");
      setRunDisplayDiagnosticProfile(true);
    }
  };
  const listeningProfile = (e) => {
    if (e !== null && runDisplayDiagnosticProfile === false) {
      setProfileEvent(e);
      setProfileType("listening");
      setRunDisplayDiagnosticProfile(true);
    }
  };

  //* Resume helpers */
  //allow user to delete an incomplete assessment
  const removeIncomplete = useCallback(
    async (event) => {
      setCountIncomplete(null);
      let assessmentTypeGrab = event.target.parentElement.parentElement.attributes.class.value.split(" ");
      let assessmentType;
      if (assessmentTypeGrab.includes("assessmentType_GR")) {
        setState({ ...state, showHideResume: false });
        const id = event.currentTarget.id;
        const res = await axios({
          method: "purge",
          url: "/api/sessionGR/" + id
        });
      } else if (assessmentTypeGrab.includes("assessmentType_RC")) {
        setState({ ...state, showHideResume: false });
        const id = event.currentTarget.id;
        const res = await axios({
          method: "purge",
          url: "/api/sessionRC/" + id
        });
      } else if (assessmentTypeGrab.includes("assessmentType_LC")) {
        setState({ ...state, showHideResume: false });
        const id = event.currentTarget.id;
        const res = await axios({
          method: "purge",
          url: "/api/sessionLC/" + id
        });
      }
      //reset incompleteTest state so hook cycles through and gets latest data
      setReloadIncompletes(true);
      setResumeLoadingModal(false);
    },
    [state]
  );

  //allow user to resume an incomplete assessment
  const [resumeIncompleteData, resumeIncompleteRunning, resumeIncompleteError] = useResumeIncomplete(
    resumeEvent,
    runResumeIncomplete
  );
  useEffect(() => {
    if (runResumeIncomplete) {
      setRunResumeIncomplete(false);
    }
  }, [runResumeIncomplete]);
  //update state from custom hook api after complete
  useEffect(() => {
    if (resumeIncompleteData !== null) {
      //console.log(resumeIncompleteData);
      setState({
        ...state,
        restoreIncompleteTest: resumeIncompleteData.restoreIncompleteTest,
        pageNum: resumeIncompleteData.pageNum,
        restoreAssessmentType: resumeIncompleteData.assessmentType
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeIncompleteData]);
  //function to call the custom hook into action to resume an assessment
  const restorePrevTestlet = (e) => {
    setState({ ...state, showHideResume: false, showHideMany: false });
    document.getElementById("LoadingResumeSpinner")?.scrollIntoView();
    setResumeLoadingModal(true);
    setResumeEvent(e);
    setRunResumeIncomplete(true);
  };

  //helper for updating state for resuming an incomplete assessment
  useEffect(() => {
    if (
      state.restoreIncompleteTest !== [] &&
      state.restoreIncompleteTest !== null &&
      state.restoreIncompleteTest !== undefined &&
      Array.isArray(state.restoreIncompleteTest) &&
      state.restoreIncompleteTest !== "" &&
      state.restoreIncompleteTest !== [[]]
    ) {
      const testlet = JSON.stringify(state.restoreIncompleteTest);
      if (state.restoreAssessmentType === "GR") {
        axios
          .get("api/testletGR?_id=" + testlet)
          .then((response) => {
            let decrypted;
            if (useAES === "true" || useAES === true) {
              decrypted = JSON.parse(odaDecrypt(response.data));
            } else {
              decrypted = response.data;
            }
            setState({
              ...state,
              restoredTestlet: decrypted
            });
          })
          .catch((error) => {
            setLatestError(error);
          });
      } else if (state.restoreAssessmentType === "RC") {
        axios
          .get("api/testletRC?_id=" + testlet)
          .then((response) => {
            let decrypted;
            if (useAES === "true" || useAES === true) {
              decrypted = JSON.parse(odaDecrypt(response.data));
            } else {
              decrypted = response.data;
            }
            //console.log(decrypted);
            setState({
              ...state,
              restoredTestlet: decrypted
            });
          })
          .catch((error) => {
            setLatestError(error);
          });
      } else if (state.restoreAssessmentType === "LC") {
        axios
          .get("api/testletLC?_id=" + testlet)
          .then((response) => {
            let decrypted;
            if (useAES === "true" || useAES === true) {
              decrypted = JSON.parse(odaDecrypt(response.data));
            } else {
              decrypted = response.data;
            }
            //console.log(decrypted);
            setState({
              ...state,
              restoredTestlet: decrypted
            });
          })
          .catch((error) => {
            setLatestError(error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.restoreIncompleteTest]);

  //helper for resuming an assessment once data is loaded
  useEffect(() => {
    if (
      state.restoredTestlet !== [] &&
      state.restoredTestlet !== null &&
      state.restoredTestlet !== undefined &&
      Array.isArray(state.restoredTestlet) &&
      state.restoredTestlet !== "" &&
      state.restoredTestlet !== [[]]
    ) {
      setResumeLoadingModal(false);
      if (state.restoreAssessmentType === "GR") {
        navigate("/grammar", {
          state: {
            testlet: state.restoredTestlet,
            pageNum: state.pageNum
          }
        });
      } else if (state.restoreAssessmentType === "RC") {
        navigate("/reading", {
          state: {
            testlet: state.restoredTestlet,
            pageNum: state.pageNum
          }
        });
      } else if (state.restoreAssessmentType === "LC") {
        navigate("/listening", {
          state: {
            testlet: state.restoredTestlet,
            pageNum: state.pageNum
          }
        });
      }
    }
  }, [state.restoredTestlet, state.pageNum, navigate]);

  //* Error handling section */
  //** errorState custom hook */
  const errorState = useErrorState(latestError);
  useEffect(() => {
    if (modalErrorState.errorUserMessage === null) {
      if (firstTestletHookError !== null) {
        setLatestError(firstTestletHookError);
        //console.log(firstTestletHookError);
      } else if (completedTestError !== null) {
        //console.log(completedTestError);
        setLatestError(completedTestError);
      } else if (incompletedTestError !== null) {
        //console.log(incompletedTestError);
        setLatestError(incompletedTestError);
      } else if (diagnosticProfileError !== null) {
        //console.log(diagnosticProfileError);
        setLatestError(diagnosticProfileError);
      } else if (resumeIncompleteError !== null) {
        //console.log(resumeIncompleteError);
        setLatestError(resumeIncompleteError);
      }
    } //only run if there is nothing already in the errorState
  }, [
    firstTestletHookError,
    completedTestError,
    incompletedTestError,
    diagnosticProfileError,
    resumeIncompleteError,
    modalErrorState
  ]);

  //* New assessment section */
  //helper for initiating click to start a new assessment & updates state/gui
  const accessToTestletDB = useCallback(
    (event) => {
      if (document.querySelector(".overlay")) {
        document.querySelector(".overlay").classList.add("fadeout");
      }
      if (document.querySelector("body")) {
        document.querySelector("body").style.overflow = "auto";
      }
      const value = event.currentTarget.value;
      if (
        value !== "English,grammar,1" &&
        value !== "Russian,grammar,2+/3" &&
        value !== "English,reading,1" &&
        value !== "English,listening,1"
      ) {
        setState({ ...state, checker: true });
        //handle showing spinner for new assessment
        setNewAssessmentLoading(true);
        let modalUL = document.querySelector("div.overlay > div.modal > div#slider");
        if (modalUL !== null) {
          modalUL.style.display = "none";
        }
      }
    },
    [state]
  );

  //listens for the last change in the testlet selection modal and starts a new test on command
  useEffect(() => {
    if (state.level !== null) {
      //dispatch the update to the global state management context reducer (RC/LC only)
      if (state.skill === "reading" || state.skill === "listening") {
        const refLevels = {
          1: "L1",
          "1+": "L1P",
          2: "L2",
          "2+": "L2P",
          3: "L3"
        };
        //dispatch call to Assessment Global State to set current module reference
        dispatch({
          type: "UPDATE_MODULE_REF",
          payload: "Ref." + refLevels[state.level] + ".M1"
        });
      }
      if (state.checker === true) {
        setRunGetFirstTestlet(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checker]);

  //update the state if skill level is adjusted automatically
  //due to moving handleNewAssessment to dashboardFunctions.js
  // (cant use state in external functions)
  useEffect(() => {
    let selectedLevelEl = document.querySelector(".levelSelect.selected");
    if (selectedLevelEl !== null) {
      let selectedLevel = selectedLevelEl.attributes.id.value;
      if (selectedLevel !== state.level && selectedLevel !== null) {
        let curVal = document.querySelector(".begin").attributes.value.value.split(",");
        //console.log("Level mismatch, updating state with changes to .begin value " + selectedLevel.replace("lvl",""));
        document
          .querySelector(".begin")
          .setAttribute("value", curVal[0] + "," + curVal[1] + "," + selectedLevel.replace("lvl", ""));
        setState((prevState) => {
          return { ...prevState, level: selectedLevel.replace("lvl", "") };
        });
      }
    }
  }, [state.skill, state.level]);

  useEffect(() => {
    let modal = document.querySelector("div.overlay > div.modal");
    modal.style.top = "40%";
  }, []);

  //allows user to select their target language and updates state
  const selectLang = useCallback(
    (event) => {
      const value = event.currentTarget.value;
      setState({ ...state, lang: value });
    },
    [state]
  );

  // allows user to select their modality and updates state
  const selectSkill = useCallback(
    (event) => {
      const value = event.currentTarget.value;
      if (value === "grammar") {
        if (document.querySelector(".proficiencyLevel") !== null) {
          document.querySelector(".proficiencyLevel").style.display = "none";
        }
        if (document.querySelector(".grammarLevel") !== null) {
          document.querySelector(".grammarLevel").style.display = "inline";
        }
      } else {
        if (document.querySelector(".grammarLevel") !== null) {
          document.querySelector(".grammarLevel").style.display = "none";
        }
        if (document.querySelector(".proficiencyLevel") !== null) {
          document.querySelector(".proficiencyLevel").style.display = "inline";
        }
      }
      setState({ ...state, skill: value });
    },
    [state]
  );

  //allows user to select their skill level and updates state
  const selectLevel = useCallback(
    (event) => {
      const value = event.currentTarget.id.substr(3);
      setState({ ...state, level: value });
    },
    [state]
  );

  //* routes user to a new assessment based on config */
  let selectTemplateModal = "";
  useEffect(() => {
    if (state.checker && state.testlet !== "" && state.skill !== "") {
      selectTemplateModal = navigate("/" + state.skill, {
        state: { testlet: state.testlet }
      });
    }
  }, [state.checker, state.testlet, state.skill]);

  if (document.querySelector(".container.template") !== null) {
    document.querySelector(".container.template").style.maxWidth = "800px";
  }

  const sendToPage = (url) => {
    navigate(url);
  };

  return (
    <div className="bodyDashboard">
      {isThursdayShowMessage && (
        <h4 className="bannerNotification">
          The ODA website undergoes scheduled maintenance every Thursday, from 1700-2100 (5:00 PM - 9:00 PM) Pacific
          time. There will be intermittent interruptions of service during this maintenance window. Please plan
          accordingly.
        </h4>
      )}
      <h1>Your Assessments</h1>
      <div>
        <div className="containerDashboardBtns">
          <div className="newAssessmentButton">
            <div className="assessments assessmentsBtn ">
              {countIncomplete === null || reloadIncompletes ? (
                ""
              ) : (
                <button
                  className="takeAssessmentBtn startBtn"
                  data-lang=""
                  data-skill=""
                  data-level=""
                  onClick={handleNewAssessment}
                >
                  Start a New Assessment
                </button>
              )}
            </div>

            <div>
              {countIncomplete === null || reloadIncompletes ? (
                <LoadingSpinner msg="Looking for incompleted tests..." />
              ) : (
                countIncomplete !== null &&
                countIncomplete["ALL"]?.length > 0 && (
                  <div className="assessments noAssessments">
                    <button id="resumeBtn" className="resumeIn" onClick={() => displayResume()} ref={resShowButton}>
                      Resume an Assessment <span style={{ color: "#3064CC" }}>→</span>{" "}
                    </button>

                    <button className="resumeOut" onClick={() => hideResume()} ref={resHideButton}>
                      <span style={{ color: "#11747F" }}>←</span> View Diagnostic Profiles{" "}
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div>
          {resumeLoadingModal ? (
            <LoadingSpinner id="LoadingResumeSpinner" msg="Resuming assessment..." />
          ) : state.showHideResume ? (
            <Resume
              restorePrevTestlet={restorePrevTestlet}
              removeIncomplete={removeIncomplete}
              incompleteTest={state.incompleteTest}
            />
          ) : state.showHideMany && state.getAllTotal.length > 0 ? (
            <ProfileAll
              readingProfile={readingProfile}
              listeningProfile={listeningProfile}
              grammarProfile={grammarProfile}
              getGrTotal={state.getGrTotal}
              getRcTotal={state.getRcTotal}
              getLcTotal={state.getLcTotal}
              getAllTotal={state.getAllTotal}
            />
          ) : (
            <None />
          )}
        </div>
      </div>
      <div>
        <div className="DLIfooter">
          <FAQLinks />

          <Footer showReportProblem={false} />
        </div>
      </div>
      <Select
        onSelectLang={selectLang}
        onSelectSkill={selectSkill}
        onSelectLevel={selectLevel}
        lang={state.lang}
        skill={state.skill}
        level={state.level}
        onAccessToTestletDB={accessToTestletDB}
        showLoadingSpinner={newAssessmentLoading}
      />
      {selectTemplateModal}
      <ErrorModal
        errorStatusCode={modalErrorState.errorStatusCode}
        errorUserMessage={modalErrorState.errorUserMessage}
        errorUserMessageDetails={modalErrorState.errorUserMessageDetails}
        errorDevData={modalErrorState.errorDevData}
      />
    </div>
  );
};

export default Dashboard;
