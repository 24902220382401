import React, { useState, useEffect } from "react";
import axios from "axios";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const GroupDPDisplayCompare = () => {
  const [items, setItems] = useState([]);
  const [itemsDetails, setItemsDetails] = useState(null);
  const [lang, setLang] = useState("");
  const [mod, setMod] = useState("");

  const [getAll, setGetAll] = useState(null);

  const defineLang = (lang) => {
    const languages = {
      arz: "Arabic-Egyption",
      qai: "Arabic-Iraqui",
      qav: "Arabic-Levantine",
      ara: "Arabic-MSA",
      cmn: "Chinese",
      hrv: "Croation",
      prs: "Dari",
      eng: "English",
      pes: "Farsi",
      fra: "French",
      heb: "Hebrew",
      kor: "Korean",
      nkr: "Korean/North Korean",
      pus: "Pashto",
      por: "Portuguese",
      rus: "Russian",
      srp: "Serbian",
      som: "Somali",
      spa: "Spanish",
      tgl: "Tagalog",
      tur: "Turkish",
      urd: "Urdu",
    };
    return languages[lang] || lang.toUpperCase();
  };

  useEffect(() => {
    const storedItems = localStorage.getItem("selectedItems");
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setItems(parsedItems);
      const currentLang = defineLang(parsedItems[0].lang);
      setLang(currentLang);
      setMod(parsedItems[0].mod);
      localStorage.removeItem("selectedItems");
    }
  }, []);

  useEffect(() => {
    if (mod && items.length > 0) {
      const sessionIds = items.map((item) => item.sessionId);
      axios
        .post(
          `/api/adminReport/groupMetrics/?mod=${mod}`,
          { profiles: sessionIds },
          { headers: { "content-type": "application/json" } }
        )
        .then((res) => {
          setItemsDetails(res.data);
          console.log("data=", JSON.stringify(res.data));
        })
        .catch((error) => {
          if (error.response) {
            console.error("Error:", error.response);
          }
        });
    }
  }, [mod, items]);

  if (!itemsDetails) {
    return <div>Loading...</div>;
  }

  const {
    targetLevelData,
    mainIdeaData,
    supportingIdeaData,
    subdomainData,
    individualData,
  } = itemsDetails;

  const targetLevels = Object.entries(targetLevelData).map(([level, count]) => {
    const total = Object.values(targetLevelData).reduce(
      (acc, cur) => acc + parseInt(cur, 10),
      0
    );
    const percentage = Math.round((parseInt(count, 10) / total) * 100);
    return {
      name: level,
      value: parseInt(count, 10),
      percentage,
    };
  });

  const maxValue = Math.max(...targetLevels.map((level) => level.value));

  const COLORS = [
    "rgba(24, 118, 128, 0.15)", // L1
    "rgba(24, 118, 128, 0.30)", // L1+
    "rgba(24, 118, 128, 0.45)", // L2
    "rgba(24, 118, 128, 0.60)", // L2+
    "rgba(24, 118, 128, 0.75)", // L3
    "rgba(24, 118, 128, 1)", // Above L3
  ];
  const getColor = (level) => {
    switch (level) {
      case "L1":
        return "rgba(24, 118, 128, 0.15)"; // Color for L1
      case "L1+":
        return "rgba(24, 118, 128, 0.30)"; // Color for L1+
      case "L2":
        return "rgba(24, 118, 128, 0.45)"; // Color for L2
      case "L2+":
        return "rgba(24, 118, 128, 0.60)"; // Color for L2+
      case "L3":
        return "rgba(24, 118, 128, 0.75)"; // Color for L3
      default:
        return "rgba(24, 118, 128, 1)"; // Color for levels above L3
    }
  };

  const displayValue = (key, ideaData) => {
    const percentValue = Math.round(ideaData[key].percent); // Round the percentage to a whole number
    return targetLevelData[key].split(" ")[0] === "0"
      ? "N/A"
      : `${percentValue}% (${ideaData[key].correct}/${ideaData[key].total})`;
  };

  const formatData = (data, level) => {
    if (level === null || parseInt(level) < 1) {
      return "N/A";
    }

    const { totalCorrect = 0, totalQuestions = 0, percent = 0 } = data;
    const calculatedPercent =
      totalQuestions > 0
        ? Math.round((totalCorrect / totalQuestions) * 100)
        : 0;

    return (
      <span>
        &nbsp;{totalCorrect}/{totalQuestions}
        <br />({calculatedPercent}%)
      </span>
    );
  };

  const navigateExternal = (target, options) => {
    if (options.state) {
      localStorage.setItem("state", JSON.stringify(options.state));
    }
    window.open(target, "_blank", "noreferrer");
  };

  const userDP = (itemID, userID, mod) => {
    const user = { userId: userID };
    if (mod === "reading") {
      axios
        .get("/api/sessionRC/diagProfileTopLevelInfo", {
          params: user,
        })
        .then((res) => {
          setGetAll(res.data);

          navigateExternal("/profileAdmin", {
            state: { getID: itemID, getAll: res.data, mod: mod },
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }
    if (mod === "listening") {
      axios
        .get("/api/sessionLC/diagProfileTopLevelInfo", {
          params: user,
        })
        .then((res) => {
          setGetAll(res.data);

          navigateExternal("/profileAdmin", {
            state: { getID: itemID, getAll: res.data, mod: mod },
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }
  };

  const tableStyle = {
    textAlign: "left",
    marginLeft: "25px",
    marginBottom: "50px",
  };

  const tableStyle2 = {
    textAlign: "left",
    marginLeft: "25px",
    marginBottom: "50px",
    borderCollapse: "collapse",
    borderColor: "green",
    borderWidth: "2px",
    borderStyle: "solid",
  };

  const cellStyle = {
    paddingLeft: "15px",
    textAlign: "left",
    color: "#4c7b80",
    fontWeight: "normal",
  };

  const cellStyle2 = {
    border: "1px solid green",
    padding: "6px",
    textAlign: "left",
    color: "black",
    fontWeight: "normal",
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    paddingRight: "25px",
  };

  var modCap = mod.charAt(0).toUpperCase() + mod.slice(1).toLowerCase();

  const filteredTargetLevels = targetLevels.filter(
    (entry) => entry.percentage > 0
  );

  return (
    <div>
      {/* Target Level Table */}
      <h4 style={{ marginLeft: "25px" }}>
        CLASS Target Level Distribution - {lang}: {modCap}
      </h4>
      <table>
        <thead>
          <tr>
            <th style={{ width: "50%" }}> Target Level</th>
            <th style={{ width: "50%", textAlign: "left" }}>{modCap}</th>
          </tr>
        </thead>
        <tbody>
          {targetLevels.map(({ name, value, percentage }, index) => (
            <tr key={index} style={{ backgroundColor: getColor(name) }}>
              <td style={cellStyle2}>{name}</td>
              <td style={cellStyle2}>
                {value} ({percentage}%)
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ResponsiveContainer width="90%" height={400}>
        <PieChart>
          <Pie
            data={filteredTargetLevels}
            cx="55%" // Center the chart horizontally
            cy="50%" // Center the chart vertically
            labelLine={false}
            label={({ name, percent, x, y, midAngle, outerRadius }) => {
              const RADIAN = Math.PI / 180;
              const radius = outerRadius - 136; // Adjust this value to set how close the labels are to the pie slices
              const xOffset = radius * Math.cos(-midAngle * RADIAN);
              const yOffset = radius * Math.sin(-midAngle * RADIAN);

              return (
                <text
                  x={x + xOffset}
                  y={y + yOffset}
                  fill="#4C7B80" // Set text color to black
                  textAnchor={xOffset > 0 ? "start" : "end"} // Adjust text alignment based on position
                  dominantBaseline="central"
                >
                  {`${name}: ${(percent * 100).toFixed(0)}%`}
                </text>
              );
            }}
            outerRadius="70%"
            dataKey="value"
          >
            {filteredTargetLevels.map((entry) => (
              <Cell key={`cell-${entry.name}`} fill={getColor(entry.name)} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>

      <br />
      <br />
      {/* Main and Supporting Idea Table */}
      <h4 style={{ marginLeft: "25px" }}>
        {lang} {modCap}: Main/Supporting Idea Target Level Performance Summary
      </h4>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th></th>
            {Object.keys(mainIdeaData).map((heading, index) => (
              <th key={index}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={cellStyle}>Main Idea</td>
            {Object.keys(mainIdeaData).map((key) => (
              <td style={cellStyle} key={key}>
                {displayValue(key, mainIdeaData)}
              </td>
            ))}
          </tr>
          <tr>
            <td style={cellStyle}>Supporting Idea</td>
            {Object.keys(supportingIdeaData).map((key) => (
              <td style={cellStyle} key={key}>
                {displayValue(key, supportingIdeaData)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      {/* Sub Domain Table */}
      <h4 style={{ marginLeft: "25px" }}>
        {lang} {modCap}: Lexical FLO Subdomain Target Level Performance Summary
      </h4>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th>Sub Domain</th>
            {Object.keys(subdomainData).map((heading, index) => (
              <th key={index}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(subdomainData).map((subDomain) => (
            <tr key={subDomain}>
              <td style={cellStyle}>{subDomain}</td>
              {Object.keys(subdomainData[subDomain]).map((level) => (
                <td style={cellStyle} key={level}>
                  {displayValue(level, subdomainData[subDomain])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
      {/* Individual Data Table */}
      <h4 style={{ marginLeft: "25px" }}>
        {lang} {modCap}: Individual Target Level Performance Summary
      </h4>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th>Email/DP</th>
            <th>Cur/Tgt</th>
            <th>Level</th>
            <th>Main Ideas</th>
            <th>Supp Ideas</th>
            <th>Lex</th>
            <th>Str</th>
            <th>Dis</th>
          </tr>
        </thead>
        <tbody>
          {individualData.map((individual, index) => (
            <React.Fragment
              key={`${individual.userId}-${individual.sessionId}-${index}`}
            >
              <tr
                key={`target-${individual.userId}-${individual.sessionId}-${index}`}
              >
                {console.log(
                  "key=",
                  `target-${individual.userId}-${individual.sessionId}-${index}`
                )}
                <td style={cellStyle}>
                  {individual.user.split("@")[0]}
                  <br />@{individual.user.split("@")[1]}
                </td>
                <td style={cellStyle}>Target</td>
                <td style={cellStyle}>{individual.targetLvl.level}</td>
                <td style={cellStyle}>
                  {formatData(individual.targetLvl.mainProposition)}
                </td>
                <td style={cellStyle}>
                  {formatData(individual.targetLvl.supportingProposition)}
                </td>
                <td style={cellStyle}>
                  {formatData(individual.targetLvl.lexicon)}
                </td>
                <td style={cellStyle}>
                  {formatData(individual.targetLvl.structure)}
                </td>
                <td style={cellStyle}>
                  {formatData(individual.targetLvl.discourse)}
                </td>
              </tr>
              <tr
                key={`current-${individual.userId}-${individual.sessionId}-${index}`}
                style={{ borderBottom: "2px solid #4c7b80" }}
              >
                {console.log(
                  "key=",
                  `current-${individual.userId}-${individual.sessionId}-${index}`
                )}
                <td style={cellStyle}>
                  <button
                    style={{
                      padding: "5px 10px",
                      fontSize: "14px",
                      float: "left",
                    }}
                    onClick={() =>
                      userDP(individual.sessionId, individual.userId, mod)
                    }
                  >
                    DP Link
                  </button>
                </td>
                <td style={cellStyle}>Current</td>
                <td style={cellStyle}>
                  {" "}
                  {individual.currentLvl.level === null
                    ? "Below 1"
                    : individual.currentLvl.level}
                </td>

                <td style={cellStyle}>
                  {formatData(
                    individual.currentLvl.mainProposition,
                    individual.currentLvl.level
                  )}
                </td>
                <td style={cellStyle}>
                  {formatData(
                    individual.currentLvl.supportingProposition,
                    individual.currentLvl.level
                  )}
                </td>
                <td style={cellStyle}>
                  {formatData(
                    individual.currentLvl.lexicon,
                    individual.currentLvl.level
                  )}
                </td>
                <td style={cellStyle}>
                  {formatData(
                    individual.currentLvl.structure,
                    individual.currentLvl.level
                  )}
                </td>
                <td style={cellStyle}>
                  {formatData(
                    individual.currentLvl.discourse,
                    individual.currentLvl.level
                  )}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div style={{ width: "100%", textAlign: "right", paddingRight: "25px" }}>
        <style>
          {`
          @media print {
            table tr, table th, table td {
              background-color: background-color: rgba(24, 118, 128, 0.25); !important;
              -webkit-print-color-adjust: exact;
              color-adjust: exact;
            }

            table th {
              background-color: background-color: rgba(24, 118, 128, 1); !important;
              -webkit-print-color-adjust: exact;
              color-adjust: exact;
            }
          }
        `}
        </style>

        <button style={{ marginRight: "20px" }} onClick={() => window.print()}>
          Print Page
        </button>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default GroupDPDisplayCompare;
