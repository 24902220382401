import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import DateRangePicker from "./adminComponents/DateRangePicker";
import AdminFooter from "./adminComponents/AdminFooter";
import TestletUsageFrequencyDisplay from "./adminDisplay/TestletUsageFrequencyDisplay";

const TestletUsageFrequency = () => {
  const initialState = {
    content: [],
    language: [],
    selectedLanguage: "",
    selectedModality: "",
    selectedSite: "",
    selectedLevel: "",
    isData: false,
    checkAPI: false,
    firstCheck: false,
    excludeAdmin: false,
  };

  const [state, setState] = useState(initialState);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const date = new Date("1970-01-01");
  const isoDate = date.toISOString().slice(0, 10);

  function refreshPage() {
    window.location.reload(false);
  }

  const resetValues = () => {
    setState(initialState);
    setFromDate(null);
    setToDate(null);
  };

  const {
    language,
    selectedLanguage,
    selectedModality,
    selectedSite,
    selectedLevel,
    isData,
    firstCheck,
    excludeAdmin,
  } = state;

  useEffect(() => {
    if (selectedModality === "reading") {
      axios
        .get('/api/language?modalities="reading"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    } else if (selectedModality === "listening") {
      axios
        .get('/api/language?modalities="listening"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    } else if (selectedModality === "grammar") {
      axios
        .get('/api/language?modalities="grammar"&_sort={"sequence": 1}')
        .then((res) => {
          setState({ ...state, language: res.data });
        });
    }
  }, [selectedModality]);

  let langDropdown = language.map((item, index) => (
    <option key={index} value={item._id}>
      {item.displayName}
    </option>
  ));

  const handleLanguageChange = (event) => {
    setState({ ...state, selectedLanguage: event.target.value });
  };

  const handleModalityChange = (event) => {
    const initial = {
      selectedLanguage: "",
      selectedSite: "",
      selectedLevel: "",
      isData: false,
      checkAPI: false,
      firstCheck: false,
    };
    setState({
      ...state,
      selectedModality: event.target.value,
      ...initial,
    });
  };

  const handleSiteChange = (event) => {
    setState({ ...state, selectedSite: event.target.value });
  };

  const handleLevelChange = (event) => {
    setState({ ...state, selectedLevel: event.target.value });
  };

  const handleDatesSelected = (fromDate, toDate) => {
    const pickFrom = new Date(fromDate);
    const isoFrom = pickFrom.toISOString().slice(0, 10);

    const pickTo = new Date(toDate);
    const isoTo = pickTo.toISOString().slice(0, 10);
    setFromDate(isoFrom);
    setToDate(isoTo);
  };
  const handleExcludeAdmin = (event) => {
    setState({ ...state, excludeAdmin: event.target.checked });
  }
  const getValues = () => {
    const user = {
      lang: selectedLanguage,
      mod: selectedModality,
      site: selectedSite,
      lvl: selectedLevel,
      startDate: fromDate,
      endDate: toDate,
      excludeAdmin: excludeAdmin,
    };

    console.log("level=", selectedLevel);

    const valuesAreValid =
      selectedLanguage !== "Select" &&
      selectedModality !== "Select" &&
      selectedSite !== "Select" &&
      selectedLevel !== "Select" &&
      selectedLanguage !== "" &&
      selectedModality !== "" &&
      selectedSite !== "" &&
      selectedLevel !== "" &&
      fromDate !== isoDate &&
      toDate !== isoDate &&
      fromDate !== null &&
      toDate !== null &&
      excludeAdmin !== null;

    if (valuesAreValid) {
      console.log("values=", {
        lang: selectedLanguage,
        mod: selectedModality,
        site: selectedSite,
        lvl: selectedLevel,
        startDate: fromDate,
        endDate: toDate,
        excludeAdmin: excludeAdmin,
      });
      axios
        .get("/api/adminReport/testletUsageReport", { params: user })
        .then((res) => {
          setState((prevState) => ({
            ...prevState,
            content: res.data,
            isData: true,
            checkAPI: true,
          }));
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            isData: false,
            firstCheck: true,
          }));
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
            }
          }
        });
    } else {
      console.log("No value can be null");
      alert("Select all items");
    }
  };

  return (
    <div className="adminMainPanel">
      <div className="infoTitle adminInfoTitle">
        <div>Testlet Usage Frequency</div>
      </div>
      <div>
        Modality:
        <select
          key="modality"
          value={selectedModality}
          onChange={handleModalityChange}
        >
          <option>Select</option>
          <option value="reading">Reading</option>
          <option value="listening">Listening</option>
          <option value="grammar">Grammar</option>
        </select>
      </div>
      <div>
        Language:
        <select
          key="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          <option>Select</option>
          {langDropdown}
        </select>
      </div>
      <div>
        Site:
        <select key="site" value={selectedSite} onChange={handleSiteChange}>
          <option>Select</option>
          <option value="oda">ODA</option>
        </select>
      </div>
      {selectedModality === "reading" || selectedModality === "listening" ? (
        <div>
          Testlet Level:
          <select
            key="level"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            {selectedLanguage === "eng" ? (
              <>
                <option>Select</option>
                <option value="all">All</option>
                <option value="1+">1+</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
                <option value="3">3</option>
              </>
            ) : (
              <>
                <option>Select</option>
                <option value="all">All</option>
                <option value="1">1</option>
                <option value="1+">1+</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
                <option value="3">3</option>
              </>
            )}
          </select>
        </div>
      ) : selectedModality === "grammar" ? (
        <div>
          Testlet Level:
          <select
            key="level"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            {selectedLanguage === "eng" ? (
              <>
                <option>Select</option>
                <option value="all">All</option>
                <option value="1+/2">1+/2</option>
                <option value="2+/3">2+/3</option>
              </>
            ) : selectedLanguage === "rus" ? (
              <>
                <option>Select</option>
                <option value="all">All</option>
                <option value="1">1</option>
                <option value="1+/2">1+/2</option>
              </>
            ) : (
              <>
                <option>Select</option>
                <option value="all">All</option>
                <option value="1">1</option>
                <option value="1+/2">1+/2</option>
                <option value="2+/3">2+/3</option>
              </>
            )}
          </select>
        </div>
      ) : (
        <div>
          Proficiency Level:
          <select
            key="level"
            value={selectedLevel}
            onChange={handleLevelChange}
          >
            <option>Select</option>
          </select>
        </div>
      )}

      <DateRangePicker onDatesSelected={handleDatesSelected} />
      <div>
        Exclude Admin Assessments:
        <input type="checkbox" onChange={handleExcludeAdmin} />
      </div>
      <div className="buttonGroup">
        <div onClick={getValues} className="searchButton">
          Get Records
        </div>
        {/* <div onClick={resetValues} className="searchButton"> */}
        <div onClick={refreshPage} className="searchButton">
          Reset Inputs
        </div>
      </div>
      <div className="adminContentBox" style={{ overflow: "auto" }}>
        <TestletUsageFrequencyDisplay
          content={state.content}
          isData={state.isData}
          checkAPI={state.checkAPI}
          firstCheck={state.firstCheck}
        />
      </div>

      <AdminFooter />
    </div>
  );
};

export default TestletUsageFrequency;
