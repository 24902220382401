import { useState, useEffect, useContext, useCallback } from "react";
import { ErrorContext } from "../../context/ErrorProvider";
import { Link } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import JWT from "../../contents/JWT";
import { AssessmentContext } from "../../context/AssessmentProvider";
import EmailNotVerifiedModal from "../../components/modals/EmailNotVerifiedModal";
import { odaEncrypt } from "../../functions/genericFunctions";

const Login = (props) => {
  //Global State Context Calls, links to context/AssessmentProvider.js
  const [, errorDispatch] = useContext(ErrorContext);
  const [, dispatch] = useContext(AssessmentContext); //assessmentState is never used, removed initial item
  const [notVerifiedModal, setNotVerifiedModal] = useState(false);
  const [state, setState] = useState({
    url: "",
    input: {},
    errors: {},
    frontEndErrors: {},
    rememberMe: true
  });
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      // console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  useEffect(() => {
    //componentDidMount (but not a useEffect[] because of state.rememberMe)
    if (state.rememberMe) {
      JWT.fetchEmail().then((result) => {
        if (result) {
          document.querySelector("#userEmail").value = result;
        } else {
          document.querySelector("#userEmail").value = "";
        }
      });
    } else {
      document.querySelector("#userEmail").value = "";
    }
  }, [state.rememberMe, dispatch]);

  const handleChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;
    setState({ ...state, [input.name]: value });
  };

  const onChange = (event) => {
    let input = state.input;
    input[event.target.name] = event.target.value;
    setState({ ...state, input });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const checkValidation = validateUser();
    if (checkValidation) {
      const user = { email: odaEncrypt(state.input.email), password: odaEncrypt(state.input.password) };

      // sessionStorage.setItem("userId", result);
      axios
        .post("/api/user/login", user)
        .then((res) => {
          //console.log("LOG IN SUCCESSFUL", res.data);
          localStorage.setItem("token", JSON.stringify(res.data));
          dispatch({
            type: "UPDATE_TOKEN",
            payload: res.data.token //adjust for res.data object if replacing localStorage (string to object)
          });
          JWT.fetchEmail().then((result) => {
            if (result) {
              dispatch({
                type: "UPDATE_USER_EMAIL",
                payload: result
              });
            }
          });
          JWT.fetchData().then((result) => {
            if (result) {
              dispatch({
                type: "UPDATE_USER_ID",
                payload: result
              });
            }
          });

          const trackingObject = {
            appName: "CMS-ODA",
            eventType: "UserLoggedIn",
            eventDetails: {
              userEncrypted: odaEncrypt(state.input.email)
            },
            dateTime: new Date().toISOString(),
            location: window.location
          };
          axios.post("/api/tag", trackingObject).catch((error) => {
            console.log(error);
          });
          // const devURL = "";
          // const stagURL = "https://oda2stag.dliflc.edu";
          // const prodURL = "https://oda.dliflc.edu";
          // const port = ":5005";
          // const api = "/api/tag";
          // if (window.location.hostname === "localhost") {
          //   axios.post(devURL + api, trackingObject).catch((error) => {
          //     console.log(error);
          //   });
          // } else if (window.location.hostname === "oda2stag.dliflc.edu") {
          //   axios.post(stagURL + api, trackingObject).catch((error) => {
          //     console.log(error);
          //   });
          // } else {
          //   axios.post(prodURL + api, trackingObject).catch((error) => {
          //     console.log(error);
          //   });
          // }
          props.handleSuccessfulLogIn(res.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setNotVerifiedModal(true);
            //alert("This account must be verified before you are able to log in.  Please check your email for a verification link.");
          } else if (error.response.status === 400) {
            let frontEndErrors = {};
            if (error.response.data.hasOwnProperty("password")) {
              frontEndErrors["password"] = error.response.data.password;
            }
            if (error.response.data.hasOwnProperty("email")) {
              frontEndErrors["email"] = error.response.data.email;
            }
            setState({ ...state, frontEndErrors: frontEndErrors });
          } else {
            setState({ ...state, errors: error.response.data });
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
                //global error dispatch call to show error modal if error received during api call
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: error.response.status,
                    errorUserMessage: "New Error",
                    errorDevData: error.response
                  }
                });
              }
            }
          }
        });
    }

    const currentTime = new Date().getTime();
    const timer = setInterval(function () {
      const accessToken = localStorage.getItem("token");
      //const accessToken = assessmentState.assessmentUserToken;
      if (accessToken !== null) {
        const base64Url = accessToken.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const iatJWT = JSON.parse(window.atob(base64)).iat;
        const expJWT = JSON.parse(window.atob(base64)).exp;
        const expDuration = expJWT - iatJWT;
        if (new Date().getTime() >= currentTime + expDuration) {
          localStorage.removeItem("token");
          //console.log(new Date().getTime());
          //console.log(currentTime);
          //console.log(expDuration);
          //console.log("deleted token");

          dispatch({
            type: "UPDATE_TOKEN",
            payload: null
          });
        }
      } else {
        clearInterval(timer);
      }
    }, 1000);
  };

  const validateUser = () => {
    let input = state.input;
    let frontEndErrors = {};
    let isValid = true;
    if ((input["email"] === undefined || input["email"] === "") && document.querySelector("#userEmail").value !== "") {
      input["email"] = document.querySelector("#userEmail").value;
    }
    if (
      (input["password"] === undefined || input["password"] === "") &&
      document.querySelector("#password").value !== ""
    ) {
      input["password"] = document.querySelector("#password").value;
    }
    // }

    if (input["email"] === "undefined") {
      isValid = false;
      frontEndErrors["email"] = "Please enter your email address.";
    }

    if (typeof input["email"] !== "undefined") {
      const emailRegEx = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!emailRegEx.test(input["email"])) {
        isValid = false;
        frontEndErrors["email"] = "Please enter valid email address.";
      }
    }

    if (!input["password"]) {
      isValid = false;
      frontEndErrors["password"] = "Please enter your password.";
    }

    if (typeof input["password"] !== "undefined") {
      if (input["password"].length < 8) {
        isValid = false;
        frontEndErrors["password"] = "Passwords must be 8 or more characters.";
      }
    }

    if (typeof input["password"] !== "undefined") {
      if (input["password"].length > 16) {
        isValid = false;
        frontEndErrors["password"] = "Maximum password length is 16 characters.";
      }
    }

    setState({ ...state, frontEndErrors: frontEndErrors });

    return isValid;
  };

  const refModalState = useCallback(() => {
    setNotVerifiedModal(false);
  }, []);

  const { errors } = state;
  return (
    <div className="loginWrap focused">
      <div className="login">
        <span className="cardTitle">Sign In</span>
        <form onSubmit={onSubmit}>
          <label htmlFor="userEmail">
            Email
            <input
              type="email"
              name="email"
              id="userEmail"
              value={state.email}
              onChange={onChange}
              autoComplete="off"
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            <div className="text-danger">{state.frontEndErrors.email}</div>
          </label>

          <label htmlFor="password">
            Password
            {/* <a href={state.url} className="forgot">
              Forgot Password?
            </a> */}
            <p className="forgot">
              <Link to={"/forgot"}>Forgot Password?</Link>
            </p>
            <input
              id="password"
              type="password"
              className="password"
              name="password"
              value={state.password}
              onChange={onChange}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
            <div className="text-danger">{state.frontEndErrors.password}</div>
            <span className="eye password" title="Show/Hide password" />
          </label>

          <label className="check">
            Remember me
            <input
              name="rememberMe"
              type="checkbox"
              checked={state.rememberMe}
              onChange={handleChange}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            <span className="checkmark" />
          </label>
          <button className="loginBtn">Sign In</button>
        </form>
      </div>

      <div className="afterLogin">
        <div className="newLine" />
        <div className="new">New to ODA?</div>
        <div className="signUpBtn">Create an Account</div>
      </div>

      {notVerifiedModal && <EmailNotVerifiedModal refModalState={refModalState} parentState={state} />}
    </div>
  );
};

export default Login;
