import { useState, useEffect, useContext } from "react";
import { AssessmentContext } from "../context/AssessmentProvider";
import { odaDecrypt } from "../functions/genericFunctions";
import axios from "axios";
import axiosRetry from "axios-retry";

export function useResumeIncomplete(resumeEvent, runResumeIncomplete) {
  //** Global State Context Calls, links to context/AssessmentProvider.js */
  const [assessmentState, dispatch] = useContext(AssessmentContext);
  const [data, setData] = useState(null);
  const [useAES] = useState(process.env.REACT_APP_USE_AES.toLowerCase() === "true" ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setLatestError] = useState(null);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
    retryCondition: (err) => {
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  useEffect(() => {
    if (
      resumeEvent !== null &&
      runResumeIncomplete !== false &&
      !(
        (resumeEvent.target.nodeName === "BUTTON" && resumeEvent.target.innerHTML === "Delete") ||
        resumeEvent.target.attributes.hasOwnProperty("id") == false
      )
    ) {
      setIsLoading(true);
      //clear previous assessmentState
      dispatch({
        type: "NEW_ASSESSMENT_RESET",
        payload: null
      });
      // console.log(resumeEvent);
      const sessionID = resumeEvent.target.attributes.id.value;
      const pageNum = resumeEvent.target.attributes.page.value;
      let assessmentTypeGrab = resumeEvent.target.attributes.class.value.split(" ");
      let assessmentType;
      let grLvl;
      if (assessmentTypeGrab.includes("assessmentType_GR")) {
        assessmentType = "GR";
        if (assessmentTypeGrab.includes("assessmentLvl_1")) {
          grLvl = 1;
        } else if (assessmentTypeGrab.includes("assessmentLvl_1+/2")) {
          grLvl = 2;
        } else if (assessmentTypeGrab.includes("assessmentLvl_2+/3")) {
          grLvl = 3;
        }
      } else if (assessmentTypeGrab.includes("assessmentType_RC")) {
        assessmentType = "RC";
      } else if (assessmentTypeGrab.includes("assessmentType_LC")) {
        assessmentType = "LC";
      }
      let getDate = new Date().toString();
      let resumedtime = '{"resumedtime":"' + getDate + '"}';

      const trackingObject = {
        appName: "CMS-ODA",
        eventType: "UserResumedAssessment",
        eventDetails: {
          sessionID: sessionID,
          modality: assessmentType
        },
        dateTime: new Date().toISOString(),
        location: window.location
      };
      axios.post("/api/tag", trackingObject).catch((error) => {
        console.log(error);
      });
      // const devURL = "";
      // const stagURL = "https://oda2stag.dliflc.edu";
      // const prodURL = "https://oda.dliflc.edu";
      // const port = ":5005";
      // const api = "/api/tag";
      // if (window.location.hostname === "localhost") {
      //   axios.post(devURL + api, trackingObject).catch((error) => {
      //     console.log(error);
      //   });
      // } else if (window.location.hostname === "oda2stag.dliflc.edu") {
      //   axios.post(stagURL + api, trackingObject).catch((error) => {
      //     console.log(error);
      //   });
      // } else {
      //   axios.post(prodURL + api, trackingObject).catch((error) => {
      //     console.log(error);
      //   });
      // }
      //update global state's sessionId to resumed Id
      dispatch({
        type: "UPDATE_SESSION_ID",
        payload: sessionID
      });

      if (assessmentType === "GR") {
        dispatch({
          type: "UPDATE_MODULE_REF",
          payload: "GR-" + grLvl
        });
        dispatch({
          type: "UPDATE_MODALITY",
          payload: "GR"
        });
        axios({
          method: "put",
          headers: { "content-type": "application/json" },
          url: "/api/sessionGR/" + sessionID,
          data: resumedtime
        })
          .then((res) => {
            axios
              .get("/api/sessionGR/" + sessionID)
              .then((response) => {
                let decrypted;
                if (useAES === "true" || useAES === true) {
                  decrypted = JSON.parse(odaDecrypt(response.data));
                } else {
                  //decrypted = JSON.parse(response.data);
                  decrypted = JSON.parse(JSON.stringify(response.data));
                }
                dispatch({
                  //per #362--probably ok here as initial language set to NKR will persist
                  type: "UPDATE_LANGUAGE",
                  payload: decrypted.language
                });
                //changed "testsets" to "modules" to match GR API changes 10/6
                setData({
                  restoreIncompleteTest: decrypted.modules[0].generatedTestlets,
                  pageNum: pageNum,
                  assessmentType: assessmentType
                });
                //setData({ restoreIncompleteTest: response.data.modules[0].generatedTestlets, pageNum: pageNum });
              })
              .catch((error) => {
                setLatestError(error);
              });
          })
          .catch((error) => {
            setLatestError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (assessmentType === "RC") {
        axios({
          method: "put",
          headers: { "content-type": "application/json" },
          url: "/api/sessionRC/" + sessionID,
          data: resumedtime
        })
          .then((res) => {
            axios
              .get("/api/sessionRC/" + sessionID)
              .then((response) => {
                let decrypted;
                if (useAES === "true" || useAES === true) {
                  decrypted = JSON.parse(odaDecrypt(response.data));
                } else {
                  //decrypted = JSON.parse(response.data);
                  decrypted = JSON.parse(JSON.stringify(response.data));
                }
                sessionStorage.setItem("sessionID", decrypted._id);
                dispatch({
                  type: "UPDATE_MODALITY",
                  payload: "RC"
                });
                dispatch({
                  type: "UPDATE_FROM_INCOMPLETE_SESSION",
                  payload: {
                    assessmentSkillFloor: decrypted.skillFloor,
                    assessmentModuleRef: decrypted.moduleRef,
                    assessmentScoreMatrix: decrypted.scoreMatrix,
                    assessmentTestletMatrix: decrypted.testletMatrix,
                    assessmentModuleData: decrypted.moduleData,
                    assessmentModulesRCLC: decrypted.modules,
                    assessmentNLPMatrix: decrypted.NLPMatrix,
                    assessmentSessionId: decrypted._id,
                    assessmentLanguage: decrypted.language
                  }
                });
                let curRef = decrypted.moduleRef.split(".");
                let activeTestlets = decrypted.testletMatrix[curRef[1]][parseInt(curRef[2].slice(-1)) - 1];
                setData({ restoreIncompleteTest: activeTestlets, pageNum: pageNum, assessmentType: assessmentType });
                //setData({ restoreIncompleteTest: response.data.modules[0].generatedTestlets, pageNum: pageNum });
              })
              .catch((error) => {
                setLatestError(error);
              });
          })
          .catch((error) => {
            setLatestError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (assessmentType === "LC") {
        axios({
          method: "put",
          headers: { "content-type": "application/json" },
          url: "/api/sessionLC/" + sessionID,
          data: resumedtime
        })
          .then((res) => {
            axios
              .get("/api/sessionLC/" + sessionID)
              .then((response) => {
                // console.log(response);
                let decrypted;
                if (useAES === "true" || useAES === true) {
                  decrypted = JSON.parse(odaDecrypt(response.data));
                } else {
                  //decrypted = JSON.parse(response.data);
                  decrypted = JSON.parse(JSON.stringify(response.data));
                }
                // console.log(decrypted);
                sessionStorage.setItem("sessionID", decrypted.session);
                dispatch({
                  type: "UPDATE_MODALITY",
                  payload: "LC"
                });
                dispatch({
                  type: "UPDATE_FROM_INCOMPLETE_SESSION",
                  payload: {
                    assessmentSkillFloor: decrypted.skillFloor,
                    assessmentModuleRef: decrypted.moduleRef,
                    assessmentScoreMatrix: decrypted.scoreMatrix,
                    assessmentTestletMatrix: decrypted.testletMatrix,
                    assessmentModuleData: decrypted.moduleData,
                    assessmentModulesRCLC: decrypted.modules,
                    assessmentNLPMatrix: decrypted.NLPMatrix,
                    assessmentSessionId: decrypted._id,
                    assessmentLanguage: decrypted.language
                  }
                });
                let curRef = decrypted.moduleRef.split(".");
                let activeTestlets = decrypted.testletMatrix[curRef[1]][parseInt(curRef[2].slice(-1)) - 1];
                setData({ restoreIncompleteTest: activeTestlets, pageNum: pageNum, assessmentType: assessmentType });
                // let curRef = decrypted.assessmentModuleRef.split(".");
                // let activeTestlets = decrypted.assessmentTestletMatrix[curRef[1]][curRef[2].slice(-1)];
                // setData({ restoreIncompleteTest: activeTestlets, pageNum: pageNum, assessmentType: assessmentType });
                //setData({ restoreIncompleteTest: response.data.modules[0].generatedTestlets, pageNum: pageNum });
              })
              .catch((error) => {
                setLatestError(error);
              });
          })
          .catch((error) => {
            setLatestError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [resumeEvent, runResumeIncomplete, isLoading, dispatch, useAES]);

  //useErrorState(error,"UPDATE_ERROR_STATE","New Error");

  return [data, isLoading, error];
}
