import CryptoJS from "crypto-js";
/**
 * Universal generic functions that can be employeed on any page should live here.
 *
 */

// ** replaces jQuery show/hide/toggle **
// Show an element
export function show(elem) {
  if (elem !== null) {
    elem.style.display = "block";
  }
}

// Hide an element
export function hide(elem) {
  if (elem !== null) {
    elem.style.display = "none";
  }
}

// Toggle element visibility
export function toggle(elem) {
  // If the element is visible, hide it
  if (window.getComputedStyle(elem).display === "block") {
    hide(elem);
    return;
  }

  // Otherwise, show it
  show(elem);
}

// ** replaces jQuery fadeOut **
export function fadeOut(el) {
  if (el !== null) {
    el.style.opacity = 1;
    //eslint-disable-next-line
    (function fade() {
      if ((el.style.opacity -= 0.05) < 0) {
        el.style.display = "none";
      } else {
        requestAnimationFrame(fade);
      }
    })();
  }
}

// ** replaces jQuery fadeIn **
export function fadeIn(el, duration = 0.05, display = "block") {
  if (el !== null) {
    el.style.opacity = 0;
    el.style.display = display;
    //eslint-disable-next-line
    (function fade() {
      var val = parseFloat(el.style.opacity);
      if (!((val += duration) > 1)) {
        el.style.opacity = val;
        requestAnimationFrame(fade);
      }
    })();
  }
}

// ** These functions enable us to open and close an accordion section easily **
//Usage (in other file: 1. import { openCloseAccordionSection } from this file)
//Usage (in other file: 2. in a returned element, onclick={openCloseAccordionSection} )
//That"s it!  See GrammarDetails.js for initial implementation

export let slideUp = (target, duration = 0) => {
  if (target !== null) {
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.boxSizing = "border-box";
    target.style.height = target.offsetHeight + "px";
    //target.offsetHeight = null;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
      target.style.display = "none";
      target.style.removeProperty("height");
      target.style.removeProperty("padding-top");
      target.style.removeProperty("padding-bottom");
      target.style.removeProperty("margin-top");
      target.style.removeProperty("margin-bottom");
      target.style.removeProperty("overflow");
      target.style.removeProperty("transition-duration");
      target.style.removeProperty("transition-property");
      //alert("!");
    }, duration);
  }
};

export let slideDown = (target, duration = 0) => {
  if (target !== null) {
    target.style.removeProperty("display");
    //let display = window.getComputedStyle(target).display;
    //console.log(display);
    //if (display === "none") display = "block";
    //target.style.display = display;
    target.style.display = "inline";
    let height = target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    //target.offsetHeight = null;
    target.style.boxSizing = "border-box";
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.height = height + "px";
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    window.setTimeout(() => {
      target.style.removeProperty("display");
      target.style.removeProperty("height");
      target.style.removeProperty("overflow");
      target.style.removeProperty("transition-duration");
      target.style.removeProperty("transition-property");
    }, duration);
  }
};

export let slideToggle = (target, duration = 0) => {
  // console.log(target);
  // console.log(window.getComputedStyle(target));
  // console.log(window.getComputedStyle(target).display);
  // console.log(target.style.display);
  if (window.getComputedStyle(target).display === "none") {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
};

export let openCloseAccordionSection = (e, eventData) => {
  if (e.target.classList.contains("open")) {
    e.target.classList.remove("open");
    e.target.classList.add("closed");
  } else {
    e.target.classList.remove("closed");
    e.target.classList.add("open");
  }
  slideToggle(e.target.nextSibling);
};

export let openCloseAccordionSectionDiscrete = (e, eventData) => {
  //console.log(eventData);
  //console.log(e.target);
  //console.log(e.target.classList);
  if (e.target !== null && e.target.classList.contains("open")) {
    //console.log("closing");
    //console.log(e.target.nextSibling);
    e.target.classList.remove("open");
    e.target.classList.add("closed");
    let piece = e.target.nextSibling;
    piece.style.setProperty("display", "none", "important");
  } else if (e.target !== null && e.target.classList.contains("closed")) {
    //console.log("opening");
    //console.log(e.target.nextSibling);
    e.target.classList.remove("closed");
    e.target.classList.add("open");
    let piece = e.target.nextSibling;
    piece.style.setProperty("display", "block", "important");
  }
  //slideToggle(e.target.nextSibling);
};

export let openCloseAccordionSectionDashboard = (e, eventData) => {
  // console.log(e);
  //console.log(e.target);
  //console.log(e.target.classList);
  let thisClassList = e.target.classList;
  //console.log(thisClassList)
  // console.log(e.target.parentElement);
  var nodes = e.target.parentElement.childNodes;
  if (thisClassList.contains("open")) {
    //console.log(e.target.classList);
    //console.log("slideDown");
    e.target.classList.replace("open", "closed");
    for (var i = 1; i < nodes.length; i++) {
      slideDown(nodes[i], 0);
    }
  } else if (thisClassList.contains("closed")) {
    e.target.classList.replace("closed", "open");
    //console.log("slideUp");
    for (var i = 1; i < nodes.length; i++) {
      slideUp(nodes[i], 0);
    }
  } else {
    let toggleClasses = ["open", "closed"];
    e.target.classList.remove(...toggleClasses);
    e.target.classList.add("open");
    for (var i = 1; i < nodes.length; i++) {
      slideDown(nodes[i], 0);
    }
  }
  //console.log(e.target.classList);
};

// ** replaces jQuery .offset() **
export let offset = (singleElement) => {
  let inputType = typeof singleElement;
  let rect;
  if (inputType === "object") {
    rect = singleElement.getBoundingClientRect;
  } else {
    rect =
      document.querySelector(singleElement) !== null && document.querySelector(singleElement) !== undefined
        ? document.querySelector(singleElement).getBoundingClientRect() !== null
          ? document.querySelector(".wrapper").getBoundingClientRect()
          : { top: 0, right: 0, bottom: 0, left: 0 }
        : { top: 0, right: 0, bottom: 0, left: 0 };
  }
  var offset = {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX
  };
  return offset;
};

// ** replacing jQuery .offset(coords) **
export let offsetCoords = (selector, x, y, useChild = false) => {
  let element;
  if (!useChild) {
    element = document.querySelector(selector);
  } else {
    element = document.querySelector(selector).firstChild;
  }
  if (element !== null) {
    element.style.position = "fixed";
    element.style.margin = "0px";
    element.style.left = x;
    element.style.top = y;
  }
};

// ** replaces jQuery .next() **
export let next = function (elem, selector) {
  // Get the next element
  var nextElem = elem.nextElementSibling;
  // If there's no selector, return the next element
  if (!selector) {
    return nextElem;
  }
  // Otherwise, check if the element matches the selector
  if (nextElem && nextElem.matches(selector)) {
    return nextElem;
  }
  // if it's not a match, return null
  return null;
};

/**
 * @function checkEnglishInput
 * @description takes form input and ensures it contains 0 non-english characters
 * @returns boolean
 */
export function checkEnglishInput(input) {
  if (input.match(/^[A-Za-z0-9(),.<>?/:"'&%$#@-\[\]{}!^\\ ]*$/g)) {
    return true;
  } else {
    return false;
  }
}

/**
 * @function odaEncrypt
 * @description encrypts using AES CBC (default crypto-js)
 **/

export let odaEncrypt = function (toEncrypt) {
  //use crypto-js to encrypt
  var _0x86dc = [
    "\x44\x28\x47\x2B\x4B\x61\x50\x64\x53\x67\x56\x6B\x59\x70\x33\x73\x36\x76\x39\x79\x24\x42\x26\x45\x29\x48\x40\x4D\x63\x51\x65\x54",
    "\x65\x6E\x63\x72\x79\x70\x74",
    "\x41\x45\x53"
  ];
  let encryptedObj = CryptoJS[_0x86dc[2]][_0x86dc[1]](toEncrypt, _0x86dc[0]);
  //convert output byte data to url safe string
  let encrypted = encodeURIComponent(encryptedObj).toString();

  return encrypted;
};

/**
 * @function odaDecrypt
 * @description decrypts using AES CBC (default crypto-js)
 **/

export let odaDecrypt = function (toDecrypt) {
  //use crypto-js to decrypt
  var _0xb751 = [
    "\x44\x28\x47\x2B\x4B\x61\x50\x64\x53\x67\x56\x6B\x59\x70\x33\x73\x36\x76\x39\x79\x24\x42\x26\x45\x29\x48\x40\x4D\x63\x51\x65\x54",
    "\x64\x65\x63\x72\x79\x70\x74",
    "\x41\x45\x53"
  ];
  let decryptedObj = CryptoJS[_0xb751[2]][_0xb751[1]](decodeURIComponent(toDecrypt), _0xb751[0]);
  let decrypted = decryptedObj.toString(CryptoJS.enc.Utf8);

  return decrypted;
};

// ** multiple depth check for object properties **
export let checkPath = (o, path) =>
  o !== null
    ? path.includes(".")
      ? o.hasOwnProperty(path.split(".")[0])
        ? checkPath(o[path.split(".")[0]], path.split(".").slice(1).join("."))
        : false
      : o.hasOwnProperty(path)
    : false;
