// ** All code for diagnostic profile functions should go here **//
// ** replaces jQuery formerly in dp.js **
import { fadeIn } from "./genericFunctions";

export default function profileFuntions() {
  // ** replaces jQuery.animate() for these purposes **
  function moveTopPercent(elem, stop, start) {
    //adjust style.top so that an element slides upwards (top:%--)

    var top = 0; //increment
    var updatedPosition = start; //initial top%
    var endingPosition = stop.replace("%", ""); //final top%

    //iterate
    function frame() {
      top--;
      updatedPosition = updatedPosition + top;
      if (elem !== null) {
        elem.style.top = updatedPosition + "%"; // show frame
        if (updatedPosition <= endingPosition) {
          elem.style.top = stop + "%";
          // check finish condition
          clearInterval(id);
        }
      }
    }
    var id = setInterval(frame, 75); // draw every 75ms
  }

  //refreshes diagnostic profile label tabs, but needs a few extra refreshes at times, so using interval
  const setupLabels = (theTabNodes) => {
    for (let i = 0; i < theTabNodes.length; i++) {
      theTabNodes[i].addEventListener("click", (e) => {
        let id =
          e.target.attributes.hasOwnProperty("id") && e.target.attributes.id.value !== undefined
            ? e.target.attributes.id.value
            : "none";
        if (id !== "none") {
          let selEl2 = document.querySelectorAll(".tabLabel, .tabContent");
          if (selEl2.length > 0) {
            for (let j = 0; j < selEl2.length; j++) {
              selEl2[j].classList.remove("active");
            }
          } else {
            selEl2.classList.remove("active");
          }
          theTabNodes[i].classList.add("active");

          let selEl3 = document.querySelector("." + id);
          if (selEl3.length > 0) {
            for (let k = 0; k < selEl3.length; k++) {
              selEl3[k].classList.add("active");
            }
          } else {
            selEl3.classList.add("active");
          }
        }
      });
    }
  };
  const startTime = new Date().getTime();
  const tabSetupInterval = setInterval(() => {
    if (new Date().getTime() - startTime < 5000) {
      let selEl = document.querySelectorAll(".tabLabel");
      if (selEl.length > 0) {
        setupLabels(selEl);
        //console.log("tabs complete");
        clearInterval(tabSetupInterval);
      }
    } else {
      //console.log("tabs did not load");
      clearInterval(tabSetupInterval); // only run up to 5 seconds
    }
  }, 150);

  function count(Num, endNumber, counterID) {
    // alert("num=" + Num);
    setTimeout(function () {
      Num++;
      if (Num < endNumber) {
        document.querySelector("#" + counterID).innerText = Num + "%";
        count(Num, endNumber, counterID);
      }
    }, 27);
  }

  let loaded = false;
  if (document.readyState !== "loading" && loaded === false) {
    if (document.querySelector(".yourScore") !== null) {
      fadeIn(document.querySelector(".yourScore"), 0.02);
      const tomaxposition = document.querySelector(".yourScore").attributes.tomaxposition.value;
      moveTopPercent(document.querySelector(".yourScore"), tomaxposition, 88);
    }

    if (document.querySelector("#countContentGR") !== null) {
      let contentScoreGR = document.querySelector("#countContentGR").attributes.scoregr.value;
      count(0, contentScoreGR, "countContentGR");
      //document.querySelector("#contentChartGR").hover(function () {
      //document.querySelector(".excellentFlag, .passFlag, .failFlag").toggle("fast");
      //});
    } else {
      let contentScore = document.querySelector("#countContent").attributes.score.value;

      let linguisticScore = document.querySelector("#countLinguistic").attributes.score.value;
      count(0, contentScore, "countContent");
      count(0, linguisticScore, "countLinguistic");
    }

    let contentScoreTarget;
    if (document.querySelector("#countContentTarget") !== null) {
      contentScoreTarget = document.querySelector("#countContentTarget").attributes.score.value;
    }

    let linguisticScoreTarget;
    if (document.querySelector("#countLinguisticTarget") !== null) {
      linguisticScoreTarget = document.querySelector("#countLinguisticTarget").attributes.score.value;
    }

    if (document.querySelector("targetlevel") !== null) {
      let initialized = false;
      document.querySelector("#targetLevel").addEventListener("click", function () {
        if (!initialized) {
          count(0, contentScoreTarget, "countContentTarget");
          count(0, linguisticScoreTarget, "countLinguisticTarget");
        }
        initialized = true;
      });
    }
    loaded = true;
  }
}
