import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import axiosRetry from "axios-retry";
import dompurify from "dompurify";
import LoadingSpinner from "../../../components/spinner/LoadingSpinner";
import { openCloseAccordionSection } from "../../../functions/genericFunctions";
import { ErrorContext } from "../../../context/ErrorProvider";
import sharedFunctions from "../../../functions/sharedFunctions";

export default function Details(props) {
  const sanitizer = dompurify.sanitize;
  let levelDescript = "";
  let skillLevel = "";
  let performance = "";

  const correct = <span className="dataLeftSmall_grMain_check">✔ </span>;
  const incorrect = <span className="dataLeftSmall_grMain_check">❌</span>;
  const asterisk = <span className="dataLeftSmall_grMain_asterisk">*</span>;

  const grID = props.grID;
  const grTotal = props.grTotal;

  const [state, setState] = useState({
    grSummary: [],
    grProfile: []
  });
  const [summaryCollState, setSummaryCollState] = useState(null);
  const [individualCatState, setindividualCatState] = useState(null);
  const [, errorDispatch] = useContext(ErrorContext);
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  for (let i = 0; i < grTotal.length; i++) {
    if (grTotal[i]._id === grID) {
      levelDescript = grTotal[i].grammarLevel.description.toLowerCase();
      skillLevel = grTotal[i].grammarLevel._id;
      performance = grTotal[i].performance;
    } else {
      //nothing
    }
  }

  /*load the sharedFunctions from seperate js file*/
  useEffect(() => {
    sharedFunctions();
  }, []);

  useEffect(() => {
    let endpoints = ["api/sessionGR/diagProfileSummary/" + grID, "api/sessionGR/diagProfileDetail/" + grID];
    const grabDataAsync = async () => {
      try {
        let theData = await axios.all(endpoints.map((endpoint) => axios.get(endpoint)));
        let awaitData = await theData;
        setState({
          grSummary: awaitData[0].data,
          grProfile: awaitData[1].data
        });

        return awaitData;
      } catch (error) {
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 600) {
            //global error dispatch call to show error modal if error received during api call
            errorDispatch({
              type: "UPDATE_ERROR_STATE",
              payload: {
                errorStatusCode: error.response.status,
                errorUserMessage: "New Error",
                errorDevData: error.response
              }
            });
          }
        }
      }
    };
    grabDataAsync();
  }, [grID, errorDispatch]);

  useEffect(() => {
    if (grTotal !== null && state.grSummary.length !== 0 && state.grSummary !== [] && state.grSummary !== null) {
      let summaryColl = [];
      let cat = "";
      let score = "";

      for (let j = 0; j < state.grSummary.length; j++) {
        cat = state.grSummary[j]._id.grammarCategory.displayName;
        score = state.grSummary[j].correctPercent;
        summaryColl.push([{ cat: cat, score: score }]);
      }
      setSummaryCollState(summaryColl);

      //let num = 0; //TODO (Review-->num is assigned but never used, can we remove it?)
      //let CatFrequency = []; //TODO (Review-->CatFrequency is assigned but never used, can we remove it?)
      let individualCat = [];
      let allCat = [];

      for (let k = 0; k < state.grProfile.length; k++) {
        for (let n = 0; n < summaryColl.length; n++) {
          if (state.grProfile[k].grammarCategory.displayName == summaryColl[n][0].cat) {
            //num++; //TODO (Review-->num is assigned but never used, can we remove it?)
            allCat.push(state.grProfile[k].grammarCategory.displayName);
          }
        }

        let countCat = {};
        allCat.forEach(function (k) {
          countCat[k] = (countCat[k] || 0) + 1;
        });

        individualCat = Object.keys(countCat);
        //CatFrequency = Object.values(countCat);  //TODO (Review-->CatFrequency is assigned but never used, can we remove it?)
      }
      setindividualCatState(individualCat);
    }
  }, [state.grSummary, state.grProfile, grTotal]);

  const buildGrammarProcessing = () => {
    let grProcessing = [];
    for (let i = 0; i < individualCatState.length; i++) {
      let cat = summaryCollState[i][0].cat;
      let score = Math.ceil(summaryCollState[i][0].score);
      let wording = "";
      let catDesrciption = "";
      let key = "cat_" + i;

      if (summaryCollState[i][0].cat != "Grammar In Listening") {
        wording = "Category";
      }
      if (cat === "Word") {
        catDesrciption =
          "This section of the profile shows your ability to apply knowledge of grammatical features occurring at the word or phrase level.";
      } else if (cat === "Sentence") {
        catDesrciption =
          "This section of the profile shows your ability to apply knowledge of grammatical features occurring at the sentence level.";
      } else if (cat === "Discourse") {
        catDesrciption = "This section of the profile shows your ability to apply knowledge of discourse features.";
      } else if (cat === "Grammar In Listening") {
        catDesrciption =
          "This section of the profile shows your ability to apply knowledge of grammatical features in listening comprehension..";
      }

      grProcessing.push(
        <div key={key}>
          <div className="grammarProcessing">
            <div className="profileCategory">
              <span className="dataLeft_gr" data="100">
                {score}%
              </span>
            </div>
            <h2>
              {cat} {wording}
            </h2>
            <h4 className="descriptor grammarHead accordionTrigger open" onClick={openCloseAccordionSection}>
              {catDesrciption}
            </h4>
            <div className="accordion">{grammarCategory(i)}</div>
          </div>
        </div>
      );
    }
    return grProcessing;
  };

  const grammarCategory = (num) => {
    let grCat = [];
    let statement = "";

    for (let k = 0; k < state.grProfile.length; k++) {
      let firstAttempt = "";
      let secondAttempt = "";
      let checkPass = "";
      let addAsterisk = "";

      if (state.grProfile[k].grammarCategory.displayName === individualCatState[num]) {
        if (state.grProfile[k]._id.try === 0) {
          firstAttempt = state.grProfile[k].questionPassed + "/" + state.grProfile[k].totQuestionCounted;
        }

        if (state.grProfile.length > 1 && k < state.grProfile.length - 1) {
          if (state.grProfile[k]._id.testlet === state.grProfile[k + 1]._id.testlet) {
            secondAttempt = state.grProfile[k + 1].questionPassed + "/" + state.grProfile[k + 1].totQuestionCounted;
          }
        }

        if (state.grProfile[k]._id.try === 0) {
          let key = "grCat_" + k;
          statement = state.grProfile[k].tag.statement;
          if (state.grProfile[k].isTestletPassed) {
            checkPass = correct;
          } else {
            checkPass = incorrect;
          }

          if (
            state.grProfile[k].testletPassingScore == "100" &&
            state.grProfile[k].isTestletPassed === false &&
            firstAttempt == "2/3"
          ) {
            addAsterisk = asterisk;
          }

          grCat.push(
            <div key={key}>
              <div className="profileCategory">
                <p className="">
                  <span className="dataLeftSmall_grMain hovertext" data-hover="First Attempt" data="66">
                    {addAsterisk}
                    {firstAttempt}
                  </span>
                </p>
                <p className="">{checkPass}</p>
                <p className="">
                  <span className="dataLeftSmall_grMod hovertext" data-hover="Second Attempt" data="100">
                    {secondAttempt}
                  </span>
                </p>
                <div
                  className="tag"
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(statement)
                  }}
                />
              </div>
            </div>
          );
        }
      }
    }
    return grCat;
  };
  if (document.querySelector(".container.template") !== null) {
    document.querySelector(".container.template").style.maxWidth = "800px";
  }

  return (
    <div className="pageBreak">
      <div className="tabs">
        <div className="tabLabel active" id="currentLevel">
          LEVEL <span>{skillLevel}</span> GRAMMAR
        </div>
      </div>

      <div className="tabContent currentLevel active">
        <h4 className="center">
          Based on your performance in this ODA Grammar session, you have demonstrated{" "}
          <span className="dp_grammar">{performance}</span> competence in using Level{" "}
          <span className="dp_grammar">{skillLevel} </span>
          grammatical structures, which are common to passages containing
          <span className="dp_grammar"> {levelDescript}</span>.
        </h4>

        <div className="profileGridContainer full">
          {individualCatState !== null ? buildGrammarProcessing() : <LoadingSpinner msg="Loading your results..." />}
        </div>
      </div>
      <div className="profileCategory" style={{ height: "80px" }}>
        <p className="">
          <span className="dataLeftSmall_grMainLegend" />
          <span className="grammarDescMain">First Attempt (Correct/Total)</span>
        </p>
        <p className="">
          <span className="dataLeftSmall_grModLegend" />
          <span className="grammarDescMod">Second Attempt with hints (Correct/Total)</span>
        </p>
        <p className="">
          <span className="dataLeftSmall_grStarLegend">*</span>
          <span className="grammarDescAsterisk">
            All the questions must be answered correctly in order to earn a green check mark.
          </span>
        </p>
      </div>
    </div>
  );
}
