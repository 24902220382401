import React, { useState, useCallback, useContext } from "react";
import { ErrorContext } from "../context/ErrorProvider";
import Footer from "../components/footer/Footer";
import axios from "axios";
import axiosRetry from "axios-retry";
import LoadingSpinner from "../components/spinner/LoadingSpinner";

/**
 * @component ForgotPassword
 * @description allows a user to request a new password
 * @todo add comments
 **/

const ForgotPassword = (props) => {
  const [, errorDispatch] = useContext(ErrorContext);
  const [state, setState] = useState({
    email: "",
    showError: false,
    messageFromServer: "",
    showNullError: false
  });
  const [isSending, setIsSending] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  axios.interceptors.request.use((config) => {
    config.timeout = 30000; // Wait for 30 seconds before timing out
    return config;
  });
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });
  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const sendEmail = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSending(true);
      const { email } = state;
      if (email === "") {
        setState({ ...state, showError: false, messageFromServer: "", showNullError: true });
      } else {
        try {
          const response = await axios
            .post("/api/forgotPassword", {
              email
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status >= 400 && error.response.status < 600 && error.response.status !== 403) {
                  //global error dispatch call to show error modal if error received during api call
                  errorDispatch({
                    type: "UPDATE_ERROR_STATE",
                    payload: {
                      errorStatusCode: error.response.status,
                      errorUserMessage: "New Error",
                      errorDevData: error.response
                    }
                  });
                }
              }
            });
          //console.log(response.data);
          // if (response.data === "Password reset link sent to: " + email) {
          //   setState({ ...state, showError: false, messageFromServer: "recovery email sent", showNullError: false });
          //   setHasSent(true);
          // }
          if (response.status === 204) {
            setState({ ...state, showError: false, messageFromServer: "attempted send", showNullError: false });
            setHasSent(true);
          }
        } catch (error) {
          // console.error(error.response.data);
          // if (error.response.data === "Email not found in database" || error.response.status === 204) {
          // setState({ ...state, showError: true, messageFromServer: "", showNullError: false });
          setState({
            ...state,
            showError: false,
            messageFromServer: "attempted send",
            showNullError: false
          });

          setHasSent(true); //show same message on success or failure

          // }
        }
      }
      setIsSending(false);
    },
    [state]
  );

  let { email, messageFromServer, showNullError, showError } = state;

  return (
    <React.Fragment>
      <div className="signUp">
        <span className="cardTitle">Forgot Password</span>
        {!isSending && !hasSent ? (
          <form noValidate onSubmit={sendEmail}>
            <label htmlFor="email">
              Email
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleChange("email")}
                autoComplete="off"
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </label>
            <br />
            <button className="loginBtn">Submit</button>
          </form>
        ) : isSending && !hasSent ? (
          <div>
            Sending password reset link to {email}...
            <LoadingSpinner msg="" />
          </div>
        ) : (
          <div>
            If your account exists, you will receive an email with your password reset link. <br />
            <br />
            If you do not receive an email link, it is possible that no account exists for this email address. You may
            need to create a new account.
          </div>
        )}

        {showNullError && <div>The email address cannot be null.</div>}
        {showError && (
          <div>
            If your account exists, you will receive an email with your password reset link. <br />
            <br />
            If you do not receive an email link, it is possible that no account exists for this email address. You may
            need to create a new account.
          </div>
        )}
      </div>
      <br />
      <Footer showReportProblem={false} />
    </React.Fragment>
  );
};

export default ForgotPassword;
