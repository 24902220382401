import React, { useState, useEffect, useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import GrammarDetails from "./profiles/grammarProfiles/GrammarDetails";
import GrammarOverview from "./profiles/grammarProfiles/GrammarOverview";
import GrammarMoreInfo from "./profiles/grammarProfiles/GrammarMoreInfo";
import Footer from "../components/footer/Footer";
import diagnosticProfileFunctions from "../functions/diagnosticProfileFunctions";
import GrammarSurveyModal from "../components/survey/GrammarSurveyModal";
import { AssessmentContext } from "../context/AssessmentProvider";

/**
 * @component ProfileGrammar
 * @description displays a users grammar profile
 * @todo clean up code and add comments
 **/

const print = () => {
  //document.querySelector("meta[name=viewport]").setAttribute("content", "width=600");
  // const element = props.targetRef.current;
  const element = document.querySelector(".bodyItems");
  let arrowsToHide = element.querySelectorAll(".centerer :nth-child(1)");
  arrowsToHide.forEach((arrow) => {
    if (arrow.style !== null) {
      arrow.style.display = "none";
    }
  });
  let currentSelectionEls = element.querySelectorAll(".active");
  currentSelectionEls.forEach((el) => {
    el.classList.add("temporaryActive");
  });
  let allTabsAndContent = element.querySelectorAll(".tabContent, .tabLabel");
  allTabsAndContent.forEach((el) => {
    if (el.classList.contains("active")) {
      //skip
    } else {
      el.classList.add("active");
    }
  });
  let theEls2 = element.querySelectorAll(".grInfo p.infoTagline, .grInfo button");
  if (theEls2 !== null) {
    theEls2.forEach((el) => {
      el.style.visibility = "hidden";
    });
  }
  if (element.querySelector(".tabContent") !== null) {
    element.querySelector(".tabContent").style.border = "none"; //was 1px solid #ddd
  }
  window.print();
};

const ProfileGrammar = (props) => {
  const [assessmentState, dispatch] = useContext(AssessmentContext);
  const location = useLocation();
  const grID = location.state.grID;
  const grTotal = location.state.grTotal;
  const pdfSegment = React.useRef();
  const [state, setState] = useState({
    grDashboardDP: location.state.grDashboardDP
  });

  useEffect(() => {
    diagnosticProfileFunctions();

    const body = document.getElementsByTagName("body")[0];
    if (body !== null) {
      body.style.overflow = "overlay";
    }

    document.body.scrollTop = document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
    window.addEventListener("popstate", (e) => {
      window.location.reload();
    });
  }, []);

  useEffect(() => {
    const thisProf = grTotal.filter((grProf) => grProf._id === grID);
    const thisLvl = thisProf[0].grammarLevel._id;
    if (grTotal !== null && grID !== null && assessmentState.assessmentModuleRef !== thisLvl) {
      dispatch({
        type: "UPDATE_MODULE_REF",
        payload: "GR-" + thisLvl
      });
    }
  }, [grTotal, grID, dispatch]);

  //printing using print dialog
  useEffect(() => {
    function handleBeforePrint() {
      // Perform actions before the print dialog opens
      // console.log("Before print");
    }

    function handleAfterPrint() {
      // Perform actions after the print dialog closes
      // console.log("After print");
      const element = document.querySelector(".bodyItems");
      let currentSelectionElsAgain = element.querySelectorAll(".active");
      currentSelectionElsAgain.forEach((el) => {
        if (el.classList.contains("temporaryActive")) {
          el.classList.remove("temporaryActive");
        } else {
          el.classList.remove("active");
        }
      });
      let arrowsToHide = element.querySelectorAll(".centerer :nth-child(1)");
      arrowsToHide.forEach((arrow) => {
        if (arrow.style !== null) {
          arrow.style.display = "inline-block";
        }
      });
      let theEls2 = element.querySelectorAll(".grInfo p.infoTagline, .grInfo button");
      if (theEls2 !== null) {
        theEls2.forEach((el) => {
          el.style.visibility = "visible";
        });
      }
      if (element.querySelector(".tabContent") !== null) {
        element.querySelector(".tabContent").style.border = "1px solid #ddd";
      }
      // document.querySelector("meta[name=viewport]").setAttribute("content", "width=device-width, initial-scale=1");
    }

    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  // useEffect(() => {
  //   console.log("New global state (below).");
  //   console.log(assessmentState);
  // }, [assessmentState.assessmentModuleRef]);

  const handleGrammarSurveyModalState = useCallback(
    (e) => {
      setState({ ...state, grDashboardDP: true });
    },
    [state]
  );

  return (
    <div>
      <div className="bodyItems">
        <div ref={pdfSegment}>
          <GrammarOverview grID={grID} grTotal={grTotal} onPrint={print} />

          <GrammarDetails grID={grID} grTotal={grTotal} />
        </div>
        <GrammarMoreInfo
          targetLevel={state.targetLevel}
          modality="Grammar"
          grID={grID}
          grTotal={grTotal}
          targetRef={pdfSegment}
        />
      </div>
      <Footer showReportProblem={false} />
      {!state.grDashboardDP && <GrammarSurveyModal handleGrammarSurveyModalState={handleGrammarSurveyModalState} />}
    </div>
  );
};

export default ProfileGrammar;
