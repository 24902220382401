import React, { useState } from "react";
import reading from "../../img/icons/reading_icon_material.svg";
import listening from "../../img/icons/listening_icon_material.svg";
import grammar from "../../img/icons/grammar_icon_material.svg";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";

export default function Select(props) {
  const showLoadingSpinner = props.showLoadingSpinner;
  const lang = props.lang;
  const skill = props.skill;
  const level = props.level;
  const inputRequested = [lang, skill, level];

  const tipTrigger = (event) => {
    const item = event.currentTarget;
    if (item !== null) {
      item.nextElementSibling.style.display = "inline";
    }
  };

  const tipClose = (event) => {
    if (document.querySelector(".toolTipText") !== null) {
      document.querySelector(".toolTipText").style.display = "none";
    }
  };

  const tooltipRCLC = (
    <div>
      <span className="tipClose" onClick={tipClose} />
      <p>Be conservative when selecting your starting level.</p>
      <p>
        For example, if you think you might be able to perform at level 2, we recommend that you start at level 1+.{" "}
      </p>
      <p>The system will adapt to your performance and move you up or down accordingly.</p>
    </div>
  );

  const tooltipGR = (
    <div>
      <span className="tipClose" onClick={tipClose} />
      <p>
        Unlike reading or listening online diagnostic assessments (ODA), grammar assessment does not adapt to your
        performance.
      </p>
      <p> Once you finish your chosen module, you're welcome to return for another assessment module anytime.</p>
    </div>
  );

  return (
    <div>
      <div className="overlay">
        <div className="modal">
          <div className="close" />
          <h4>Take an Assessment</h4>
          <hr />
          {showLoadingSpinner && (
            <LoadingSpinner
              size="newAssessment"
              msg={
                "Starting your\r\n\r\n L" +
                level +
                " " +
                lang
                  .replace(
                    /^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i,
                    "Korean (South & North)"
                  )
                  .replace(/^Korean$/i, "Korean (South)")
                  .replace("_", " ") +
                " \r\n\r\n" +
                skill +
                " assessment..."
              }
            />
          )}

          <div id="slider">
            <ul>
              {/* <!-- Select Language--> */}
              <li id="slide0">
                <div className="langSelect dropdownSelect">
                  <label>Language</label>
                  <select className="languages" name="languages" onChange={props.onSelectLang}>
                    <option defaultValue disabled>
                      Select Language
                    </option>
                    {/* <option value="Albanian">Albanian</option> */}
                    <option value="Arabic_Egyptian">Arabic-Egyptian</option>
                    <option value="Arabic_Iraqi">Arabic-Iraqi</option>
                    <option value="Arabic_Levantine">Arabic-Levantine</option>
                    <option value="Arabic_MSA">Arabic-MSA</option>
                    <option value="Balochi">Balochi</option>
                    <option value="Chinese">Chinese</option>
                    {/* <option value="Croatian">Croatian</option> */}
                    <option value="Dari">Dari</option>
                    <option value="English">English</option>
                    <option value="Farsi">Farsi</option>
                    <option value="French">French</option>
                    {/* <option value="German">German</option> */}
                    {/* <option value="Greek">Greek</option> */}
                    <option value="Hebrew">Hebrew</option>
                    {/* <option value="Indonesian">Indonesian</option> */}
                    <option value="Korean">Korean (South)</option>
                    <option value="North_Korean">Korean (South+North)</option>
                    <option value="Pashto">Pashto</option>
                    <option value="Portuguese">Portuguese</option>
                    <option value="Russian">Russian</option>
                    <option value="Serbian">Serbian/Croatian</option>
                    {/* <option value="Serbian">Serbian</option> */}
                    <option value="Somali">Somali</option>
                    <option value="Spanish">Spanish</option>
                    <option value="Tagalog">Tagalog</option>
                    <option value="Turkish">Turkish</option>
                    {/* <option value="Thai">Thai</option> */}
                    <option value="Urdu">Urdu</option>
                  </select>
                  <br />
                  <button id="toSkillBtn" className="loginBtn">
                    Next
                  </button>
                </div>
              </li>
              {/* <!-- Select Skill--> */}
              <li id="slide1">
                <span className="selectedLang" />
                <p className="skill readingSkill">
                  <img src={reading} alt="Reading Skill" />
                  <button className="skillBtn" value="reading" onClick={props.onSelectSkill}>
                    Reading
                  </button>
                </p>
                <p className="skill noReading">
                  <img src={reading} alt="No Reading Skill" />
                  <span>Reading Coming soon!</span>
                </p>
                <p className="skill listeningSkill">
                  <img src={listening} alt="Listening Skill" />
                  <button className="skillBtn" value="listening" onClick={props.onSelectSkill}>
                    Listening
                  </button>
                </p>
                <p className="skill noListening">
                  <img src={listening} alt="No Listening Skill" />
                  <span>Listening Coming soon!</span>
                </p>
                <p className="skill grammarSkill">
                  <img src={grammar} alt="Grammar Skill" />
                  <button className="skillBtn" value="grammar" onClick={props.onSelectSkill}>
                    Grammar
                  </button>
                </p>
                <p className="skill noGrammar">
                  <img src={grammar} alt="No Grammar Skill" />
                  <span>Grammar Coming soon!</span>
                </p>
                <span className="back">← back to Language Select</span>
              </li>
              {/* <!-- select ILR level--> */}
              <li id="slide2">
                <span className="selectedLang" />
                &nbsp;
                <span className="selectedSkill" />
                <p className="levelLabel">
                  Select your starting level
                  <span className="toolTip">
                    <span className="tipTrigger" onClick={tipTrigger}>
                      ?
                    </span>
                    <span className="toolTipText">{skill === "grammar" ? tooltipGR : tooltipRCLC}</span>
                  </span>
                </p>
                <span className="proficiencyLevel">
                  <p
                    className="levelSelect"
                    id="lvl1"
                    title="Basic elementary proficiency"
                    onClick={props.onSelectLevel}
                  >
                    1
                  </p>
                  <p
                    className="levelSelect selected"
                    id="lvl1+"
                    title="Elementary proficiency"
                    onClick={props.onSelectLevel}
                  >
                    1+
                  </p>
                  <p
                    className="levelSelect"
                    id="lvl2"
                    title="Limited working proficiency"
                    onClick={props.onSelectLevel}
                  >
                    2
                  </p>
                  <p className="levelSelect" id="lvl2+" title="Working proficiency" onClick={props.onSelectLevel}>
                    2+
                  </p>
                  <p
                    className="levelSelect"
                    id="lvl3"
                    title="Professional working proficiency"
                    onClick={props.onSelectLevel}
                  >
                    3
                  </p>
                </span>
                <span className="grammarLevel">
                  <p
                    className="levelSelect grammarLevelSelect selected"
                    id="lvl1"
                    title="Basic elementary proficiency"
                    onClick={props.onSelectLevel}
                  >
                    <span>1</span>
                  </p>
                  <p
                    className="levelSelect grammarLevelSelect"
                    id="lvl1+/2"
                    title="Elementary proficiency"
                    onClick={props.onSelectLevel}
                  >
                    <span>1+/</span>
                    <span className="">2</span>
                  </p>
                  <p
                    className="levelSelect grammarLevelSelect"
                    id="lvl2+/3"
                    title="Working proficiency"
                    onClick={props.onSelectLevel}
                  >
                    <span>2+/</span>
                    <span className="">3</span>
                  </p>
                </span>
                <button className="begin" value={inputRequested} onClick={props.onAccessToTestletDB}>
                  Begin Assessment
                </button>{" "}
                <span className="back">← back to Skill Select</span>
              </li>
              &nbsp;
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
