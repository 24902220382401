/**
 *  Dashboard.js specific non-state funtions should live here
 *
 */
import { show, hide, fadeIn, fadeOut } from "./genericFunctions";

export function moveLeft(slideWidth) {
  if (document.querySelector("#slider ul") !== null) {
    move(document.querySelector("#slider ul"), slideWidth, "left", document.querySelector("#slider ul").style.left);
  }
}

export function moveRight(slideWidth) {
  if (document.querySelector("#slider ul") !== null) {
    move(document.querySelector("#slider ul"), slideWidth, "right", document.querySelector("#slider ul").style.left);
  }
}

export function setLevel(lvl) {
  let allLvlSelEl = document.querySelectorAll(".levelSelect");
  allLvlSelEl.forEach((el) => {
    el.classList.remove("selected");
  });
  if (lvl != "") {
    document.querySelector("#" + lvl).classList.add("selected");
  } else {
    document.querySelector("#lvl1+").classList.add("selected");
  }
}

export function resetSlider(a, b, c) {
  document.querySelector("#slider ul").appendChild(document.querySelector("#slide" + a));
  document.querySelector("#slider ul").appendChild(document.querySelector("#slide" + b));
  document.querySelector("#slider ul").appendChild(document.querySelector("#slide" + c));
}

// ** replaces jQuery.animate() left/right sliding for our purposes **
export function move(elem, distance, direction, currentPosition) {
  var left = 0;
  var updatedPosition = 0;
  var endingPosition = 0;
  if (currentPosition !== "") {
    updatedPosition = parseInt(currentPosition);
  }
  if (direction == "right") {
    distance = distance * -1;
  }
  endingPosition = updatedPosition + distance;
  function frame() {
    if (direction == "left") {
      left++;
    } else {
      left--;
    }
    updatedPosition = updatedPosition + left;
    if (direction == "left" && updatedPosition >= endingPosition) {
      updatedPosition = Math.floor(updatedPosition / 400) * 400;
    } else if (direction == "right" && updatedPosition <= endingPosition) {
      updatedPosition = Math.floor(updatedPosition / -400) * -400;
    }
    if (elem !== null) {
      elem.style.left = updatedPosition + "px"; // show frame
    }
    if (
      (updatedPosition >= endingPosition && direction === "left") ||
      (updatedPosition <= endingPosition && direction === "right")
    ) {
      // check finish condition
      clearInterval(id);
    }
  }
  var id = setInterval(frame, 5); // draw every 5ms
}

// **  Handles language names and abbreviations **/
export const convertLegacyLangId = (lang) => {
  if (lang === "arb") {
    return "ara";
  } else if (lang === "chn") {
    return "cmn";
  } else if (lang === "fre") {
    return "fra";
  } else if (lang === "tag") {
    return "tgl";
  } else if (lang === "egy") {
    return "arz";
  } else if (lang === "spn") {
    return "spa";
  } else if (lang === "far") {
    return "pes";
  } else if (lang === "lev") {
    return "qav";
  } else if (lang === "iar") {
    return "qai";
  } else {
    return lang;
  }
};

export const setLang = (lang) => {
  const langColl = {
    // Albanian: "sqi",
    Arabic_Egyptian: "arz",
    Arabic_Iraqi: "qai",
    Arabic_Levantine: "qav",
    Arabic_MSA: "ara",
    Balochi: "bal",
    Chinese: "cmn",
    Croatian: "hrv",
    Dari: "prs",
    English: "eng",
    Farsi: "pes",
    French: "fra",
    // German: "deu",
    // Greek: "ell",
    // Gulf: "qag",
    Hebrew: "heb",
    // Hindi:"hin",
    // Indonesian: "ind",
    // Japanese:"jpn",
    Korean: "kor",
    North_Korean: "nkr",
    Pashto: "pus",
    Portuguese: "por",
    Russian: "rus",
    Serbian: "srp",
    Somali: "som",
    Spanish: "spa",
    // Sudanese: "apd",
    Tagalog: "tgl",
    // Thai: "tha",
    Turkish: "tur",
    // Turkmen: "tuk",
    Urdu: "urd"
    // Yemeni: "qay",
  };
  for (var n in langColl) {
    if (lang === n) {
      return langColl[n];
    } else {
      //alert('Select another language')
    }
  }
};

// ** Handles the modal for "Take New Assessment" area from the Dashboard **
export const handleNewAssessment = (e) => {
  let languageValue = "";
  let hasGrammar = ["Arabic_MSA", "Chinese", "English", "Farsi", "Korean", "Russian", "Spanish", "Tagalog"];
  let hasReading = ["Arabic_Egyptian", "Arabic_Iraqi", "Arabic_Levantine", "Balochi"];
  let hasListening = [];
  //let hasListening = ["Turkish"];

  if (!e.target.dataset.lang) {
    resetSlider(0, 1, 2);
    let languageValue = document.querySelector('select[name="languages"]').value;
    if (languageValue != null && languageValue != "Select Language") {
      fadeIn(document.querySelector("#toSkillBtn")); //was fast
      let selElLang = document.querySelectorAll(".selectedLang");
      for (let i = 0; i < selElLang.length; i++) {
        selElLang[i].textContent = languageValue
          .replace(/^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i, "Korean (South & North)")
          .replace(/^Korean$/i, "Korean (South)")
          .replace("_", " ");
      }
    }
  } else {
    resetSlider(2, 0, 1);
    //console.log("Language not set so slider reset to 2 0 1 : is everything working properly?");
    let languageValue = e.target.dataset.lang;
    let selElLang = document.querySelectorAll(".selectedLang");
    for (let i = 0; i < selElLang.length; i++) {
      //Korean Wording Fix
      selElLang[i].textContent = languageValue
        .replace(/^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i, "Korean (South & North)")
        .replace(/^Korean$/i, "Korean (South)")
        .replace("_", " ");
    }
    //console.log("Setting skill to :" + e.target.dataset.skill);
    let selElSkill = document.querySelectorAll(".selectedSkill");
    for (let i = 0; i < selElSkill.length; i++) {
      selElSkill[i].textContent = e.target.dataset.skill;
    }
    setLevel(e.target.dataset.level);
  }

  fadeIn(document.querySelector(".overlay")); //was fast

  document.body.style.overflow = "hidden";

  // sliders for take assessment section
  var slideCount = document.querySelectorAll("#slider ul li").length;
  var slideWidth =
    document.querySelector("#slider ul li") !== null ? document.querySelector("#slider ul li").clientWidth : 0;
  var slideHeight =
    document.querySelector("#slider ul li") !== null ? document.querySelector("#slider ul li").clientHeight : 0;
  var sliderUlWidth = slideCount * slideWidth;

  if (document.querySelector("#slider") !== null) {
    document.querySelector("#slider").style.width = slideWidth + "px";
    document.querySelector("#slider").style.height = slideHeight + "px";
  }
  if (document.querySelector("#slider ul") !== null) {
    document.querySelector("#slider ul").style.width = sliderUlWidth + "px";
  }

  document.querySelector(".languages").addEventListener("change", function () {
    fadeIn(document.querySelector("#toSkillBtn")); //was fast
  });

  let selEl = document.querySelectorAll("#toSkillBtn");
  for (let i = 0; i < selEl.length; i++) {
    selEl[i].addEventListener("click", function () {
      moveRight(slideWidth);
    });
  }

  let selEl2 = document.querySelectorAll(".skillBtn");
  for (let i = 0; i < selEl2.length; i++) {
    selEl2[i].addEventListener("click", function (f) {
      let selElSkill = document.querySelectorAll(".selectedSkill");
      //console.log("Setting skill to :" + f.target.innerText);
      for (let i = 0; i < selElSkill.length; i++) {
        selElSkill[i].textContent = f.target.innerText;
      }

      //persist proficiency level if user swapped from grammar to R/L
      if (f.target.innerText === "Reading" || f.target.innerText === "Listening") {
        let curVal = document.querySelector(".begin").attributes.value.value.split(",");
        let curProfVal = curVal[2];
        let allLvlSelEl = document.querySelectorAll(".levelSelect");
        allLvlSelEl.forEach((el) => {
          el.classList.remove("selected");
        });
        if (curProfVal === "2+/3" || curProfVal === "3") {
          document.getElementById("lvl3").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",3");
        } else if (curProfVal === "1+/2" || curProfVal === "2") {
          document.getElementById("lvl2").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",2");
        } else if (curProfVal === "1") {
          document.querySelector(".proficiencyLevel > #lvl1").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1");
        } else if (curProfVal === "1+") {
          document.getElementById("lvl1+").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1+");
        } else if (curProfVal === "2+") {
          document.getElementById("lvl2+").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",2+");
        }

        // handles issue with English Reading/Listening lvl1 not being available
        if (
          (f.target.innerText === "Reading" || f.target.innerText === "Listening") &&
          document.querySelector('select[name="languages"]').value === "English"
        ) {
          if (document.querySelector(".proficiencyLevel > #lvl1") !== null) {
            document.querySelector("span.proficiencyLevel > p#lvl1").classList.remove("selected");
            document.querySelector(".proficiencyLevel > #lvl1").style.cursor = "none";
            document.querySelector(".proficiencyLevel > #lvl1").classList.add("noContents");
            let curVal = document.querySelector(".begin").attributes.value.value.split(",");
            let curProfVal = curVal[2];
            if (curProfVal === "1" || curProfVal === "1+/2") {
              document.getElementById("lvl1+").classList.add("selected");
              document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1+/2");
            }
          }
        }
      }

      //persist proficiency level if user swapped from R/L to grammar
      if (f.target.innerText === "Grammar") {
        let curVal = document.querySelector(".begin").attributes.value.value.split(",");
        let curProfVal = curVal[2];
        let allLvlSelEl = document.querySelectorAll(".levelSelect");
        allLvlSelEl.forEach((el) => {
          el.classList.remove("selected");
        });
        if (curProfVal === "3" || curProfVal === "2+/3") {
          document.getElementById("lvl2+/3").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",2+/3");
        } else if (curProfVal === "2+" || curProfVal === "2+/3") {
          document.getElementById("lvl2+/3").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",2+/3");
        } else if (curProfVal === "2" || curProfVal === "1+/2") {
          document.getElementById("lvl1+/2").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1+/2");
        } else if (curProfVal === "1+" || curProfVal === "1+/2") {
          document.getElementById("lvl1+/2").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1+/2");
        } else if (curProfVal === "1") {
          document.querySelector(".grammarLevel > #lvl1").classList.add("selected");
          document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1");
        }
      }

      // handles issue with English Grammar lvl1 not being available
      if (f.target.innerText === "Grammar" && document.querySelector('select[name="languages"]').value === "English") {
        if (document.querySelector(".grammarLevel > #lvl1") !== null) {
          document.querySelector("span.grammarLevel > p#lvl1").classList.remove("selected");
          document.querySelector(".grammarLevel > #lvl1").style.cursor = "none";
          document.querySelector(".grammarLevel > #lvl1").classList.add("noContents");
          let curVal = document.querySelector(".begin").attributes.value.value.split(",");
          let curProfVal = curVal[2];
          if (curProfVal === "1" || curProfVal === "1+/2") {
            document.getElementById("lvl1+/2").classList.add("selected");
            document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1+/2");
          }
        }
      }

      // handles issue with Russian Grammar lvl2+/3 not being available
      if (f.target.innerText === "Grammar" && document.querySelector('select[name="languages"]').value === "Russian") {
        if (document.getElementById("lvl2+/3") !== null) {
          document.getElementById("lvl2+/3").classList.remove("selected");
          document.getElementById("lvl2+/3").style.cursor = "none";
          document.getElementById("lvl2+/3").classList.add("noContents");
          let curVal = document.querySelector(".begin").attributes.value.value.split(",");
          let curProfVal = curVal[2];
          if (curProfVal === "2+/3" || curProfVal === "1+/2") {
            document.getElementById("lvl1+/2").classList.add("selected");
            document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1+/2");
          }
        }
      }
      moveRight(slideWidth);
    });
  }

  document.querySelector("#toSkillBtn").addEventListener("click", function () {
    languageValue = document.querySelector('select[name="languages"]').value;

    let selElLang = document.querySelectorAll(".selectedLang");
    for (let i = 0; i < selElLang.length; i++) {
      selElLang[i].textContent = languageValue
        .replace(/^(North_Korean|North Korean|Korean\/North Korean|Korean\/North_Korean)$/i, "Korean (South & North)")
        .replace(/^Korean$/i, "Korean (South)")
        .replace("_", " ");
    }
    handleReading();
    handleListening();
    handleGrammar();
  });

  let selEl3 = document.querySelectorAll("#slider .back");
  for (let i = 0; i < selEl3.length; i++) {
    selEl3[i].addEventListener("click", function () {
      if (
        document.querySelector(".selectedSkill").textContent === "Grammar" &&
        document.querySelector('select[name="languages"]').value === "English" &&
        document.querySelector("span.grammarLevel > #lvl1") !== null
      ) {
        document.querySelector("span.grammarLevel > #lvl1").classList.remove("noContents");
        document.querySelector("span.grammarLevel > #lvl1").style.cursor = "pointer";
      }
      if (
        document.querySelector(".selectedSkill").textContent === "Grammar" &&
        document.querySelector('select[name="languages"]').value === "Russian" &&
        document.getElementById("lvl2+/3") !== null
      ) {
        document.getElementById("lvl2+/3").classList.remove("noContents");
        document.getElementById("lvl2+/3").style.cursor = "pointer";
      }

      handleReading();
      handleListening();
      handleGrammar();
      handleLangSelect();
      moveLeft(slideWidth);
    });
  }

  function handleReading() {
    if (hasReading.indexOf(languageValue) == -1) {
      show(document.querySelector(".readingSkill"));
      hide(document.querySelector(".noReading"));
    } else {
      hide(document.querySelector(".readingSkill"));
      show(document.querySelector(".noReading"));
    }
  }

  function handleListening() {
    if (hasListening.indexOf(languageValue) == -1) {
      show(document.querySelector(".listeningSkill"));
      hide(document.querySelector(".noListening"));
    } else {
      hide(document.querySelector(".listeningSkill"));
      show(document.querySelector(".noListening"));
    }
  }

  function handleGrammar() {
    if (hasGrammar.indexOf(languageValue) == -1) {
      hide(document.querySelector(".grammarSkill"));
      show(document.querySelector(".noGrammar"));
    } else {
      show(document.querySelector(".grammarSkill"));
      hide(document.querySelector(".noGrammar"));
    }
  }

  function handleLangSelect() {
    document.querySelector(".languages").val = languageValue;

    //document.querySelector(".select2-selection__rendered").textContent = languageValue;
  }

  document.querySelector(".close").addEventListener("click", function () {
    if (
      document.querySelector(".selectedSkill").textContent === "Grammar" &&
      document.querySelector('select[name="languages"]').value === "English" &&
      document.querySelector("span.grammarLevel > #lvl1") !== null
    ) {
      document.querySelector("span.grammarLevel > #lvl1").classList.remove("noContents");
      document.querySelector("span.grammarLevel > #lvl1").style.cursor = "pointer";
    }
    if (
      document.querySelector(".selectedSkill").textContent === "Grammar" &&
      document.querySelector('select[name="languages"]').value === "Russian" &&
      document.getElementById("lvl2+/3") !== null
    ) {
      document.getElementById("lvl2+/3").classList.remove("noContents");
      document.getElementById("lvl2+/3").style.cursor = "pointer";
    }

    fadeOut(document.querySelector(".overlay")); // was fast
    document.body.style.overflow = "auto";
  });

  let selEl4 = document.querySelectorAll(".levelSelect");
  let curVal = document.querySelector(".begin").attributes.value.value.split(",");
  for (let i = 0; i < selEl4.length; i++) {
    selEl4[i].addEventListener("click", function (e) {
      if (
        e.target.attributes.id.value === "lvl1" &&
        document.querySelector(".selectedSkill").textContent === "Grammar" &&
        document.querySelector('select[name="languages"]').value === "English"
      ) {
        selEl4[i].classList.remove("selected");
        document.getElementById("lvl1+/2").classList.add("selected");
        document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1+/2");
      } else if (
        e.target.attributes.id.value === "lvl2+/3" &&
        document.querySelector(".selectedSkill").textContent === "Grammar" &&
        document.querySelector('select[name="languages"]').value === "Russian"
      ) {
        selEl4[i].classList.remove("selected");
        document.getElementById("lvl1+/2").classList.add("selected");
        document.querySelector(".begin").setAttribute("value", curVal[0] + "," + curVal[1] + ",1+/2");
      } else {
        let selEl5 = document.querySelectorAll(".levelSelect");
        for (let j = 0; j < selEl5.length; j++) {
          selEl5[j].classList.remove("selected");
        }
        e.target.classList.add("selected");
      }
    });
  }
};

export default function miscFunctions() {
  // ** For hiding and showing the different layouts for User Dashboard page **
  let querySelEl = document.querySelector(".toggleAssessments");
  if (querySelEl !== null) {
    querySelEl.click(function (e) {
      querySelEl.classList.remove("active");
      querySelEl.classList.add("active");

      if (e.target.attributes.id.value === "noneBtn") {
        //hide some and many
        let hideSelEl = document.querySelectorAll(".someAssessments, .manyAssessments");
        hideSelEl.forEach((el) => {
          hide(el);
        });
        let showSelEl = document.querySelectorAll(".noAssessments h4");
        showSelEl.forEach((el) => {
          fadeIn(el);
        });
      } else if (e.target.attributes.id.value === "someBtn") {
        //hide many, show some
        let hideSelEl = document.querySelectorAll(".noAssessments h4, .someAssessments, .manyAssessments");
        hideSelEl.forEach((el) => {
          hide(el);
        });
        let showSelEl = document.querySelectorAll(".someAssessments");
        showSelEl.forEach((el) => {
          fadeIn(el);
        });
      } else {
        // show all
        let hideSelEl = document.querySelectorAll(".noAssessments h4, .someAssessments, .manyAssessments");
        hideSelEl.forEach((el) => {
          hide(el);
        });
        let showSelEl = document.querySelectorAll(".someAssessments, .manyAssessments");
        showSelEl.forEach((el) => {
          fadeIn(el);
        });
      }
    });
  }

  //**reset the language selection */
  document.querySelector(".languages").selectedIndex = 0;
}
