import React, { useState, useEffect, useContext, useCallback } from "react";
import Test from "./test/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";
import { useScoreNLP } from "../../../../hooks/useScoreNLP";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";

const GMSA = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    instruction: testData.instruction,
    actID: testData._id,
    modality: testData.modality,
    template: testData.template,
    skill: testData.grammarLevel,
    dir: testData.language.isRightToLeft,
    assessment: testData.testitems,
    userAnswerMainPre: [],
    userAnswerMain: [],
    userAnswerModPre: [],
    userAnswerMod: [],
    userAnswerAll: [],
    hint: testData.hint,
    score: "",
    correctAnswer: testData.testitems,
    clickCounter: 0
  });
  const [assessmentState] = useContext(AssessmentContext);
  const [runScoreNLP, setRunScoreNLP] = useState(false);
  const [eventNLP, setEventNLP] = useState(false);
  const [storeNLPScores, setStoreNLPScores] = useState([]);
  const [finalChecking, setFinalChecking] = useState(null);
  const [theBtnName, setTheBtnName] = useState(null);

  state.userAnswerMain.length = state.correctAnswer.length;
  state.userAnswerMod.length = state.correctAnswer.length;
  state.userAnswerAll.length = state.correctAnswer.length * 2;

  //useEffect(()=> {console.log(state);},[state]);

  useEffect(() => {
    draggableFunctions();
    //fontSizeFunctions();

    if (props.navLen === 20 && document.querySelector(".progressGrid") !== null) {
      document.querySelector(".progressGrid").style.gridTemplateColumns = "repeat(20, 1fr)";
    }

    const footerLogo = document.querySelector(".footerLogo");
    if (footerLogo !== null) {
      footerLogo.style.top = "13px";
      footerLogo.style.height = "67px";
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed hold SHIFT and left clicking this button to unlock it.  Next, click the button normally to proceed without answering the question.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const updateUserAnswerSA = useCallback(
    (id) => {
      const itemId = id.substr(-1);
      let countAnswer = 0;
      let checkAnswer = false;
      let getAnswer = [];

      let userAns;
      if (state.clickCounter === 0) {
        userAns = state.userAnswerMainPre;
      } else {
        userAns = state.userAnswerModPre;
      }

      let matching;
      let itemScore = 0;
      const passingScore = state.correctAnswer[itemId].answerFields[0].passingScore;
      if (userAns[itemId] !== undefined) {
        if (passingScore == "100") {
          const answerKeywordLen = state.correctAnswer[itemId].answerFields[0].answerKeywords.length;
          for (let j = 0; j < answerKeywordLen; j++) {
            const patternLen = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].matchingPatterns.length;
            for (let k = 0; k < patternLen; k++) {
              const crrtAns = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].matchingPatterns[k] + ".*";

              matching = userAns[itemId].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                countAnswer = countAnswer + 1;
                getAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }
          if (countAnswer == answerKeywordLen) {
            checkAnswer = true;
          }

          return { checkAnswer, getAnswer };
        } else {
          const answerKeywordLen = state.correctAnswer[itemId].answerFields[0].answerKeywords.length;

          for (let j = 0; j < answerKeywordLen; j++) {
            let individualScore = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].individualScore;
            const patternLen = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].matchingPatterns.length;
            for (let k = 0; k < patternLen; k++) {
              const crrtAns = state.correctAnswer[itemId].answerFields[0].answerKeywords[j].matchingPatterns[k] + ".*";

              matching = userAns[itemId].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
              if (matching !== null) {
                itemScore = itemScore + individualScore;
                getAnswer.push(crrtAns.toLowerCase());
                break;
              }
            }
          }
          if (itemScore >= passingScore) {
            checkAnswer = true;
          } else {
            //do nothing
          }
          return { checkAnswer, getAnswer };
        }
      }
    },
    [state]
  );

  const handleChange = useCallback(
    (event) => {
      const answerFieldsID = event.currentTarget.name;
      const testitem = event.target.getAttribute("data-testitem");
      const index = event.currentTarget.id.substr(-3, 1);
      const id = event.currentTarget.id;
      const value = event.currentTarget.value;
      let new_state = Object.assign({}, state);
      let a = new_state.userAnswerMainPre;
      let b = new_state.userAnswerMod;
      let z = new_state.userAnswerMain;

      a[index] = {
        uiControlID: id,
        userAnswer: value
      };

      const answerCheck = updateUserAnswerSA(answerFieldsID).checkAnswer;
      const answerGet = updateUserAnswerSA(answerFieldsID).getAnswer;

      z[index] = {
        uiControlID: id,
        testlet: state.actID,
        testitem: testitem,
        answerField: answerFieldsID,
        try: 0,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };

      b[index] = {
        uiControlID: id.substring(0, id.length - 1) + 1,
        testitem: testitem,
        answerField: answerFieldsID,
        testlet: state.actID,
        try: 1,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };
      setState({
        ...state,
        userAnswerMainPre: a,
        userAnswerMain: z,
        userAnswerMod: b
      });

      let TransToMod = [];
      if (id != null) {
        let selID = id.substring(0, id.length - 1);
        TransToMod.push(selID + "1");
      }
      document.querySelector("#" + TransToMod).value = value;

      const len = state.userAnswerMain.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswerMain[i] != undefined) {
          num++;
          if (state.userAnswerMain[i].userAnswer.length == 0) {
            num--;
          }
        }
      }
      if (num == len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
      }
    },
    [state, updateUserAnswerSA]
  );

  const handleChangeMod = useCallback(
    (event) => {
      const answerFieldsID = event.currentTarget.name;
      const testitem = event.target.getAttribute("data-testitem");
      const index = event.currentTarget.id.substr(-3, 1);
      const id = event.currentTarget.id;
      const value = event.currentTarget.value;
      let new_state = Object.assign({}, state);
      let a = new_state.userAnswerModPre;
      let z = new_state.userAnswerMod;

      a[index] = {
        uiControlID: id,
        userAnswer: value
      };

      const answerCheck = updateUserAnswerSA(answerFieldsID).checkAnswer;
      const answerGet = updateUserAnswerSA(answerFieldsID).getAnswer;
      z[index] = {
        uiControlID: id,
        testlet: state.actID,
        testitem: testitem,
        answerField: answerFieldsID,
        try: 1,
        keywordMatches: answerGet,
        userAnswer: value,
        isPassed: answerCheck,
        isModifiedAudioPlayed: false,
        isTranscriptShown: false
      };
      setState({ ...state, userAnswerModPre: a, userAnswerMod: z });

      const len = state.userAnswerMain.length;
      let num = 0;
      for (let i = 0; i < len; i++) {
        if (state.userAnswerMod[i] != undefined) {
          num++;
          if (state.userAnswerMod[i].userAnswer.length == 0) {
            num--;
          }
        }
      }
      if (num == len) {
        document.querySelector(".nextBtn").classList.remove("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.remove("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Click to submit your answers.";
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
      }
    },
    [state, updateUserAnswerSA]
  );

  const combinedAnswers = (btnName) => {
    const mainAns = state.userAnswerMain;
    const modAns = state.userAnswerMod;
    let combinedAns = null;
    let finalAns = null;

    if (state.clickCounter === 0 && finalChecking == true) {
      combinedAns = mainAns;
    } else if (state.clickCounter >= 1 && finalChecking === true) {
      combinedAns = mainAns.concat(modAns);
    }

    if (combinedAns !== null && finalChecking !== null) {
      finalAns = '{"questionItemAns":' + JSON.stringify(combinedAns) + "}";
      if (!(state.clickCounter === 0 && finalChecking == null)) props.parentCallback(finalAns, btnName);
    }
  };

  const handleEvalSA = useCallback(
    (event) => {
      const btnName = event.currentTarget.name;
      setTheBtnName(btnName);

      let userAns = "";
      if (state.clickCounter === 0) {
        userAns = state.userAnswerMain;
      } else {
        userAns = state.userAnswerMod;
      }

      let crrtAnsCounter = 0;
      let scoreCal = "";

      let matching;
      for (let i = 0; i < state.correctAnswer.length; i++) {
        const passingScore = state.correctAnswer[i].answerFields[0].passingScore;

        let checkAnswer = 0;
        let itemScore = 0;
        if (userAns[i] !== undefined) {
          if (passingScore == "100") {
            const answerKeywordLen = state.correctAnswer[i].answerFields[0].answerKeywords.length;
            for (let j = 0; j < answerKeywordLen; j++) {
              const patternLen = state.correctAnswer[i].answerFields[0].answerKeywords[j].matchingPatterns.length;
              for (let k = 0; k < patternLen; k++) {
                const crrtAns = state.correctAnswer[i].answerFields[0].answerKeywords[j].matchingPatterns[k] + ".*";

                matching = userAns[i].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
                if (matching !== null) {
                  checkAnswer = checkAnswer + 1;
                  break;
                }
              }
            }
            if (checkAnswer == answerKeywordLen) {
              crrtAnsCounter = crrtAnsCounter + 1;
            }
          } else {
            const answerKeywordLen = state.correctAnswer[i].answerFields[0].answerKeywords.length;

            for (let j = 0; j < answerKeywordLen; j++) {
              let individualScore = state.correctAnswer[i].answerFields[0].answerKeywords[j].individualScore;
              const patternLen = state.correctAnswer[i].answerFields[0].answerKeywords[j].matchingPatterns.length;
              for (let k = 0; k < patternLen; k++) {
                const crrtAns = state.correctAnswer[i].answerFields[0].answerKeywords[j].matchingPatterns[k] + ".*";

                matching = userAns[i].userAnswer.toLowerCase().match(crrtAns.toLowerCase());
                if (matching !== null) {
                  itemScore = itemScore + individualScore;
                  break;
                }
              }
            }
            if (itemScore >= passingScore) {
              crrtAnsCounter = crrtAnsCounter + 1;
              checkAnswer = checkAnswer + 1;
            }
          }
        }
      }

      if (crrtAnsCounter == state.correctAnswer.length && state.clickCounter === 0) {
        // console.log("correct");
        setFinalChecking(true);
        if (props.navCrrtItem === props.navLen) {
          props.submitButton();
        } else {
          props.nextButton();
        }
      } else {
        document.querySelector(".nextBtn").classList.add("disabled");
        const titleChanger = document.querySelector(".nextSubmitParentDiv");
        titleChanger.classList.add("nextSubmitParentDivUnanswered");
        if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
          titleChanger.title =
            "Please attempt to re-answer at least one incorrect question before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please attempt to re-answer at least one incorrect question before proceeding.";
        }
        if (state.clickCounter === 0) {
          // console.log("incorrect");
          scoreCal =
            "<p>You scored " +
            crrtAnsCounter +
            "/" +
            state.correctAnswer.length +
            " on this grammar point. You may wish to try again.</p>";
          setState({
            ...state,
            score: scoreCal,
            clickCounter: state.clickCounter + 1
          });
          if (document.querySelector(".feedbackModal") !== null) {
            document.querySelector(".feedbackModal").style.display = "block";
          }
        } else {
          setFinalChecking(true);

          // console.log("no judging for scores");
          if (props.navCrrtItem === props.navLen) {
            props.submitButton();
          } else {
            props.nextButton();
          }
          setState({ ...state, clickCounter: state.clickCounter + 1 });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [state, combinedAnswers]
  );

  // ** Issue #163 - disable NLP calls until post-launch period
  // //** customHook & effects to score from NLP API */
  // const [nlpScoreData, nlpScoreRunning, nlpScoreError] = useScoreNLP(
  //   eventNLP,
  //   "GMSA",
  //   assessmentState.assessmentSessionId,
  //   state.correctAnswer,
  //   runScoreNLP
  // );
  // //only execute once
  // useEffect(() => {
  //   if (runScoreNLP) {
  //     setRunScoreNLP(false); //resets to false after one run
  //   }
  // }, [runScoreNLP]);
  // //initialization call from react component
  // const checkNLP = useCallback((event) => {
  //   setEventNLP(event);
  //   setRunScoreNLP(true);
  // }, []);
  // //store the async results in a static object we can reference at the end of the testlet
  // useEffect(() => {
  //   if (nlpScoreData !== null) {
  //     let tempArr = storeNLPScores;
  //     if (nlpScoreData.testletId !== null && nlpScoreData.nlpScore !== null) {
  //       tempArr[nlpScoreData.testletId] = nlpScoreData.nlpScore;
  //       setStoreNLPScores(tempArr);
  //     }
  //     //console.log(tempArr);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [nlpScoreData]);

  useEffect(() => {
    if (finalChecking === true) {
      combinedAnswers(theBtnName);
    }
  }, [combinedAnswers, theBtnName, finalChecking]);

  const renderTest = () => {
    return (
      <>
        <Test
          assessment={state.assessment}
          actID={state.actID}
          dir={state.dir}
          userAnswerMain={state.userAnswerMain}
          userAnswerMod={state.userAnswerMod}
          instruction={state.instruction}
          correctAnswer={state.correctAnswer}
          hint={state.hint}
          score={state.score}
          clickCounter={state.clickCounter}
          // ** Issue #163 - disable NLP calls until post-launch period
          //onLoseFocusCheckNLP={checkNLP}
          onAnswerSelected={handleChange}
          onAnswerSelectedMod={handleChangeMod}
          onEvaluation={handleEvalSA}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
        />
        <Footer actID={state.actID} mod={state.modality} template={state.template} skill={state.skill} />
      </>
    );
  };

  return <div>{renderTest()}</div>;
};

export default GMSA;
