import React, { useState, useCallback } from "react";
import AnswerOption from "../components/AnswerOption";
// import Audio from "../components/Audio";
import Audio from "../components/NewAudio";
import Next from "../../../../../components/testlet/Next_LC";

export default function Test(props) {
  const [toggleState, setToggleState] = useState(false);
  const toggleTheState = useCallback(
    (input) => {
      if (toggleState === false && input !== "not") {
        setToggleState(true);
      } else {
        setToggleState(false);
      }
    },
    [toggleState]
  );
  
  return (
    <div className="templateInner AMC2" key={props.anchorKey}>
      <Audio
        key={props.audKey}
        LO={props.LO}
        lang={props.lang}
        audSequence={props.audSequence}
        mainAudio={props.mainAudio}
        itemInContextAudio={props.itemInContextAudio}
        audioClip={props.audioClip}
        questionText={props.questionText}
        onPlayMainAudio={props.onPlayMainAudio}
        toggleStateFn={toggleTheState}
        toggleState={toggleState}
        template={props.template}
      />
      <div className="answerSection">
        <AnswerOption
          LO={props.LO}
          actID={props.actID}
          lang={props.lang}
          question={props.question}
          onAnswerSelected={props.onAnswerSelected}
          toggleStateFn={toggleTheState}
          toggleState={toggleState}
        />
      </div>
      <Next
        clickCounter={props.clickCounter}
        navLen={props.navLen}
        navCrrtItem={props.navCrrtItem}
        onClickNextBtn={props.onClickNextBtn}
        onSubmitBtn={props.onSubmitBtn}
        onEvaluation={props.onEvaluation}
      />
    </div>
  );
}
