import React, { useEffect, useContext } from "react";
import "@fontsource/noto-naskh-arabic";
import "@fontsource-variable/noto-sans-kr";
import "@fontsource-variable/noto-sans-tc";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";

import Auth from "./contents/Auth";
import AuthAdmin from "./contents/AuthAdmin";
import { AssessmentProvider } from "./context/AssessmentProvider";
import { ErrorProvider, ErrorContext } from "./context/ErrorProvider";
import RootLayout from "./contents/RootLayout";
import Home from "./contents/Home";
import Dashboard from "./contents/Dashboard";
import ReadingTest from "./contents/testRC/ReadingTest";
import ListeningTest from "./contents/testLC/ListeningTest";
import GrammarTest from "./contents/testGR/GrammarTest";
import Profile from "./contents/Profile";
import ProfileGrammar from "./contents/ProfileGrammar";
import ForgotPassword from "./contents/ForgotPassword";
import ResetPassword from "./contents/ResetPassword";
import EditProfile from "./contents/EditProfile";
import NotFoundPage from "./components/error/NotFoundPage";
import About from "./components/menubar/pages/About";
import Descriptions from "./components/menubar/pages/Descriptions";
import Proficiency from "./components/menubar/pages/Proficiency";
import FAQ from "./components/menubar/pages/FAQ";
import Policy from "./components/menubar/pages/Policy";
import Contact from "./components/menubar/pages/Contact";
import VerifyEmail from "./contents/VerifyEmail";
import SurveyGrammar from "./components/menubar/survey/SurveyGrammar";
import SurveyRCLC from "./components/menubar/survey/SurveyRCLC";
import RedirectBoundary from "./components/error/RedirectBoundary";

import AdminHome from "./contents/AdminHome";
import EnterCode from "./contents/admin/EnterCode";
import AdminReport from "./contents/admin/AdminReport";
import AdminFooter from "./contents/admin/adminReport/adminComponents/AdminFooter";
import ProfileAdmin from "./contents/ProfileAdmin";
import ProfileGrammarAdmin from "./contents/ProfileGrammarAdmin";
import AdminReportMainPanel from "./contents/admin/adminReport/MainPanel";
import AdminReportKeywordPanel from "./contents/admin/adminReport/KeywordPanel";
import AdminReportTagPanel from "./contents/admin/adminReport/TagPanel";
import AdminReportItemUserCorrelation from "./contents/admin/adminReport/ItemUserCorrelation";
import AdminReportItemItemCorrelation from "./contents/admin/adminReport/ItemItemCorrelation";
import AdminReportUserAnswerSearchPanel from "./contents/admin/adminReport/UserAnswerSearchPanel";
import AdminReporQuestionByPrimayTag from "./contents/admin/adminReport/QuestionByPrimayTag";
import AdminReporPassgeByProficiencyLevel from "./contents/admin/adminReport/PasageByProficiencyLevel";
import AdminReportODATestletInventory from "./contents/admin/adminReport/ODATestletInventory";
import AdminReportDataTypesSearch from "./contents/admin/adminReport/DataTypesSearch";
import AdminReportODAUsage from "./contents/admin/adminReport/ODAUsage";
import AdminReportTestletUsageFrequency from "./contents/admin/adminReport/TestletUsageFrequency";
import AdminReportGroupDP from "./contents/admin/adminReport/GroupDP";
import AdminReportGroupDPCompare from "./contents/admin/adminReport/adminDisplay/GroupDPDisplayCompare";
import AdminReportGroupDPCompareGR from "./contents/admin/adminReport/adminDisplay/GroupDPDisplayCompareGR";
import AdminReportItemUserDetail from "./contents/admin/adminReport/adminDisplay/ItemUserDisplayDetail";
import AdminReportMainItemTrack from "./contents/admin/adminReport/adminDisplay/MainPanelDisplayItemTrack";
import AdminReportUserAnswerItemTrack from "./contents/admin/adminReport/adminDisplay/UserAnswerSearchPanelDisplayItemTrack";
import AdminReportODAUserSurvey from "./contents/admin/adminReport/ODAUserSurvey";
import AdminReportMasterAdmin from "./contents/admin/adminReport/MasterAdmin";
import AdminReportSysAdminSuite from "./contents/admin/adminReport/SysAdminSuite";
import AdminReportSingleTestlet from "./contents/admin/adminReport/SingleTestlet";

import DisplaySingleTestlet from "./contents/DisplaySingleTestlet";
import "./css/index.css";

//** Main Routing & Global States **/
const App = () => {
  // TagManager.dataLayer.push({
  //initiate the global error state
  const modalErrorState = useContext(ErrorContext);
  const developerFlag = process.env.REACT_APP_DEVELOPERMODE;
  //watch for changes in errorState
  useEffect(() => {
    console.log("New global error state (below).");
    console.log(modalErrorState);
  }, [modalErrorState]);

  //send a single message to the backend GTM route
  useEffect(() => {
    axiosRetry(axios, {
      retries: 4, // number of retries
      retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
      retryCondition: (err) => {
        // console.log(err);
        // console.log(err.response);
        return (
          axiosRetry.isNetworkError(err) ||
          err.code === "ECONNABORTED" ||
          err.code === "ENOTFOUND" ||
          err.code === "ETIMEDOUT" ||
          (err.response && err.response.status >= 500 && err.response.status <= 599)
        );
      }
    });
    const trackingObject = {
      appName: "CMS-ODA",
      eventType: "AppLanding",
      eventDetails: {},
      dateTime: new Date().toISOString(),
      location: window.location
    };

    axios.post("/api/tag", trackingObject).catch((error) => {
      console.log(error);
    });
    // const devURL = "";
    // const stagURL = "https://oda2stag.dliflc.edu";
    // const prodURL = "https://oda.dliflc.edu";
    // const port = ":5005";
    // if (window.location.hostname === "localhost") {
    //   axios.post(devURL + api, trackingObject).catch((error) => {
    //     console.log(error);
    //   });
    // } else if (window.location.hostname === "oda2stag.dliflc.edu") {
    //   axios.post(stagURL + api, trackingObject).catch((error) => {
    //     console.log(error);
    //   });
    // } else {
    //   axios.post(prodURL + api, trackingObject).catch((error) => {
    //     console.log(error);
    //   });
    // }
  });

  //react-router-dom v6.8 implementation of createBrowserRouter
  //review here: https://reactrouter.com/en/main/routers/create-browser-router
  //important changes:
  //1. routes are now in json format for cleaner routing
  //2. nested routers no longer work so all routes migrated here
  //3. helper file is contents/RootLayout.js that dicates the header for given page (aka <Outlet />)
  //4. auth is checked with the loader for each protected route
  const router = createBrowserRouter([
    {
      path: "/",
      id: "root",
      element: <RootLayout />,
      //keep NotFoundPage disabled for dev mode
      //404 page redirect
      //errorElement: <NotFoundPage />,
      //dashboard redirect
      //errorElement: <RedirectBoundary />,
      //conditional
      errorElement: developerFlag ? <NotFoundPage /> : <RedirectBoundary />,
      loader: () => Auth.getToken(),
      loaderAdmin: () => AuthAdmin.getToken(),
      children: [
        {
          index: true,
          element: <Home />
        },
        {
          path: "/home",
          element: <Navigate to="/" replace={true} />
        },
        {
          path: "/admin",
          element: <AdminHome />
        },
        {
          path: "/forgot",
          element: <ForgotPassword />
        },
        {
          path: "/reset/:resetToken",
          element: <ResetPassword />
        },
        {
          path: "/verify/:verifyToken",
          element: <VerifyEmail />
        },
        {
          path: "/reset",
          element: <ResetPassword />,
          loader: Auth.checkToken
        },
        {
          path: "/dashboard",
          id: "dashboard",
          element: <Dashboard />,
          loader: Auth.checkToken
        },
        {
          path: "/editProfile",
          id: "editProfile",
          element: <EditProfile />,
          loader: Auth.checkToken
        },
        {
          path: "/grammar",
          id: "grammarTest",
          element: <GrammarTest />,
          errorElement: <RedirectBoundary />,
          loader: Auth.checkToken
        },
        {
          path: "/profileGrammar",
          id: "profileGrammar",
          element: <ProfileGrammar />,
          loader: Auth.checkToken
        },
        {
          path: "/reading",
          id: "readingTest",
          element: <ReadingTest />,
          //errorElement: <RedirectBoundary />,
          loader: Auth.checkToken
        },
        {
          path: "/listening",
          id: "listeningTest",
          element: <ListeningTest />,
          //errorElement: <RedirectBoundary />,
          loader: Auth.checkToken
        },
        {
          path: "/profile",
          id: "profile",
          element: <Profile />,
          loader: Auth.checkToken
        },
        {
          path: "/displaySingleTestlet/:skillType/:testletId",
          id: "displaySingleTestlet",
          element: <DisplaySingleTestlet />,
          loader: Auth.checkToken
        },
        {
          path: "/about",
          element: <About />
        },
        {
          path: "/testdescriptions",
          element: <Descriptions />
        },
        {
          path: "/proficiency",
          element: <Proficiency />
        },
        {
          path: "/faqs",
          element: <FAQ />
        },
        {
          path: "/policy",
          element: <Policy />
        },
        {
          path: "/contact",
          element: <Contact />
        },
        {
          path: "/surveyGrammar",
          element: <SurveyGrammar />
        },
        {
          path: "/surveyRCLC",
          element: <SurveyRCLC />
        },
        {
          path: "/entercode",
          id: "entercode",
          element: <EnterCode />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport",
          id: "adminreport",
          element: <AdminReport />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminfooter",
          id: "adminfooter",
          element: <AdminFooter />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/profileAdmin",
          id: "profileAdmin",
          element: <ProfileAdmin />
          //loader: AuthAdmin.checkToken,
        },
        {
          path: "/profileGrammarAdmin",
          id: "profileGrammarAdmin",
          element: <ProfileGrammarAdmin />
          //loader: AuthAdmin.checkToken,
        },
        {
          path: "/adminreport/mainpanel",
          id: "mainpanel",
          element: <AdminReportMainPanel />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/keywordpanel",
          id: "keywordpanel",
          element: <AdminReportKeywordPanel />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/tagpanel",
          id: "tagpanel",
          element: <AdminReportTagPanel />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/itemusercorrelation",
          id: "itemusercorrelation",
          element: <AdminReportItemUserCorrelation />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/itemitemcorrelation",
          id: "itemitemcorrelation",
          element: <AdminReportItemItemCorrelation />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/useranswersearchpanel",
          id: "useanswersearchpanel",
          element: <AdminReportUserAnswerSearchPanel />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/questionbyprimarytag",
          id: "questionbyprimarytag",
          element: <AdminReporQuestionByPrimayTag />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/passagebyproficiencylevel",
          id: "passagebyproficiencylevel",
          element: <AdminReporPassgeByProficiencyLevel />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/odatestletinventory",
          id: "odatestletinventory ",
          element: <AdminReportODATestletInventory />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/datatypessearch",
          id: "datatypessearch ",
          element: <AdminReportDataTypesSearch />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/odausage",
          id: "odausage ",
          element: <AdminReportODAUsage />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/testletusagefrequency",
          id: "testletusagefrequency ",
          element: <AdminReportTestletUsageFrequency />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/groupdps",
          id: "groupdps ",
          element: <AdminReportGroupDP />,
          loaderAdmin: AuthAdmin.checkToken
        },

        {
          path: "/adminreport/groupdps/compare",
          id: "compare",
          element: <AdminReportGroupDPCompare />,
          loaderAdmin: AuthAdmin.checkToken
        },

        {
          path: "/adminreport/groupdps/compareGR",
          id: "compareGR",
          element: <AdminReportGroupDPCompareGR />,
          loaderAdmin: AuthAdmin.checkToken
        },

        {
          path: "/adminreport/itemuser/detail",
          id: "detail",
          element: <AdminReportItemUserDetail />,
          loaderAdmin: AuthAdmin.checkToken
        },

        {
          path: "/adminreport/main/itemtrack",
          id: "itemtrack",
          element: <AdminReportMainItemTrack />,
          loaderAdmin: AuthAdmin.checkToken
        },

        {
          path: "/adminreport/useranswer/itemtrack",
          id: "itemtrackAnswer",
          element: <AdminReportUserAnswerItemTrack />,
          loaderAdmin: AuthAdmin.checkToken
        },

        {
          path: "/adminreport/usersurvey",
          id: "usersurvey ",
          element: <AdminReportODAUserSurvey />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/masteradmin",
          id: "masteradmin",
          element: <AdminReportMasterAdmin />,
          loaderAdmin: AuthAdmin.checkToken
        },
        {
          path: "/adminreport/sysadminsuite",
          id: "sysadminsuite",
          element: <AdminReportSysAdminSuite />,
          loaderAdmin: AuthAdmin.checkToken
        },

        {
          path: "/adminreport/singletestlet",
          id: "singletestlet",
          element: <AdminReportSingleTestlet />,
          loaderAdmin: AuthAdmin.checkToken
        }
      ]
    }
  ]);

  //error & assessment global states wrap the main router
  return (
    <div>
      <ErrorProvider>
        <AssessmentProvider>
          <RouterProvider router={router} />
        </AssessmentProvider>
      </ErrorProvider>
    </div>
  );
};

export default App;
