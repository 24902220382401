import { useState, useEffect, useContext } from "react";
import { AssessmentContext } from "../context/AssessmentProvider";
import { useNavigate } from "react-router-dom";
import JWT from "../contents/JWT";
import useErrorState from "./useErrorState";
import { odaDecrypt } from "../functions/genericFunctions";
import axios from "axios";
import axiosRetry from "axios-retry";

export function useCollectIncompleteTestFromDb(reloadData = false) {
  const [allData, setAllData] = useState(null);
  const [incompleteGR, setIncompleteGR] = useState(null);
  const [incompleteLC, setIncompleteLC] = useState(null);
  const [incompleteRC, setIncompleteRC] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [useAES] = useState(process.env.REACT_APP_USE_AES.toLowerCase() === "true" ? true : false);
  const [error, setError] = useState(null);
  const [assessmentState, dispatch] = useContext(AssessmentContext);
  const navigate = useNavigate();
  axiosRetry(axios, {
    retries: 4, // number of retries
    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
    retryCondition: (err) => {
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  useEffect(() => {
    if (reloadData && !isLoading) {
      setIsLoading(true);
      JWT.fetchData()
        .then((result) => {
          if (result) {
            const userId = result;
            //GR SECTION
            axios({
              method: "purge",
              url: "api/sessionGR/deleteEmptySessions?userId=" + userId
            }).catch((err) => {
              // setError(err);
              console.log(err);
            });

            axios
              //.get('api/sessionGR?user.email="' + result + '"&endtime=null&_sort={"starttime": -1}')
              .get('api/sessionGR?user._id="' + result + '"&endtime=null&_sort={"starttime": -1}')
              .then((response) => {
                let decrypted;
                if (useAES === "true" || useAES === true) {
                  decrypted = JSON.parse(odaDecrypt(response.data));
                } else {
                  //decrypted = JSON.parse(response.data);
                  decrypted = response.data;
                }
                //setData(decrypted);
                //collectIncompletes["GR"] = decrypted;
                setIncompleteGR(decrypted);
              })
              .catch((err) => {
                setError(err);
              });

            //RC SECTION
            axios({
              method: "purge",
              url: "api/sessionRC/deleteEmptySessions?userId=" + userId
            }).catch((err) => {
              // setError(err);
              console.log(err);
            });

            axios
              //.get('api/sessionRC?user.email="' + result + '"&endtime=null&_sort={"starttime": -1}')
              .get('api/sessionRC?user._id="' + result + '"&endtime=null&_sort={"starttime": -1}')
              .then((response) => {
                let decrypted;
                if (useAES === "true" || useAES === true) {
                  decrypted = JSON.parse(odaDecrypt(response.data));
                } else {
                  //decrypted = JSON.parse(response.data);
                  decrypted = response.data;
                }
                setIncompleteRC(decrypted);
                //setData(decrypted);
                //collectIncompletes["RC"] = decrypted;
                //collectIncompletes["ALL"] = [].concat(collectIncompletes["GR"],collectIncompletes["RC"]);
                //setAllData(collectIncompletes);
              })
              .catch((err) => {
                setError(err);
              });

            //LC SECTION
            axios({
              method: "purge",
              url: "api/sessionLC/deleteEmptySessions?userId=" + userId
            }).catch((err) => {
              // setError(err);
              console.log(err);
            });

            axios
              //.get('api/sessionLC?user.email="' + result + '"&endtime=null&_sort={"starttime": -1}')
              .get('api/sessionLC?user._id="' + result + '"&endtime=null&_sort={"starttime": -1}')
              .then((response) => {
                let decrypted;
                if (useAES === "true" || useAES === true) {
                  decrypted = JSON.parse(odaDecrypt(response.data));
                } else {
                  //decrypted = JSON.parse(response.data);
                  decrypted = response.data;
                }
                setIncompleteLC(decrypted);
                //setData(decrypted);
                //collectIncompletes["LC"] = decrypted;
                //collectIncompletes["ALL"] = [].concat(collectIncompletes["GR"],collectIncompletes["RC"],collectIncompletes["LC"]);
                //setAllData(collectIncompletes);
              })
              .catch((err) => {
                setError(err);
              });
          } else {
            //console.log("Line 111 of useCollectIncompleteFromDb");
            navigate("/");
          }
        })

        .catch((err) => {
          setError(err);
        })

        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [navigate, reloadData, isLoading, useAES]);

  //collect and combine incomplete dataset once all are available
  useEffect(() => {
    if (
      incompleteGR !== undefined &&
      incompleteGR !== null &&
      incompleteRC !== undefined &&
      incompleteRC !== null &&
      incompleteLC !== undefined &&
      incompleteLC !== null
    ) {
      let collectIncompletes = [];
      collectIncompletes["GR"] = incompleteGR;
      collectIncompletes["RC"] = incompleteRC;
      collectIncompletes["LC"] = incompleteLC;
      collectIncompletes["ALL"] = [].concat(
        collectIncompletes["GR"],
        collectIncompletes["RC"],
        collectIncompletes["LC"]
      );
      setAllData(collectIncompletes);
    }
  }, [incompleteGR, incompleteLC, incompleteRC]);

  //useErrorState(error,"UPDATE_ERROR_STATE","New Error");
  //return [data, isLoading, error];
  return [allData, isLoading, error];
}
