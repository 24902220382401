import dompurify from "dompurify";
import moveImg from "../../img/icons/move.svg";

export default function Next(props) {
  const sanitizer = dompurify.sanitize;
  let hint = props.hint;
  // hint = "[HINT]&nbsp;&nbsp;&nbsp;" + hint;

  let score = "";
  score = props.score;

  const totLen = parseInt(props.navLen);
  const crrtItem = parseInt(props.navCrrtItem);
  const redo = () => {
    if (document.querySelector(".feedbackModal") !== null) {
      document.querySelector(".feedbackModal").style.display = "none";
    }
  };

  const nxt = "Next";
  const sub = "Submit";
  const next = (
    <button
      className="outlined nextBtn disabled"
      name="next"
      onClick={props.onEvaluation}
      onKeyPress={(e) => {
        e.key === "Enter" && e.preventDefault();
      }}
    >
      {nxt}
    </button>
  );
  const submit = (
    <button className="outlined nextBtn disabled" name="submit" onClick={props.onEvaluation}>
      {sub}
    </button>
  );
  const checkForBypassToggle = (e) => {
    if (e.shiftKey && typeof document.querySelector("button.nextBtn.disabled") !== "undefined") {
      console.log("Bypassing logic for stuck question.");
      document.querySelector("button.nextBtn.disabled").classList.remove("disabled");
    }
  };

  return (
    <div id="templateNextModal">
      <div className="feedbackModal">
        <div className="feedbackHeader">
          Feedback <img src={moveImg} alt="Move" id="move" draggable="false" />{" "}
        </div>
        <div className="feedbackBody">
          <div
            style={{ color: "#d90c13" }}
            dangerouslySetInnerHTML={{
              __html: sanitizer(score)
            }}
          />
          <div
            className="hint"
            dangerouslySetInnerHTML={{
              __html: sanitizer(hint)
            }}
          />
          <button className="feedbackRedo smallBtn" onClick={redo}>
            Try Again
          </button>
        </div>
      </div>
      <div className="nextSubmitParentDiv" title="" onClick={(e) => checkForBypassToggle(e)}>
        {totLen === crrtItem ? submit : next}
      </div>
    </div>
  );
}
