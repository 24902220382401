import React, { useState, useCallback } from "react";
import { DropdownItems } from "./DropdownItems";
import { Link } from "react-router-dom";

function Dropdown(props) {
  const [click] = useState(false); //TODO: (REVIEW-->click is never set from false, is this intended?)

  return (
    <>
      <ul onClick={props.onMenu} className={click ? "dropdown-menu clicked" : "dropdown-menu"}>
        {DropdownItems.map((item, index) => {
          return (
            <Link key={item.cName + index} className={item.cName} to={item.path}>
              <li key={index}>{item.title}</li>
            </Link>
          );
        })}
      </ul>
    </>
  );
}

export default Dropdown;
