import React, { useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
//import Dropdown from "../../components/menubar/Dropdown"; //TODO (Review-->Dropdown is assigned but never used, can we remove it?)
import { CgMenu } from "react-icons/cg";
import { CgClose } from "react-icons/cg";
import { AssessmentContext } from "../../../context/AssessmentProvider";

import Auth from "../../Auth";
import Signout from "../../../components/signout/Signout";
import header from "../../../img/logos/headerLogo.svg";
import headerSmall from "../../../img/logos/headerLogoSmall.svg";
import accountIcon from "../../../img/icons/account.svg";

export default function Header() {
  const footerLogo = document.querySelector(".footerLogo");
  if (footerLogo !== null) {
    footerLogo.style.top = "10px";
    footerLogo.style.height = "67px";
  }

  //Global State Context Calls, links to context/AssessmentProvider.js & context/ErrorProvider.js
  const [assessmentState] = useContext(AssessmentContext);
  //const status = "grammarDPA"; //TODO (Review-->status is assigned but never used, can we remove it?)
  const logout = () => {
    Auth.logout(() => {
      window.localStorage.clear();
      //console.log("clearing local storage");
      localStorage.removeItem("token");
      window.location.href = "/";
    });
  };
  const [click, setClick] = useState(false);
  //const [, setDropdown] = useState(false); //dropdown was assigned but never used, removed

  const handleClick = () => {
    setClick(!click);
  };

  /* //TODO: these four functions assigned but never used, can we remove?
  const closeMobileMenu = () => {
    document.querySelector(".bodyItems").style.display = "none";
    setClick(false);
  };

  const showItems = () => {
    document.querySelector(".bodyItems").style.display = "inline";
    setClick(false);
  };

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };
  */

  const openIcon = <CgMenu className="cgHamberger cgHamberger-DP" size="25px" color="green" />;
  const closeIcon = <CgClose className="cgHamberger cgHamberger-DP" size="25px" color="green" />;

  // const handleDropDown = useCallback((e) => {
  //   if (e.style.display === "none") {
  //     document.getElementById("signout-content").style.display = "block";
  //   } else {
  //     document.getElementById("signout-content").style.display = "none";
  //   }
  // }, []);

  return (
    <header className="profileHeaderA">
      <Link to="/dashboard">
        <img
          className="logo"
          src={header}
          alt="Online Diagnostic Assessment Logo"
          title="Click here to return to your assessment dashboard."
        />
        <img className="logoSmall" src={headerSmall} alt="Online Diagnostic Assessment Logo" />
      </Link>
      <Signout>
        <div className="account">
          <img id="accountIcon" src={accountIcon} alt="Account Logo" />
        </div>

        <div className="signout-content accountSlideOutPane" id="signout-content">
          <b>
            <u>Account</u>
          </b>
          <br />
          {assessmentState.assessmentUserEmail}
          <br />
          <br />
          <Link to="/editProfile" state={{ accountName: assessmentState.assessmentUserEmail }}>
            <span>Account Details</span>
          </Link>
          <br />
          <br />
          <div onClick={logout}>
            <u>Logout</u>
          </div>
        </div>
      </Signout>
      <nav className="menuNav">
        <ul className={click ? "nav-menu active nav-menu-DP" : "nav-menu"}>
          <li className="nav-item linkAA">
            <Link to="/dashboard" className="nav-links">
              Assessment Dashboard
            </Link>
          </li>
          <li className="nav-item linkAA">
            <Link to="/surveyGrammar" className="nav-links">
              Survey
            </Link>
          </li>
        </ul>
      </nav>
      <div className="menu-icon" onClick={handleClick}>
        {click ? closeIcon : openIcon}
      </div>
    </header>
  );
}
