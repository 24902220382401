import React, { useState, useEffect, useContext, useCallback } from "react";
import Test from "./components/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";

const AMC2 = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    LO: testData._id,
    actID: testData.testitems[props.counter]._id,
    modality: testData.testitems[props.counter].modality,
    template: testData.testitems[props.counter].template,
    skill: testData.proficiencyLevel,

    mainAudio: testData.testitems[props.counter].mainAudio,
    itemInContextAudio: testData.testitems[props.counter].itemInContextAudio,
    audioClip: testData.testitems[props.counter].audioClips,
    questionText: testData.testitems[props.counter].questionTexts,
    question: testData.testitems[props.counter].questions,
    lang: testData.language._id,
    userAnswer: [],
    scoredAnswer: [],
    correctAnswer:
      testData.testitems[props.counter].answerFields[0]
        .multipleChoiceAnsNums[0],
    answerField: testData.testitems[props.counter].answerFields[0]._id,
    audSequence: 1,
    audioKey: Math.random(),
    anchorKey: new Date(),
  });
  const [scoredAnswer, setScoredAnswer] = useState([]);
  const [assessmentState] = useContext(AssessmentContext);

  let playMainAudio = useCallback(
    (event) => {
      let num = parseInt(event.currentTarget.id);
      setState({ ...state, audSequence: num });
    },
    [state]
  );
  playMainAudio = playMainAudio.bind(this);

  useEffect(() => {
    //fontSizeFunctions();
    draggableFunctions();

    return () => {};
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title = "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed hold SHIFT and left clicking this button to unlock it.  Next, click the button normally to proceed without answering the question.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const handleClick = useCallback(
    (event) => {
      // console.log(event);
      const id = event.currentTarget.id;
      const value = parseInt(event.currentTarget.value);
      const userAns = value;
      const crrtAns = state.correctAnswer;

      const checkAnswer = () => {
        let checkAnswer = false;
        if (parseInt(userAns) == parseInt(crrtAns)) {
          checkAnswer = true;
        }
        return checkAnswer;
      };

      setScoredAnswer([
        {
          uiControlID: id,
          testlet: testData._id,
          testitem: state.actID,
          answerField: state.answerField,
          keywordMatches: [],
          userAnswer: value.toString(),
          isPassed: checkAnswer(),
          isModifiedAudioPlayed: false,
          isTranscriptShown: false,
        },
      ]);

      document.querySelector(".nextBtn").classList.remove("disabled");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
        titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
      } else {
        titleChanger.title = "Click to submit your answers.";
      }
    },
    [state]
  );

  const handleEval = useCallback((name) => {
    const btnName = name;
    props.parentCallback(JSON.stringify(scoredAnswer), btnName);
  });

  const renderTest = () => {
    return (
      <>
        <Test
          LO={state.LO}
          actID={state.actID}
          lang={state.lang}
          audKey={state.audioKey}
          anchorKey={state.anchorKey}
          mainAudio={state.mainAudio}
          itemInContextAudio={state.itemInContextAudio}
          audioClip={state.audioClip}
          question={state.question}
          questionText={state.questionText}
          onAnswerSelected={handleClick}
          onPlayMainAudio={playMainAudio}
          audSequence={state.audSequence}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
          onClickNextBtn={props.clickNextBtn}
          onSubmitBtn={props.submitBtn}
          onEvaluation={handleEval}
          template="AMC2"
        />
        <Footer
          actID={state.actID}
          mod={state.modality}
          template={state.template}
          skill={state.skill}
        />
      </>
    );
  };
  return <div>{renderTest()}</div>;
};

export default AMC2;
