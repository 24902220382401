import React, { useState, useEffect, useCallback, useContext } from "react";
import { ErrorContext } from "../../context/ErrorProvider";
import axios from "axios";
import axiosRetry from "axios-retry";
import RegisterAccountModal from "../../components/modals/RegisterAccountModal";
import { useCollectLanguages } from "../../hooks/useCollectLanguages";
import { useCollectAffiliations } from "../../hooks/useCollectAffiliations";
import { useCollectSchoolhouse } from "../../hooks/useCollectSchoolhouse";
import { useNavigate } from "react-router-dom";

const Register = (props) => {
  const [, errorDispatch] = useContext(ErrorContext);
  const [state, setState] = useState({
    input: {},
    errors: {},
    frontEndErrors: {},
    accountCreatedModal: false
  });
  const [accountName, setAccountName] = useState(null);
  const [theAffilData, setTheAffilData] = useState(null);
  const [theSchoolData, setTheSchoolData] = useState(null);
  const [registerPage, setRegisterPage] = useState(1);
  const [theLangData, setTheLangData] = useState(null);
  const [ifDLIFLCStudent, setIfDLIFLCStudent] = useState(false);
  const [otherAffiliation, setOtherAffiliation] = useState(false);
  const [hasCACRef, setHasCACRef] = useState(false);
  const [langData, langError] = useCollectLanguages(theLangData);
  const [affilData, affilError] = useCollectAffiliations(theAffilData);
  const [schoolData, schoolError] = useCollectSchoolhouse(theSchoolData);
  const [epAffil, setAffil] = useState(null);
  const [epAffilOther, setAffilOth] = useState(null);
  const [epTargetLang, setTargetLang] = useState(null);
  const [otherLanguage, setOtherLanguage] = useState(false);
  const [epLangOther, setLangOther] = useState(null);
  const [epSchool, setDLISchool] = useState(null);
  const [otherSchool, setOtherSchool] = useState(false);
  const [epSchoolOther, setDLISchoolOther] = useState(null);
  const [epClass, setDLIClass] = useState(null);
  const [epTeam, setDLITeam] = useState(null);
  const [epDept, setDLIDept] = useState(null);
  const navigate = useNavigate();
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
            retryCondition: (err) => {return axiosRetry.isNetworkError(err) || err.code === "ECONNABORTED" || err.code === "ENOTFOUND" || err.code === "ETIMEDOUT" || (err.response && err.response.status >= 500 && err.response.status <= 599) }


  });
  //const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (langData !== null && affilData !== null && schoolData !== null) {
      setTheLangData(langData);
      setTheAffilData(affilData);
      setTheSchoolData(schoolData);
    }
    if (langError !== null) {
      //console.log(langError);
    }
    if (affilError !== null) {
      //console.log(affilError);
    }
    if (schoolError !== null) {
      //console.log(schoolError);
    }
  }, [langData, langError, affilData, affilError, schoolData, schoolError]);

  const handleAffiliationSelect = (e) => {
    setAffil(e.target.value);
    if (e.target.value === "student" ? setIfDLIFLCStudent(true) : setIfDLIFLCStudent(false));

    if (
      e.target.value === "student" || e.target.value === "faculty" || e.target.value === "dod"
        ? setHasCACRef(true)
        : setHasCACRef(false)
    );

    if (e.target.value === "other" ? setOtherAffiliation(true) : setOtherAffiliation(false));
  };

  const handleSetLanguage = (e) => {
    setTargetLang(e.target.value);
    if (e.target.value === "other") {
      setOtherLanguage(true);
    } else {
      setOtherLanguage(false);
    }
  };

  const handleSetSchool = (e) => {
    setDLISchool(e.target.value);
    if (e.target.value === "OTHER") {
      setOtherSchool(true);
    } else {
      setOtherSchool(false);
    }
  };

  const onChange = (event, changeType) => {
    let input = state.input;
    input[event.target.name] = event.target.value;
    let frontEndErrors = {};
    let isValid = true;

    if (changeType === "email") {
      if (!input["email"]) {
        isValid = false;
        frontEndErrors["email"] = "Please enter your email address.";
      }

      if (typeof input["email"] !== "undefined") {
        const emailRegEx = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!emailRegEx.test(input["email"])) {
          isValid = false;
          frontEndErrors["email"] = "Please enter valid email address.";
        }
      }
    } else if ((changeType = "password")) {
      if (typeof input["password"] !== "undefined") {
        //at least one uppercase letter one lowercase letter, and one number
        const passwordRegEx = new RegExp(/(?=^.{8,16}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*\s).*$/);

        if (!passwordRegEx.test(input["password"])) {
          isValid = false;
          frontEndErrors["password"] = "Your password must contain uppercase, lowercase, and number.";
        }
      }

      if (!input["password"]) {
        isValid = false;
        frontEndErrors["password"] = "Please enter your password.";
      }

      if (!input["password2"]) {
        isValid = false;
        frontEndErrors["password2"] = "Please confirm your password.";
      }

      if (typeof input["password"] !== "undefined") {
        if (input["password"].length < 8) {
          isValid = false;
          frontEndErrors["password"] = "Passwords must be 8 or more characters.";
        }
      }

      if (typeof input["password"] !== "undefined") {
        if (input["password"].length > 16) {
          isValid = false;
          frontEndErrors["password"] = "Maximum password length is 16 characters.";
        }
      }

      if (typeof input["password"] !== "undefined" && typeof input["password2"] !== "undefined") {
        if (input["password"] !== input["password2"]) {
          isValid = false;
          frontEndErrors["password"] = "Passwords do not match.";
        }
      }
    }
    setState({ ...state, input, frontEndErrors });
    return isValid;
  };

  useEffect(() => {
    //fix for overflow on DLIFLC details
    if (registerPage === 2 && document.querySelector(".centerWrap") !== null) {
      if ((epAffil === "student" || epAffil === null) && !otherLanguage && !otherSchool) {
        document.querySelector(".centerWrap").style.height = "580px";
      } else if (
        ((epAffil === "student" || epAffil === null) && otherLanguage && !otherSchool) ||
        ((epAffil === "student" || epAffil === null) && !otherLanguage && otherSchool)
      ) {
        document.querySelector(".centerWrap").style.height = "640px";
      } else if ((epAffil === "student" || epAffil === null) && otherLanguage && otherSchool) {
        document.querySelector(".centerWrap").style.height = "700px";
      } else {
        document.querySelector(".centerWrap").style.height = "500px";
      }
    } else {
      document.querySelector(".centerWrap").style.height = "500px";
    }
  }, [epAffil, registerPage]);

  const handleRefModalState = useCallback(
    (e) => {
      setState({ ...state, accountCreatedModal: false });
    },
    [state]
  );

  const onSubmit = (event) => {
    event.preventDefault();
    if (registerPage === 1) {
      let params = {
        params: {
          email: state.input.email
        }
      };

      axios.get("/api/user/login", params).then((res) => {
        //Proceed if email does not exist
        //console.log(res);
        //console.log(res.data.message);
        if (res.data.message === "Email available.") {
          if (validateUser()) {
            setRegisterPage(2);
          }
        } else {
          //backpedal if email exists
          let frontEndErrors = {};
          frontEndErrors["email"] = "This email address already exists.";
          setState({
            ...state,
            frontEndErrors: frontEndErrors
          });
        }
      });
    } else if (registerPage === 2) {
      if (validateUser()) {
        let stInE = state.input.email;
        let stInP1 = state.input.password;
        let stInP2 = state.input.password2;
        let otherAffilOutput = epAffil === "other" ? epAffilOther : null;
        let otherLangOutput = epTargetLang === "other" ? epLangOther : null;
        let otherSchoolOutput = epSchool === "OTHER" ? epSchoolOther : null;
        const newUser = {
          email: stInE,
          password: stInP1,
          password2: stInP2,
          affiliation: epAffil,
          otherAffiliation: otherAffilOutput,
          primaryLanguage: epTargetLang,
          otherPrimaryLanguage: otherLangOutput,
          school: epSchool,
          otherSchool: otherSchoolOutput,
          department: epDept,
          team: epTeam,
          class: epClass
        };
        axios
          .post("/api/user/register", newUser)
          .then((res) => {
            setRegisterPage(1);
            setState({ ...state, accountCreatedModal: true });
          })
          .catch((error) => {
            setRegisterPage(1);
            setState({ ...state, errors: error.response.data });
            //console.log(error.response.data);
            if (error.response) {
              if (error.response.status >= 400 && error.response.status < 600) {
                //global error dispatch call to show error modal if error received during api call
                errorDispatch({
                  type: "UPDATE_ERROR_STATE",
                  payload: {
                    errorStatusCode: error.response.status,
                    errorUserMessage: "New Error",
                    errorDevData: error.response
                  }
                });
              }
            }
          });
      }
    }
  };

  const validateUser = () => {
    let input = state.input;
    let frontEndErrors = {};
    let isValid = true;

    if (!input["email"]) {
      isValid = false;
      frontEndErrors["email"] = "Please enter your email address.";
    }

    if (typeof input["email"] !== "undefined") {
      const emailRegEx = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!emailRegEx.test(input["email"])) {
        isValid = false;
        frontEndErrors["email"] = "Please enter valid email address.";
      }
    }

    if (typeof input["password"] !== "undefined") {
      //at least one uppercase letter one lowercase letter, and one number
      const passwordRegEx = new RegExp(/(?=^.{8,16}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*\s).*$/);

      if (!passwordRegEx.test(input["password"])) {
        isValid = false;
        frontEndErrors["password"] = "Your password must contain uppercase, lowercase, and number.";
      }
    }

    if (!input["password"]) {
      isValid = false;
      frontEndErrors["password"] = "Please enter your password.";
    }

    if (!input["password2"]) {
      isValid = false;
      frontEndErrors["password2"] = "Please confirm your password.";
    }

    if (typeof input["password"] !== "undefined") {
      if (input["password"].length < 8) {
        isValid = false;
        frontEndErrors["password"] = "Passwords must be 8 or more characters.";
      }
    }

    if (typeof input["password"] !== "undefined") {
      if (input["password"].length > 16) {
        isValid = false;
        frontEndErrors["password"] = "Maximum password length is 16 characters.";
      }
    }

    if (typeof input["password"] !== "undefined" && typeof input["password2"] !== "undefined") {
      if (input["password"] !== input["password2"]) {
        isValid = false;
        frontEndErrors["password"] = "Passwords do not match.";
      }
    }

    setState({
      ...state,
      frontEndErrors: frontEndErrors
    });

    return isValid;
  };

  useEffect(() => {
    //console.log(state);
  }, [state]);

  return (
    <div className="signUpWrap">
      <div className="signUp">
        <span className="cardTitle">Create an Account</span>
        <form noValidate onSubmit={onSubmit}>
          <label htmlFor="userEmail">
            Email
            <input
              type="email"
              name="email"
              id="userEmail"
              value={state.input.email}
              onChange={(e) => {
                onChange(e, "email");
              }}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
            {registerPage === 1 && state.frontEndErrors.email && (
              <div className="text-danger">{state.frontEndErrors.email}</div>
            )}
          </label>

          {registerPage === 1 && !state.frontEndErrors.password ? (
            <React.Fragment>
              <label htmlFor="password1">
                Password
                <input
                  id="password1"
                  type="password"
                  className="password"
                  name="password"
                  value={state.input.password}
                  onChange={(e) => {
                    onChange(e, "password");
                  }}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <span className="eye password" title="Show/Hide password" />
              </label>
            </React.Fragment>
          ) : registerPage === 1 && state.frontEndErrors.password ? (
            <React.Fragment>
              <label htmlFor="password1">
                Password
                <input
                  id="password1"
                  type="password"
                  className="password"
                  name="password"
                  value={state.input.password}
                  onChange={(e) => {
                    onChange(e, "password");
                  }}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <div className="text-danger">{state.frontEndErrors.password}</div>
                <span className="eye password" title="Show/Hide password" />
              </label>
            </React.Fragment>
          ) : (
            ""
          )}

          {registerPage === 1 && !state.frontEndErrors.password2 ? (
            <React.Fragment>
              <label htmlFor="password2">
                Re-enter password
                <input
                  id="password2"
                  type="password"
                  className="password"
                  name="password2"
                  value={state.input.password2}
                  onChange={(e) => {
                    onChange(e, "password");
                  }}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <span className="eye password2" title="Show/Hide password" />
              </label>
            </React.Fragment>
          ) : registerPage === 1 && state.frontEndErrors.password2 ? (
            <React.Fragment>
              <label htmlFor="password2">
                Re-enter password
                <input
                  id="password2"
                  type="password"
                  className="password"
                  name="password2"
                  value={state.input.password2}
                  onChange={(e) => {
                    onChange(e, "password");
                  }}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <div className="text-danger">{state.frontEndErrors.password2}</div>
                <span className="eye password2" title="Show/Hide password" />
              </label>
            </React.Fragment>
          ) : (
            ""
          )}

          {registerPage === 1 && (
            <div className="passwordRules">
              Password Requirements
              <ul>
                <li>Between 8 and 16 characters</li>
                <li>At least one lower case letter</li>
                <li>At least one upper case letter</li>
                <li>At least one number</li>
              </ul>
            </div>
          )}

          {registerPage === 2 && (
            <React.Fragment>
              <label htmlFor="epAffil">
                User Affiliation:
                <select id="editProfileAffil" className="profileSelect" onChange={handleAffiliationSelect}>
                  {theAffilData !== null &&
                    Object.entries(theAffilData).map((entry) => {
                      let [key, value] = entry;
                      return (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      );
                    })}
                </select>
                {otherAffiliation && (
                  <input
                    id="editProfileAffilOther"
                    type="text"
                    className="editProfile-input"
                    placeholder="Other Institution"
                    onChange={(e) => setAffilOth(e.target.value)}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                )}
              </label>
              <label htmlFor="epTargetLang">
                Primary Target Language:
                <select className="profileSelect" id="editProfileTargetLanguage" onChange={handleSetLanguage}>
                  {theLangData !== null &&
                    Object.entries(theLangData).map((entry) => {
                      let [key, value] = entry;
                      return (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      );
                    })}
                </select>
                {otherLanguage && (
                  <input
                    id="editProfileLangOther"
                    type="text"
                    className="editProfile-input"
                    placeholder="Other Language"
                    onChange={(e) => setLangOther(e.target.value)}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                )}
              </label>
              {ifDLIFLCStudent && (
                <div>
                  <label htmlFor="epSchool">
                    DLIFLC School:
                    <select className="profileSelect" id="editProfileSchool" onChange={handleSetSchool}>
                      {theSchoolData !== null &&
                        Object.entries(theSchoolData).map((entry) => {
                          let [key, value] = entry;
                          return (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          );
                        })}
                    </select>
                    {otherSchool && (
                      <input
                        id="editProfileSchoolOther"
                        type="text"
                        className="editProfile-input"
                        placeholder="Other School"
                        onChange={(e) => setDLISchoolOther(e.target.value)}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                    )}
                  </label>
                  <label htmlFor="epDept">
                    DLIFLC Department:
                    <input
                      id="editProfileDept"
                      type="text"
                      className="editProfile-input"
                      placeholder="DLIFLC Department"
                      onChange={(e) => setDLIDept(e.target.value)}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                  </label>
                  <label htmlFor="epTeam">
                    DLIFLC Team Number:
                    <input
                      id="editProfileTeam"
                      type="text"
                      className="editProfile-input"
                      placeholder="DLIFLC Team Number"
                      onChange={(e) => setDLITeam(e.target.value)}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                  </label>
                  <label htmlFor="epClass">
                    DLIFLC Class Number:
                    <input
                      id="editProfileClass"
                      type="text"
                      className="editProfile-input"
                      placeholder="DLIFLC Class Number"
                      onChange={(e) => setDLIClass(e.target.value)}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                  </label>
                </div>
              )}
            </React.Fragment>
          )}

          {registerPage === 2 && (
            <div className="passwordRules">
              Note: You can change these later.
              <br />
              <br />
            </div>
          )}

          {registerPage === 1 ? (
            <button className="loginBtn">Proceed</button>
          ) : (
            <button className="loginBtn">Create Account</button>
          )}
        </form>
      </div>

      <div className="afterLogin">
        <div className="backToLogin">← Return to Sign In</div>
      </div>

      <div className="clearFix" />
      {state.accountCreatedModal && <RegisterAccountModal handleRefModalState={handleRefModalState} />}
    </div>
  );
};

export default Register;
