import React from "react";

export default function AnswerOption(props) {
  const question = props.question;
  const len = question.length;
  const RTL = props.dir;
  const dir = RTL ? "RTL" : "";
  const align = RTL ? { textAlign: "right" } : {};

  const createAnswerField = () => {
    let items = [];
    for (let i = 0; i < len; i++) {
      const actID = props.actID + "_" + i;
      const xlateNum = "xlate" + (i + 1);
      const questionItem = question[i].value;

      items.push(
        <div className="gridItem" style={align} key={actID}>
          <label htmlFor="">
            <span className={[dir, "xlateWord noSelect"].join(" ")} id={xlateNum}>
              {questionItem}
            </span>
            <form autoComplete="off">
              <input
                type="text"
                autoComplete="off"
                spellCheck={true}
                className="xlate"
                id={actID}
                onChange={props.onAnswerSelected}
                onBlur={props.onLoseFocusCheckNLP}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </form>
            {props.invalidEnInput.find((thisEl) => thisEl === actID) && (
              <div className="invalidInputLanguageDiv">Please enter your answer in English.</div>
            )}
          </label>
        </div>
      );
    }
    return items;
  };
  return createAnswerField();
}
