import React, { useState, useEffect, useContext, useCallback } from "react";
import Test from "./components/Test";
import Footer from "../../../../components/footer/Footer";
import "../../../../css/index.css";
//import fontsizeFunctions from "../../../../functions/fontsizeFunctions";
import draggableFunctions from "../../../../functions/draggableFunctions";
import { AssessmentContext } from "../../../../context/AssessmentProvider";

const MCT = (props) => {
  const testData = props.testData;
  const [state, setState] = useState({
    actID: testData.testitems[props.counter]._id,
    modality: testData.modality,
    template: testData.testitems[props.counter].template,
    skill: testData.proficiencyLevel,
    passage: testData.testitems[props.counter].mainPassage,
    questionText: testData.testitems[props.counter].questionText,
    answer: testData.testitems[props.counter].questions,
    dir: testData.language.isRightToLeft,
    //mcdir:testData.testitems[props.counter].mcdir.isRightToLeft,
    mcdir: false,
    userAnswer: [],
    scoredAnswer: [],
    correctAnswer: testData.testitems[props.counter].answerFields[0].multipleChoiceAnsNums[0],
    answerField: testData.testitems[props.counter].answerFields[0]._id
  });
  const [scoredAnswer, setScoredAnswer] = useState([]);
  const [assessmentState] = useContext(AssessmentContext);

  useEffect(() => {
    //fontSizeFunctions();
    draggableFunctions();
  }, []);

  useEffect(() => {
    //disable next/submit until previous api call is complete; prevents overrunning api calls to change same data
    if (props.buttonWaiting) {
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      apiWaitButtons.forEach((item, index) => {
        item.classList.add("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title =
            "Please answer the questions above before proceeding. You will also need to wait for the system to record your previous answer before proceeding.";
        } else {
          titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      titleChanger.classList.add("nextSubmitParentDivWaiting");
    } else {
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      const apiWaitButtons = document.querySelectorAll(".nextBtn, .submitBtn");
      apiWaitButtons.forEach((item, index) => {
        item.classList.remove("waitingForAPI");
        if (item.classList.contains("disabled")) {
          titleChanger.title = "Please attempt to answers all questions before proceeding.  Note: If you feel your assessment is stuck and you can not proceed hold SHIFT and left clicking this button to unlock it.  Next, click the button normally to proceed without answering the question.";
        } else {
          titleChanger.title = "Click to submit your answer(s).";
        }
      });
      titleChanger.classList.remove("nextSubmitParentDivWaiting");
      titleChanger.classList.add("nextSubmitParentDivUnanswered");
    }
  }, [props.buttonWaiting]);

  const handleClick = useCallback(
    (event) => {
      const id = event.currentTarget.name.substring(11);
      const value = parseInt(event.currentTarget.value);
      const userAns = value;
      const crrtAns = state.correctAnswer;

      const checkAnswer = () => {
        let checkAnswer = false;
        if (parseInt(userAns) == parseInt(crrtAns)) {
          checkAnswer = true;
        }
        return checkAnswer;
      };

      setScoredAnswer([
        {
          uiControlID: id,
          testlet: testData._id,
          testitem: state.actID,
          answerField: state.answerField,
          keywordMatches: [],
          userAnswer: value.toString(),
          isPassed: checkAnswer()
        }
      ]);

      document.querySelector(".nextBtn").classList.remove("disabled");
      const titleChanger = document.querySelector(".nextSubmitParentDiv");
      titleChanger.classList.remove("nextSubmitParentDivUnanswered");
      if (titleChanger.classList.contains("nextSubmitParentDivWaiting")) {
        titleChanger.title = "Please wait for your assessment to save your previous answer before proceeding.";
      } else {
        titleChanger.title = "Click to submit your answers.";
      }
    },
    [state]
  );

  const handleEval = useCallback((name) => {
    const btnName = name;
    props.parentCallback(JSON.stringify(scoredAnswer), btnName);
  });

  const renderTest = () => {
    return (
      <>
        <Test
          passage={state.passage}
          actID={state.actID}
          dir={state.dir}
          mcdir={state.mcdir}
          questionText={state.questionText}
          answer={state.answer}
          onAnswerSelected={handleClick}
          navLen={props.navLen}
          navCrrtItem={props.navCrrtItem}
          onClickNextBtn={props.clickNextBtn}
          onSubmit={props.clickSubmit}
          onEvaluation={handleEval}
        />
        <Footer actID={state.actID} mod={state.modality} template={state.template} skill={state.skill} />
      </>
    );
  };
  return <div>{renderTest()}</div>;
};

export default MCT;
