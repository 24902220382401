import React, { useState, useEffect, useCallback, useContext } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import axios from "axios";
import dompurify from "dompurify";
import axiosRetry from "axios-retry";
import EmailSentModal from "../../../components/modals/EmailSentModal";
import LoadingSpinner from "../../../components/spinner/LoadingSpinner";
import { ErrorContext } from "../../../context/ErrorProvider";
import ErrorModal from "../../../components/error/ErrorModal";
import { AssessmentContext } from "../../../context/AssessmentProvider";

/**
 * @component MoreInfo
 * @description this component handles the logic for the footer elements to a diagnostic profile
 *  This includes creating the buttons, passing the proper information from parent elements,
 *  generating formatted PDFs, and sending axios request to send an email with a generated
 *  PDF. This component also includes a modal that is displayed for the user to email a
 *  target email for their diagnostic profile to be sent to. Modified from original GrammarMoreInfo PDF setup
 * @param {object} props
 * @example
 * #in return segment at bottom of a diagnostic profile, can be tailored for reading or listening#
 * <MoreInfo {...} xrID={xrID} xrTotal={xrTotal} targetRef={pdfSegment} />
 *
 */

export default function MoreInfo(props) {
  const [assessmentState] = useContext(AssessmentContext);
  const [emailBlobState, setEmailBlobState] = useState(null);
  const [emailModal, setEmailModal] = useState(false);
  const [emailSentModal, setEmailSentModal] = useState(false);
  const [emailTarget, setEmailTarget] = useState(
    props.xrTotal.length > 0 && props?.xrTotal[0]?.user?.email !== undefined
      ? props.xrTotal[0].user.email
      : props?.xrTotal?.user?.email !== undefined
      ? props.xrTotal.user.email
      : assessmentState?.user?.email !== undefined
      ? assessmentState.user.email
      : ""
  );
  const [spinnerModal, setSpinnerModal] = useState(false);
  const [modalErrorState, errorDispatch] = useContext(ErrorContext);
  const [modality, setModality] = useState(props.modality);
  const [glossLessons, setGlossLessons] = useState(null);
  const sanitizer = dompurify.sanitize;
  axiosRetry(axios, {
    retries: 4, // number of retries

    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000mst
    retryCondition: (err) => {
      console.log(err.response);
      return (
        axiosRetry.isNetworkError(err) ||
        err.code === "ECONNABORTED" ||
        err.code === "ENOTFOUND" ||
        err.code === "ETIMEDOUT" ||
        (err.response && err.response.status >= 500 && err.response.status <= 599)
      );
    }
  });

  const xrID = props.xrID;
  const xrTotal = props.xrTotal;
  let userEmail = null;
  let targetLang = null;

  if (xrTotal.hasOwnProperty("user")) {
    userEmail = xrTotal.user.email;
    targetLang = xrTotal.language.displayName;
  } else {
    for (let i = 0; i < xrTotal.length; i++) {
      if (xrTotal[i]._id === xrID) {
        userEmail = xrTotal[i].user.email;
        targetLang = xrTotal[i].language.displayName;
      }
    }
  }
  if (glossLessons === null) {
    const languages = {
      arz: "Arabic-Egyption",
      qai: "Arabic-Iraqi",
      qav: "Arabic-Levantine",
      ara: "Arabic-MSA",
      cmn: "Chinese",
      hrv: "Croatian/Serbian",
      prs: "Dari",
      eng: "English",
      pes: "Farsi",
      fra: "French",
      // deu: "German",
      // ell: "Greek",
      // qag: "Gulf",
      heb: "Hebrew",
      kor: "Korean",
      nkr: "Korean/North Korean",
      pus: "Pashto",
      por: "Portuguese",
      rus: "Russian",
      srp: "Serbian",
      som: "Somali",
      spa: "Spanish",
      // apd: "Sudanese",
      tgl: "Tagalog",
      // tha: "Thai",
      tur: "Turkish",
      // tuk: "Turkmen",
      urd: "Urdu"
      // qay: "Yemeni",
    };

    const swappedLanguages = {};
    for (const key in languages) {
      if (languages.hasOwnProperty(key)) {
        const value = languages[key];
        swappedLanguages[value] = key;
      }
    }

    if (
      typeof xrID === "string" &&
      typeof modality === "string" &&
      typeof swappedLanguages[targetLang] === "string" &&
      xrID !== null &&
      modality !== null &&
      swappedLanguages[targetLang] !== null
    ) {
      axios({
        method: "post",
        headers: { "content-type": "application/json" },
        url: "api/glossLesson/",
        data: { sessionId: xrID, mod: modality, lang: swappedLanguages[targetLang] }
      })
        .then((res) => {
          if (Array.isArray(res.data)) {
            setGlossLessons(res.data);
          } else {
            console.log("no GLOSS lessons found :(");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: error.response.status,
                  errorUserMessage: "New Error",
                  errorDevData: error.response
                }
              });
            }
          }
        });
    }
  }
  const [emailSent, setEmailSent] = useState(userEmail);

  const handleEmailSentModal = useCallback(() => {
    setEmailSentModal(false);
    setEmailBlobState(null);
  }, []);

  //useEffect below triggered at the end of the createDownloadPDF func below
  //sets up a request to send an email with pdf attached to a specified address
  useEffect(() => {
    if (emailBlobState !== null && emailTarget !== null && emailSent !== true) {
      //create form data, this approach needed for pdf attachment
      const data = new FormData();
      data.append("blob", emailBlobState, "test.pdf");
      data.append("targetEmail", emailTarget);
      data.append("language", targetLang);
      data.append("modality", modality);
      data.append("userEmail", userEmail);

      //send email data object to API
      axios
        .post("/api/emailAssessmentToAddress", data, {
          headers: { "content-type": `multipart/form-data; boundary=${data._boundary}` }
        })
        .then(() => {
          setSpinnerModal(false);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status >= 400 && error.response.status < 600) {
              //global error dispatch call to show error modal if error received during api call
              errorDispatch({
                type: "UPDATE_ERROR_STATE",
                payload: {
                  errorStatusCode: error.response.status,
                  errorUserMessage: "New Error",
                  errorDevData: error.response
                }
              });
            }
          }
        });
      setSpinnerModal(false);
      setEmailSent(true);
      setEmailSentModal(true);
    }
  }, [emailBlobState, emailTarget, emailSent, modality, targetLang, userEmail, errorDispatch]);

  //creates pdf as requested by user and routes output
  const createDownloadPDF = async (pdfFormat, modality, userEmail) => {
    //hide the modal if it is showing
    setEmailModal(false);
    //setup pdf canvas and capture the diagnostic profile DOM into an image
    document.querySelector("meta[name=viewport]").setAttribute("content", "width=1100");
    // const element = props.targetRef.current;
    const element = document.querySelector(".bodyItems");
    let arrowsToHide = element.querySelectorAll(".centerer :nth-child(1)");
    arrowsToHide.forEach((arrow) => {
      if (arrow.style !== null) {
        arrow.style.display = "none";
      }
      // arrow.style.display = "inline-block";
      // } else {
      //   arrow.style = { display: "none" };
      // }
    });
    // if (element.querySelector(".centerer :nth-child(1)").style !== null) {
    //   element.querySelector(".centerer :nth-child(1)").style.display = "inline-block";
    // }
    let currentSelectionEls = element.querySelectorAll(".active");
    currentSelectionEls.forEach((el) => {
      el.classList.add("temporaryActive");
    });
    let allTabsAndContent = element.querySelectorAll(".tabContent, .tabLabel");
    allTabsAndContent.forEach((el) => {
      if (el.classList.contains("active")) {
        //skip
      } else {
        el.classList.add("active");
      }
    });
    let theEls2 = element.querySelectorAll(".grInfo p.infoTagline, .grInfo button, .modalDialog");
    theEls2.forEach((el) => {
      el.style.visibility = "hidden";
    });
    if (element.querySelector(".tabContent") !== null) {
      element.querySelector(".tabContent").style.border = "none";
    } //was 1px solid #ddd

    // let theEls = element.querySelectorAll(".tag, .accordion, .grammarProcessing, .profileCategory");
    // theEls.forEach((el) => {
    //   el.classList.add("pdfBackgroundFix");
    // });
    let data = null;
    await html2canvas(element, {
      scale: 1
    }).then((canvas) => {
      data = canvas.toDataURL("image/png");
    });
    //const data = canvas.toDataURL("image/png");

    //setup some metatext (date) for optional display
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    let shortToday = yyyy + mm + dd;
    //let footerToday = mm+"/"+dd+"/"+yyyy;

    let modCapitalized = modality.charAt(0).toUpperCase() + modality.slice(1);
    if (pdfFormat === "long") {
      const pdf = new jsPDF("p", "px", [794 * 0.75, element.offsetHeight * 0.5 + 70]);
      const pdfWidth = 794 * 0.5;
      const pdfHeight = pdf.internal.pageSize.getHeight() - 70;
      pdf.setTextColor("#187680");
      pdf.addImage(data, "PNG", 100, 45, pdfWidth, pdfHeight, null, "SLOW");
      pdf.text(
        targetLang + " " + modality.charAt(0).toUpperCase() + modality.slice(1) + " Assessment for " + userEmail,
        300,
        15,
        "center"
      );
      //pdf.text("Date: "+testDate+"  Score: "+score, 300, 30, "center");
      pdf.save("ODA_" + modCapitalized + "_Assessment_" + shortToday + "_DigitalCopy.pdf");
    } else if (pdfFormat === "printable" || pdfFormat === "email") {
      if (pdfFormat === "email") {
        setSpinnerModal(true);
      }
      const pdfWidth = element.offsetWidth * 0.6;
      const pdfHeight = element.offsetHeight * 0.6;
      const pdf = new jsPDF("p", "px", [pdfWidth, pdfHeight]);
      pdf.setTextColor("#187680");
      pdf.setFontSize("14");
      pdf.addImage(data, "PNG", 0, 15, pdfWidth, pdfHeight, null, "SLOW");
      pdf.text(
        userEmail +
          "'s " +
          targetLang +
          " " +
          modality.charAt(0).toUpperCase() +
          modality.slice(1) +
          " Diagnostic Profile",
        pdfWidth * 0.5,
        15,
        "center"
      );
      pdf.text(
        userEmail +
          "'s " +
          targetLang +
          " " +
          modality.charAt(0).toUpperCase() +
          modality.slice(1) +
          " Diagnostic Profile",
        pdfWidth * 0.5,
        pdfHeight - 15,
        "center"
      );
      // var quotes = element;
      // html2canvas(quotes, {
      //   scale: 1
      // }).then((canvas) => {
      //   var pdf = new jsPDF("p", "pt", "letter");

      //   //for (var i = 0; i <= quotes.clientHeight/980; i++) {
      //   for (var i = 0; i <= canvas.height / 1200; i++) {
      //     var srcImg = canvas;
      //     var sX = 0;
      //     var sY = 1200 * i; // start 980 pixels down for every new page
      //     var sWidth = 1000;
      //     var sHeight = 1200;
      //     var dX = 0;
      //     var dY = 0;
      //     var dWidth = 1000;
      //     var dHeight = 1200;
      //     var multiplierX = 0.55;
      //     var multiplierY = 0.9;
      //     var spacerInsertX = 30;
      //     var spacerInsertY = 50;
      //     var pageSpacer = sHeight * multiplierY + spacerInsertY + 40; // bottom of page

      //     let onePageCanvas = document.createElement("canvas");
      //     onePageCanvas.setAttribute("width", 1000);
      //     onePageCanvas.setAttribute("height", 1300);
      //     var ctx = onePageCanvas.getContext("2d");
      //     // details on this usage of this function:
      //     // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
      //     ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

      //     // document.body.appendChild(canvas);
      //     var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

      //     var width = onePageCanvas.width;
      //     var height = onePageCanvas.clientHeight;
      //     let modCapitalized = modality.charAt(0).toUpperCase() + modality.slice(1);
      //     if (i > 0) {
      //       pdf.addPage([612, 791]); //8.5" x 11" in pts (in*72)
      //     }
      //     pdf.setPage(i + 1);
      //     pdf.setTextColor("#187680");
      //     pdf.text(
      //       targetLang + " " + modality.charAt(0).toUpperCase() + modality.slice(1) + " Assessment for " + userEmail,
      //       300,
      //       spacerInsertY - 25,
      //       "center"
      //     );
      //     //pdf.text("Date: "+testDate+" Score: "+score, 300, spacerInsertY-8, "center");
      //     pdf.addImage(
      //       canvasDataURL,
      //       "PNG",
      //       spacerInsertX,
      //       spacerInsertY,
      //       width * multiplierX,
      //       height * multiplierY,
      //       null,
      //       "SLOW"
      //     );
      //     //pdf.text("Page "+ (i+1) + " of "+ Math.ceil(quotes.clientHeight/sHeight), 300, pageSpacer, "center");
      //     pdf.text("Page " + (i + 1) + " of " + Math.ceil(canvas.height / sHeight), 300, pageSpacer - 400, "center");
      //   }
      //handle paged output (email or direct download)
      if (pdfFormat === "printable") {
        pdf.save("ODA_" + modCapitalized + "_Assessment_" + shortToday + "_PagedCopy.pdf");
      } else if (pdfFormat === "email") {
        setEmailSent(false); //by now should be ok to resend, so set back to false
        setEmailBlobState(pdf.output("blob")); //triggers useEffect
      }
      // });
    }
    //element.querySelector(".centerer :nth-child(2)").style.display = "none";
    let currentSelectionElsAgain = element.querySelectorAll(".active");
    currentSelectionElsAgain.forEach((el) => {
      if (el.classList.contains("temporaryActive")) {
        el.classList.remove("temporaryActive");
      } else {
        el.classList.remove("active");
      }
    });
    // element.querySelector(".centerer :nth-child(1)").style.display = "none";
    arrowsToHide.forEach((arrow) => {
      if (arrow.style !== null) {
        arrow.style.display = "inline-block";
      }
    });
    theEls2.forEach((el) => {
      el.style.visibility = "visible";
    });
    if (element.querySelector(".tabContent") !== null) {
      element.querySelector(".tabContent").style.border = "1px solid #ddd";
    }
    document.querySelector("meta[name=viewport]").setAttribute("content", "width=device-width, initial-scale=1");
  };

  const handleEventTracking = (item, xrID) => {
    const trackingObject = {
      appName: "CMS-ODA",
      eventType: "GLOSSLessonFromODA",
      eventDetails: {
        item,
        xrID
      },
      dateTime: new Date().toISOString(),
      location: window.location
    };
    // const devURL = "";
    // const stagURL = "https://oda2stag.dliflc.edu";
    // const prodURL = "https://oda.dliflc.edu";
    // const port = ":5005";
    // const api = "/api/tag";
    // if (window.location.hostname === "localhost") {
    //   axios.post(devURL + api, trackingObject).catch((error) => {
    //     console.log(error);
    //   });
    // } else if (window.location.hostname === "oda2stag.dliflc.edu") {
    //   axios.post(stagURL + api, trackingObject).catch((error) => {
    //     console.log(error);
    //   });
    // } else {
    //   axios.post(prodURL + api, trackingObject).catch((error) => {
    //     console.log(error);
    //   });
    // }
    axios.post("/api/tag", trackingObject).catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className="info grInfo" key={"glLength-" + glossLessons}>
      {glossLessons !== null && glossLessons.length > 0 && props.targetLevel !== "***" && (
        <div className="callout">
          <p>For personalized lessons to help you reach your level {props.targetLevel} target, we got you covered:</p>
          {/* <button className="outlined">Suggested Materials</button> */}
          <br />
          <table id="glossTable">
            <thead>
              <td>GLOSS Lesson Title</td>
              <td>Topic</td>
              <td>Level</td>
            </thead>
            <tbody>
              {glossLessons !== null &&
                glossLessons.map((item, index) => {
                  return (
                    <tr>
                      <td
                        key={"glossitem-" + item.title}
                        onClick={() => {
                          handleEventTracking(item, xrID);
                        }}
                      >
                        <a
                          href={item.url}
                          dangerouslySetInnerHTML={{ __html: sanitizer(item.title) }}
                          target="_blank"
                        />
                      </td>
                      <td>{item.topic.charAt(0).toUpperCase() + item.topic.slice(1)}</td>
                      <td>{item.lvl}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      <br />
      <p className="infoTagline">You can also send a copy of this Diagnostic Profile to a teacher or yourself:</p>
      {!emailModal && !spinnerModal && !emailSentModal && emailBlobState === null ? (
        <button className="outlined" onClick={() => setEmailModal(true)}>
          Email this Profile
        </button>
      ) : (
        <button className="outlined" disabled>
          Email this Profile
        </button>
      )}
      {modality !== null && userEmail !== null ? (
        <button className="outlined" onClick={() => createDownloadPDF("printable", modality, userEmail)}>
          Download this Profile
        </button>
      ) : (
        <button className="outlined" disabled>
          Download this Profile
        </button>
      )}
      {emailModal && modality !== null && userEmail !== null && (
        <div className="modalDialog" id="emailConfirmDialog">
          <div>
            <div className="feedbackHeader" id="modalHeader">
              Email Diagnostic Profile
            </div>
            <br />
            <input
              className="login focused"
              type="email"
              name="targetEmail"
              id="targetEmail"
              autoComplete="off"
              defaultValue={userEmail}
              onChange={(e) => setEmailTarget(e.target.value)}
            />
            <h4>Your ODA diagnostic profile will be sent to the email address entered above.</h4>
            <button className="outlined" onClick={() => createDownloadPDF("email", modality, userEmail)}>
              Confirm
            </button>
            <button className="outlined" onClick={() => setEmailModal(false)}>
              Back
            </button>
          </div>
        </div>
      )}
      {spinnerModal && emailTarget !== null && (
        <div className="modalDialog" id="emailConfirmDialog">
          <div>
            <div className="feedbackHeader" id="modalHeader">
              {"Sending to " + emailTarget + "..."}
            </div>
            <LoadingSpinner msg="" />
          </div>
        </div>
      )}
      {emailSentModal && (
        <EmailSentModal
          refModalState={handleEmailSentModal}
          emailTarget={emailTarget}
          lang={targetLang}
          mod={modality}
        />
      )}
      <ErrorModal
        errorStatusCode={modalErrorState.errorStatusCode}
        errorUserMessage={modalErrorState.errorUserMessage}
        errorDevData={modalErrorState.errorDevData}
      />
    </div>
  );
}
